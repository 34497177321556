.project {
  position: relative;
  border: 1px solid #272727;
  margin-bottom: 54px;
  background-color: $componentBackground;
  height: fit-content;
  width: 240px;

  .projectArt {
    display: block;
    height: 0;
    margin-bottom: 10px;
    padding-top: 100%;
    position: relative;
    width: 100%;

    img {
      height: 240px;
      left: 0;
      object-fit: cover;
      object-position: center center;
      position: absolute;
      top: 0;
      width: 100%;
    }

    a {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .projectCover {
    display: block;
    height: 240px;
    margin-bottom: 10px;
    padding-top: 100%;
    position: relative;
    width: 100%;

    span {
      color: $white;
      display: block;
      font: 700 30px/1.4 $poppins;
      left: 50%;
      max-width: 100%;
      padding: 0 10px;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);

      @include min-screen(1200px) {
        font-size: (30px/1200px) * 100vw;
      }

      @include min-screen(1400px) {
        font-size: 38px;
      }
    }

    a {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .favouriteIcon {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .projectInfoContainer {
    display: flex;
    justify-content: space-between;


    .projectInfoOptions {
      display: flex;
      // justify-content: center;
      align-items: center;
      .bx--overflow-menu {
        margin-top: 0px;

      }
    }

  }

  .projectInfo {
    width: calc(100% - 20px);

    h3 {
      color: $white;
      font: 700 14px/1.2 $poppins;
      letter-spacing: letter-spacing(25, 14px);
      margin-bottom: 10px;
    }
    .project-tile-title{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      padding: 10px;
      overflow-wrap: break-word;
      max-width: 190px;
    }

    

    aside {
      color: #bbbbbb;
      font: 500 13px/1.4 $poppins;
      margin-top: 8px;
      span {
        color: $white;

        a {
          color: $accent;
          text-decoration: none;
        }
      }
    }

    .tags-wrapper {
      margin-left: 0px;
    }
  }

  .bx--overflow-menu--flip {
   
    left: -160px;

    &:before {
      left: 165px;
    }
  }
}

// Project list file page
.main.project-list-file{
  width: calc(100% - 4.5%);
  .mainContainer{
    padding-top: 30px;
  }
  .sections-container{
    .breadcrumb{
      margin-top: 0px;
      padding-top: 20px;
    } 
  }
  .hero{
    .sections-container{
      @media screen and (min-width: 1024px) and (max-width: 1400px){
        width: calc(100% - 153px);
      }
    }
    .heroContent{
      margin-bottom: 19px;
      margin-top: 0px;
      border-bottom: none;
      padding: 0px;
      .title-wrapper{
        margin-top: 8px;
        width: 634px;
        @media screen and (min-width: 1024px) and (max-width: 1400px){
          width: 432px !important;
        }
        p{
          font-family: "Poppins", sans-serif !important;
          line-height: 51px;
          height: auto;
          letter-spacing: inherit;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .hero-add-button{
          margin-left: 29px;
        }
      }
    }
    img{
      opacity: inherit;
    }  
  }
  .section{
    tr{
      td{
        &:first-of-type{
          padding-left: 21px;
          padding-right: 3px;
          text-align: center;
        }
      }
    }
  }
  .breadcrumb{
    .bx--breadcrumb-item:last-of-type{
      .bx--link{
        color: inherit;
      }
    } 
  }
  
  // favorite icon
  .itemSave{
    .hero-icon{
      svg{
        path{
          fill: transparent;
          stroke: $grey-lighter;
        }
      }
    }
  }
  .itemSave.itemSaved{
    .hero-icon{
      svg{
        path{
          fill: $accent;
          stroke: transparent;
        }
      }
    }
  }
  .bx--search-input{
    &::placeholder{
      color: $black-100;
    }
    background: $grey-dark;
  }
  .bx--search-magnifier{
    fill: $black-100;
  }

// checkbox
  .custom-checkbox {
    .bx--checkbox-label::before {
      border-radius: 0px;
    }
    .bx--checkbox-label::after {
      width: 10px !important;
      height: 5px;
    }
    .bx--checkbox:checked + .bx--checkbox-label::before {
      border-radius: 4px !important;
    }
  
    .bx--data-table-v2 thead tr td .bx--checkbox-label::before, .bx--data-table-v2 tbody tr td .bx--checkbox-label::before {
      background-color: transparent !important;
    }
  
    .bx--checkbox-label::before {
      background-color: transparent !important;
    }
  
    .bx--checkbox:checked + .bx--checkbox-label::before {
      border: none !important;
      background-color: $accent !important;
    }
  
    .bx--checkbox-label::after {
      border-left: 2px solid $black;
      border-bottom: 2px solid $black;
    }
  
    .bx--checkbox:indeterminate + .bx--checkbox-label::before {
      border-radius: 4px !important;
    }
  
    .bx--checkbox:indeterminate + .bx--checkbox-label::after, .bx--checkbox-label[data-contained-checkbox-state='mixed']::after {
      transform: scale(1) rotate(0deg);
      border-left: 0 solid $white;
      border-bottom: 2px solid $black;
      opacity: 1;
      margin-top: -0.2rem;
      left: 0.3rem
    }
  
    .bx--checkbox:checked + .bx--checkbox-label::before, .bx--checkbox:indeterminate + .bx--checkbox-label::before, .bx--checkbox-label[data-contained-checkbox-state='true']::before, .bx--checkbox-label[data-contained-checkbox-state='mixed']::before {
      background-color: $accent !important;
    }
      .link.row-value  {
          padding:0px !important
      }
    }

    // table dropdown style
    .bx--overflow-menu-options{
      background: $componentBackground;
      border: 1px solid $grey;
      color: $white;
      padding: 0px;
      right: -20px;
      left: auto;
      &::before{
        display: none;
        right: 21px;
        left: auto !important;
      }
      .bx--overflow-menu-options__option{
        &:hover{
          background: $accent
        }
        background:none;
        .bx--overflow-menu-options__btn{
          padding: 10px;
          color: $white;
          opacity: 1;
        }
        .bx--overflow-menu-options__btn:focus{
          background: none  !important;
        }
      }
     
    }
    .actions-header > th:has(.bx--checkbox){
      @include max-screen (1024px){
        margin-right: 0px;
      }
    }
    .batch-actions-wrapper,.custom-pagination{
      column-gap: 20px;
        @include max-screen (1024px){
          column-gap: 10px;
        }
    }
    .project-file-container{
      width: 100vw;
    }
}


.project-tiles{

  .bx--overflow-menu{
    margin-top: 30px;
    margin-right: 25px;
  }
  .bx--overflow-menu__icon {
    -webkit-transform: rotate(0deg) !important; 
    transform: rotate(0deg) !important;
fill: $white;
&:hover{
  fill: $white;
}
&:focus{
  fill: $white;
}
  }
 @include max-screen (720px){
  .section{
    margin-right: 80px;
  }
 }
 .see-more{    
  font-family: $poppins;
  color: $accent;
  font-size: 8px;
  cursor: pointer;
  margin-top: 10px;
    }
    .wrapper{
      display: flex;
    }
 .tags-wrapper{
  max-width: 300px;
  padding: 7px 0 21px 0;
 
  .tag{
width: 64px;
height: 16px;
font-family: $poppins;
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 15px;
color: #B3B3B3;
border: 1px solid #B3B3B3;
padding:3px 7px;
margin: 0 10px;
border-radius: 20px;

  }
}
}

// Project Recording - Start


.main.project-recordings{
  width: calc(100% - 4.5%);
  .hero{
    .bx--search-magnifier{
      fill: $black-100;
    }
    .bx--search-input{
      background: $grey-dark;
      &::placeholder{
        color: $black-100;
      }
    }
    .sections-container{
      @media screen and (min-width: 1024px) and (max-width: 1400px){
        width: calc(100% - 147px);
      }
    }
    .heroContent {
      p{
        font-family: "Poppins", sans-serif !important;
        line-height: 51px;
        height: auto;
        letter-spacing: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .title-wrapper{
        margin-bottom: 10px;
        width: 75%;
        @media screen and (min-width: 1024px) and (max-width: 1400px){
          width: 48%;
        }
      }
      .actions-wrapper{
        margin-bottom: 22px;
      }
    }
    img{
      opacity: inherit;
    }
  }
  .create-hero{
    margin: 0px;
    .content-wrapper{
      .title-wrapper{
        .tags-wrapper{
          margin-left: 35px;
          .tag{
            min-height: 15px;
            color: $black-100;
            padding: 0px 7px;
            margin: 0px 10px 0px 0px;
          }
        }
      }
    }
  }
  .create-hero:nth-child(2){
    .separator{
      display: none;
    }
  }
  .mainContainer{
    padding-top: 25px !important;
      @media(max-width:1023px) {
        width: calc(100% - 9.5%)
      } 
  }
  .section{
    tr{
      td{
        &:first-of-type{
          padding-left: 20px;
          padding-right: 0px;
          text-align: center;
        }
      }
    }
  }
  .breadcrumb {
    margin-top: 20px !important;
    .bx--breadcrumb-item:last-of-type {
      .bx--link{
        color: inherit;
      }
    }
  }
  .itemSave{
    .hero-icon{
      svg{
        path{
          fill: transparent;
          stroke: $black-100;
        }
      }
    }
  }
  .itemSave.itemSaved{
    .hero-icon{
      svg{
        path{
          fill: $primary;
          stroke: transparent;
        }
      }
    }
  }
  .custom-pagination{
    padding-left: 0px;
    .pages-dropdown{
      &:first-of-type{
      margin-left: 0px;
      }
    }
  }
  .bx--data-table-v2 {
    tr{
      background: $header-background;
    }
  }
  // table dropdown style
  .bx--overflow-menu-options{
    background: $componentBackground;
    border: 1px solid $grey;
    color: $white;
    padding: 0px;
    right: -20px;
    left: auto;
    &::before{
      display: none;
      right: 21px;
      left: auto !important;
    }
    .bx--overflow-menu-options__option{
      &:hover{
        background: $accent
      }
      background:none;
      .bx--overflow-menu-options__btn{
        padding: 10px;
        color: $white;
        opacity: 1;
      }
      .bx--overflow-menu-options__btn:focus{
        background: none  !important;
      }
    }
    
  }
  .hero{
    .heroContent{
      padding: 0px;
      border-bottom: none;
      .title-wrapper{
        p{
          font-family: "Poppins", sans-serif !important;
          line-height: 51px;
          height: auto;
          letter-spacing: inherit;
        }
      }
    }
  }

  .bx--data-table-v2.without-expanded-content-table {
    tbody{
        tr{
            td {
                .row-value-name{
                    width: 378px;
                    max-width: 378px;
                }
            }
        }
    }
  }
}


// Project Recording - End


.create-project{
  .create-hero{
    margin-bottom: 55px;
  }
  .breadcrumb {
    .bx--breadcrumb-item:last-of-type{
      .bx--link{
        color: inherit;
      }
    } 
  }
  .formRow{
    margin-bottom: 35px;
  }

}
.create-recording-main {
  .create-hero {
    margin-bottom: 55px;
  }
}

.edit-credit-project{
  .create-hero{
    margin-bottom: 55px;
  }
  .breadcrumb {
    .bx--breadcrumb-item:last-of-type {
      .bx--link{
        color: inherit;
      }
    }
  }
  .formRow{
    margin-bottom: 36px;
  }
}

.create-recording-main {
  .create-hero {
    margin-bottom: 55px;
  }
}




// Project Credit List - Start

.project-credit-list{
  .hero{
    .sections-container{
      @media screen and (min-width: 1024px) and (max-width: 1400px){
        width: calc(100% - 138px);
      }
    }
    .heroContent{
      padding: 0px !important;
      margin-bottom: 19px;
      margin-top: 0px;
      border-bottom: none;
      padding: 0px;
      .title-wrapper{
        margin-top: 8px;
        width: 634px;
        @media screen and (min-width: 1024px) and (max-width: 1400px){
          width: 432px !important;
        }
        p{
          font-family: "Poppins", sans-serif !important;
          line-height: 51px;
          height: auto;
          letter-spacing: inherit;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .hero-add-button{
          margin-left: 29px;
        }
      }
    }
    img{
      opacity: inherit;
    }  
  }
  .dropdown-icon{
    margin-left: 27px !important;
  }
}

// Project Credit List - End
.songs-tiles{
 @include max-screen (720px){
  .section{
    margin-right: 80px;
  }
 }
}


.projectCollaborator {
  .create-hero {
    overflow: visible;
  }

  .dropdown-container .dropdown-menu {
    left:-10px;
  }

  .dropdown-container .arrow-1 {
    left: 13px;
  }

  .dropdown-container .arrow-2 {
    left: 15px;
  }

}


//  Project Dashboard
// 


.projectDashboard {

  .bx--data-table-v2, .bx--data-table-v2-container {
    max-width: calc(100vw - 200px) !important;
  }

  .content-wrapper {
    max-width: calc(100vw - 120px) !important;

  }
  .vertical-line {
    color: $accent;
    margin-top: 3px !important;
    margin-left: 14px !important;
    padding-left: 0px !important;
    font-size: 26px !important;
    font-weight: 500 !important;
  }
  
  .tags-wrapper {
    margin-left: 12px;
  }

  .create-hero .create-header-link {
    margin-left: 20px
  }

  .projectDetails {

    .create-hero {
      margin-bottom: 45px;
    }

    .projectInfoGrid{
      .section {
        margin-bottom: 41px;
      }
  
      .infoGrid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        max-width: calc(100vw - 200px);
        padding-left: 0px;
        grid-gap: 35px 20px !important;

  
        .infoText:first-child {
          grid-column-start: 1;
          grid-column-end: 3;
        }
  
        .infoText:last-child {
          grid-column-start: 1;
          grid-column-end: 5;
        }
  
      }
    }
  }

  .projectRecordings, .projectSessions, .projectCredits {
    .create-hero {
      margin-bottom: 11px;
      .section {
        margin-bottom: 35px;
      }
    }

    .sections-container {
      .section {
        margin-bottom: 22px;
      }
    }
  }


  .projectRecordings {


    .recording-table td:nth-child(3) {
      width: 300px !important;
    }

    .recording-table td:nth-child(4) {
      max-width: 200px !important;
      width: 200px;
    }

    .recording-table td:nth-child(6) {
      width: 50px !important;
    }

  }

  .projectFiles {
    .section{
      margin-bottom: 35px;
    }
  }

  .projectCollaborator {
    .create-hero {
      margin-bottom: 11px;

    }
  }

  .projectCredits {
    .credit-table {
      .row-value-name{
        min-width: 10px;
        @include max-screen(1024px) {
          min-width:10px;
        
        }
       
      }
      @include max-screen(1024px) {
        td:last-of-type{
          min-width: 10px;
        }
      
      }
    }
  }
}

.projectDashboard {
  .create-hero {
    overflow: visible;
  }
}


// Edit Recording Credit - Start
// 


.recordingCreditEdit {
  .create-hero {
    margin-bottom: 55px;
  }

  .bx--form {
    max-width: 795px;
    .formRow {
      margin-bottom: 35px;
    }
  }
  #contributionId{
    .css-1wy0on6 {
      display: none;
    }
  }

}



// 
// Edit Recording Credit - End


//  Project Tile List Container - Start

.projectTileContainer {
  display: flex;
  flex-direction: row;
  gap:18px;
  flex-wrap: wrap
}


//  Project Tile List Container - End