.playlistHero {
    overflow-x: hidden;
    padding-bottom: 20px;

    .playlistHeroImg {
        display: flex;
        justify-content: center;
        margin: auto;
        opacity: 0.3;
        position: relative;

        &::before {
            bottom: 0;
            box-shadow: inset 0px 0px 90px 70px #1f1f1f, 5px 5px 5px 5px #1f1f1f;
            content: "";
            display: flex;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
            width: 410px;
        }

        img {
            height: auto;
            max-width: 410px;

            @include min-screen(768px) {
                width: 410px;
            }
        }
    }

    .playlistHeroContent {
        position: relative;

        @include min-screen(768px) {
            display: flex;
            justify-content: center;
            justify-content: flex-start;
        }

        .playlistHeroThumbnail {
            height: 246px;
            margin: 0 auto;
            margin-left: 0px;
            margin-right: 15px;
            width: 262px;
            margin-top: 29px;
        }

        img {
            height: 152px;
            width: auto;
        }

        .playlistHeroBody {
            margin-left: 0px;
            margin-top: 50px;

            @include min-screen(768px) {
                margin-left: 0px;
                margin-top: 0px;
                width: calc(100% - 206px);
            }

            header {
                text-align: center;

                @include min-screen(768px) {
                    text-align: left;
                }

                h3 {
                    color: $white;
                    font: 400 14px/1.8 $poppins;
                    letter-spacing: letter-spacing(25, 16px);
                    position: relative;
                    top: -8px;

                    @include min-screen(768px) {
                        font-size: 16px;
                        letter-spacing: letter-spacing(25, 18px);
                        line-height: 30px;
                    }

                    span {
                        color: $accent;
                        margin-left: 10px;
                    }
                }
                .playlist-tooltip {
                    background: #141414;
                    border: transparent !important;
                    .bx--tooltip__caret{
                      background: #141414 !important;
                      border: transparent !important;
                      width: 30px !important;
                      height: 30px !important;
                      border-color: #2e2d2d !important;
                    }
                }

                h1 {
                    color: $white;
                    font: 700 36px/51px $poppins;
                    height: 51px;
                    letter-spacing: letter-spacing(25, 30px);
                    max-width: 900px !important;
                    margin: 0 auto 5px;
                    white-space: nowrap !important;
                    overflow: hidden;
                    text-overflow: ellipsis !important;

                    @include min-screen(768px) {
                      font-size: 36px;
                      max-width: 590px;
                      margin: 0 auto 5px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      letter-spacing: letter-spacing(25, 50px);
                      margin: 0 0 5px;
                    }

                    @include min-screen(1024px) {
                        font-size: 36px;
                        letter-spacing: letter-spacing(25, 60px);
                    }
                }
            }
            .playlist-description{
                max-width:730px;
                height:84px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                .ellipsis-text{
                    color:$accent;
                    cursor:pointer;
                }
            }

            .playlistHeroLinks {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                gap:25px;
                margin-top: 35px;

                @include min-screen(768px) {
                    justify-content: left;
                }

                .playlistHeroItem {
                    background-color: $btnbackground;
                    border-radius: 6px;
                    height:40px;
                    color: $white;
                    display: none;
                    font: 500 13px/21px $poppins;
                    padding: 9px 11px 11px 11px;
                    transition: background-color 300ms ease;
                    text-decoration:none;

                    @include min-screen(768px) {
                        display: block;
                    }



                    &:hover {
                        background-color: $accent;
                    }
                    .hero-icon{
                        width: 20px;
                        height:19px;
                        margin-right: 20px;
                    }

                }
                .play-icon{
                    height:38px;
                    width: 38px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $accent;
                    border-radius: 100%;
                    cursor: pointer;
                    img{
                        height: 9px;
                        width: 12px;
                        cursor: pointer;
                    }
                }
                .files-count{
                    font-family: $poppins;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #B3B3B3;
                }
                .itemSave {
                    align-items: center;
                    background-color: transparent;
                    border: 1px solid $white;
                    border-radius: 6px;
                    color: $white;
                    display: flex;
                    font: 400 16px/25px $poppins;
                    margin-top: 20px;
                    margin-right: 15px;
                    padding: 9px 11px 11px 11px;
                    text-decoration: none;
                    transition: background-color 300ms ease, border-color 300ms ease;

                    svg {
                        fill: $white;
                        margin-right: 15px;
                    }

                    &.itemSaved {
                        background-color: $accent;
                    }

                    &:hover {
                        background-color: $accent;
                        border-color: $accent;
                    }
                    .hero-icon{
                        width: 20px;
                        height:19px;

                    }


                }
                &--share-icon{
                    height: 23px;
                    width: 23px;
                    cursor: pointer;
                }
            }
            
            .favorites-playlist {
               cursor: pointer;
            }

        }
    }

    &--no-image {
        margin-bottom: 100px;
    }

    &--no-image .playlistHeroContent {
        top: 0;
    }



}
.playlist-tooltip {
    background: $componentBackground !important;
    border: transparent !important;
    p{
        color: $white;
        font-family: $poppins;
        position: relative;
        z-index: 10;
    }

    .bx--tooltip__caret{
      background: $componentBackground !important;
      border: transparent !important;
      width: 30px !important;
      height: 30px !important;
      border-color: $componentBackground !important;
    }
}
.bx--tooltip__trigger{
    > p {
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
    }
    .trigger-text {
        color: $white;
        max-width: 900px;
        margin: 0 auto 5px;
        font-size: 20px !important;
        letter-spacing: inherit I !important;
        font-weight: 500 I !important;

        @media (max-width:768px) {
          font-size: inherit !important;
          max-width: 490px;
          margin: 0 auto 5px;
          margin: 0 0 5px;
        }
    }
}
