.projectHero {
  overflow-x: hidden;

  margin-top: 0px;
  margin-bottom: 20px;
  padding-top: 30px;


  .projectTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 57px;
  }

  
  .projectHeroImg {
    display: flex;
    justify-content: center;
    margin: auto;
    opacity: 0.3;
    position: relative;

    &::before {
      bottom: 0;
      box-shadow: inset 0px 0px 90px 70px $componentBackground,
        5px 5px 5px 5px $componentBackground;
      content: "";
      display: flex;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 410px;
    }

    img {
      height: auto;
      max-width: 410px;

      @include min-screen(768px) {
        width: 410px;
      }
    }
  }

  .projectHeroContent {
    position: relative;

    @include min-screen(768px) {
      display: flex;
      justify-content: center;
      justify-content: flex-start;
    }

    .projectHeroThumbnail {
      margin: 0 auto;
      margin-left: 0px;
      margin-right: 30px;
      width: 237px;
      height: 237px;
      img {
        width: 237px;
        height: 237px;
      }
    }

    .projectCover {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: $white;
        display: block;
        font-size: 38px;
        font-weight: 700;
      }
    }

    img {
      height: 140px;
      width: auto;
    }

    .projectHeroBody {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 237px;

      .projectDescription {
        // max-width: 730px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }

      @include min-screen(768px) {
        margin-left: 0px;
        margin-top: 0px;
        width: calc(100% - 206px);
      }

      .breadcrumb {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-top: 0px;
      }

      header {
        text-align: center;

        @include min-screen(768px) {
          text-align: left;
        }

        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 38px;
          line-height: 57px;
        }
        .artistInfo {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 20px;
          margin: 5px 0px;

          .artistName {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
          }

          .artistNumber {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: $accent;
          }
        }
      }

      .projectHeroLinks {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include min-screen(768px) {
          justify-content: left;
        }

        .projectHeroItem {
          background: transparent;
          border: 1px solid #1f1f1f;
          border-radius: 6px;
          height: 40px;
          color: $white;
          display: none;
          font: 500 13px/21px $poppins;
          margin-right: 15px;
          margin-top: 15px;
          padding: 9px 11px 11px 11px;
          transition: background-color 300ms ease;
          text-decoration: none;
          cursor: pointer;

          @include min-screen(768px) {
            display: block;
          }

          &:hover {
            background-color: $accent;
          }
          .hero-icon {
            width: 20px;
            height: 19px;
            margin-right: 20px;
          }
        }

        .itemSave {
          align-items: center;
          background-color: transparent;
          border: 1px solid #1f1f1f;
          border-radius: 6px;
          color: $white;
          display: flex;
          font: 400 16px/25px $poppins;
          margin-top: 15px;
          margin-right: 15px;
          height: 40px;
          padding: 9px 11px 11px 11px;
          text-decoration: none;
          transition: background-color 300ms ease, border-color 300ms ease;

          svg {
            fill: $white;
            margin-right: 15px;
          }

          &.itemSaved {
            background-color: $accent;
          }

          &:hover {
            background-color: $accent;
            border-color: $accent;
          }
          .hero-icon {
            width: 20px;
            height: 19px;
          }
        }
      }
    }
  }


  &--no-image .projectHeroContent {
    top: 0;
  }

  .moreDescriptionBtn {
    color: $accent;
    cursor: pointer;
  }
}
.export-integrations-wrapper {
  border-top: 1px solid #4e4e4e;
  margin-top: 26px;
  .header {
    font: 700 22px/1.4 $poppins;
    padding-top: 19px;
  }
  .content {
    padding-bottom: 19px;
    .projectHeroLinks {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @include min-screen(768px) {
        justify-content: left;
      }

      .projectHeroItem {
        background-color: transparent;
        border: 1px solid #1f1f1f;
        border-radius: 6px;
        color: $white;
        height: 40px;
        display: none;
        font: 500 13px/21px $poppins;
        margin-right: 15px;
        margin-top: 15px;
        padding: 9px 27px 11px 27px;
        transition: background-color 300ms ease;
        text-decoration: none;
        cursor: pointer;
        @include min-screen(768px) {
          display: block;
        }

        &:hover {
          background-color: $accent;
        }
        .hero-icon {
          width: 20px;
          height: 19px;
          margin-right: 20px;
        }
      }
      .sound-exchange {
        background-color: $white;
        border: 1px solid #1f1f1f;
        border-radius: 6px;
        color: #0d0d0d;
        display: none;
        font: 500 13px/21px $poppins;
        margin-right: 15px;
        margin-top: 15px;
        padding: 9px 27px 8px 27px;
        transition: background-color 300ms ease;
        text-decoration: none;
        @include min-screen(768px) {
          display: block;
        }
        .hero-icon {
          width: 20px;
          height: 19px;
          margin-right: 20px;
        }
      }

      .itemSave {
        align-items: center;
        background-color: transparent;
        border: 1px solid #1f1f1f;
        border-radius: 6px;
        color: $white;
        height: 40px;
        display: flex;
        font: 400 16px/25px $poppins;
        margin-top: 15px;
        margin-right: 15px;
        padding: 9px 9px;
        text-decoration: none;
        transition: background-color 300ms ease, border-color 300ms ease;

        svg {
          fill: $white;
          margin-right: 15px;
        }

        &.itemSaved {
          background-color: $accent;
        }

        &:hover {
          background-color: $accent;
          border-color: $accent;
        }
        .hero-icon {
          width: 20px;
          height: 19px;
          margin-right: 20px;
        }
      }
    }
  }
}
.export-text {
  font-family: $poppins;
  font-style: normal;
  display: none;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  color: #b3b3b3;
  padding: 5px;
  @include min-screen(768px) {
    display: flex;
  }
}
.export-date {
  font-family: $poppins;
  font-style: normal;
  display: none;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: white;
  padding-left: 3px;
  @include min-screen(768px) {
    display: flex;
  }
}
.export-active {
  background-color: $accent !important ;
}
.ghosted-credits {
  pointer-events: none;
  opacity: 0.5;
  z-index: 9990;
  position: relative;
  .sectionHeaderLink {
    display: none !important;
  }
}
.ghosted-wrapper {
  .title-wrapper {
    opacity: 0.5;
  }
  .sectionHeaderWrapper {
    opacity: 0.5;
  }
  .locked {
    opacity: 0.5;
  }
  .locked-menu {
    pointer-events: none;
    opacity: 0.5 !important;
  }
}
.section-link {
  background: none;
  border: none;
  color: #d5743e;
  font: 500 14px/1.4 "Poppins", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  padding: 0;
  text-align: right;
  text-decoration: none;
  position: absolute;
  right: 1px;
  margin-top: 55px;
  z-index: 9999;
}
.section-link-hidden {
  display: none !important;
}
.credits-alert {
  .bx--modal-close {
    display: none;
  }
}



// ProjectHeroActionContainer - Start


.projectHeroContainer {
  .create-hero{
    margin-bottom: 0px;
    .separator {
      display: none;
    }
  }
}

.projectHeroActionContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 4px;
  flex-wrap: wrap;
}

.exportIntegrations {

  .create-hero{
    margin-bottom: 35px;
  }

  .soundExchange {
    background-color: white;
    color:black;
    border: 1px;
    &:hover{
    border: 1px;
    }
  }
  .integrationLinks {
    display: flex;
    flex-direction: row;
    gap:35px;
    margin-bottom: 38px;


    .export-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      padding: 3px 0px 0px 0px;
    }

  }

  .iconButton {
    gap:10px
  }

}


//  ProjectHeroActionContainer - End

.cutoff-project-title {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1000px;
  width: 900px;
  white-space: nowrap;
}
@media (max-width: 1520px) {
  .cutoff-project-title {
    width: 700px;
  }
}
@media (max-width: 1280px) {
  .cutoff-project-title {
    width: 600px;
  }
}
@media (max-width: 1180px) {
  .cutoff-project-title {
    width: 550px;
  }
}
@media (max-width: 1100px) {
  .cutoff-project-title {
    width: 480px;
  }
}
@media (max-width: 1060px) {
  .cutoff-project-title {
    width: 440px;
  }
}