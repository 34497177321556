.song-hero {

    .projectHeroImg {
        display: flex;
        justify-content: center;
        margin: auto;
        opacity: 0.3;
        position: relative;

        &::before {
            bottom: 0;
            box-shadow: inset 0px 0px 90px 70px $componentBackground, 5px 5px 5px 5px $componentBackground;
            content: "";
            display: flex;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
            width: 410px;
        }

        img {
            height: auto;
            max-width: 410px;

            @include min-screen(768px) {
                width: 410px;
            }
        }
    }

    .song-HeroContent {
        position: relative;

        @include min-screen(768px) {
            display: flex;
            justify-content: center;
            justify-content: flex-start;
        }

        .songHeroThumbnail {
            margin: 0 auto;
            margin-left: 0px;
            margin-right: 4px;
            width: 237px !important;
            height: 237px !important;

            display: flex;
            justify-content: center;
            align-items: center;

            span {
            font-size: 38px;
            font-weight: 700;                
            }

            img {
              height: 100%;
              width: 100%;
            }
        }

        .songHeroBody {
            margin-left: 0px;
            margin-top: 50px;

            @include min-screen(768px) {
                margin-left: 0px;
                margin-top: 0px;
                width: calc(100% - 270px);
            }

            header {
                text-align: center;

                @include min-screen(768px) {
                    text-align: left;
                }

                h3 {
                    color: $white;
                    font: 400 14px/1.8 $poppins;
                    letter-spacing: letter-spacing(25, 16px);
                    position: relative;
                    top: -8px;

                    @include min-screen(768px) {
                        font-size: 16px;
                        letter-spacing: letter-spacing(25, 18px);
                        line-height: 30px;
                    }

                    span {
                        color: $accent;
                        margin-left: 10px;
                    }
                }

                h1 {
                    color: $white;
                    font: 700 36px/51px $poppins;
                    letter-spacing: letter-spacing(25, 30px);
                    max-width: 590px;
                    margin: 0 auto 0px;

                    @include min-screen(768px) {
                      font-size: 36px;
                      letter-spacing: letter-spacing(25, 50px);
                      margin: 0 0 0px;
                    }

                    @include min-screen(1024px) {
                        font-size: 36px;
                        letter-spacing: letter-spacing(25, 60px);
                    }
                }
            }

            .song-HeroLinks {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                @include min-screen(768px) {
                    justify-content: left;
                }

                .songHeroItem {
                    background: transparent;
                    border: 1px solid $black-100;
                    border-radius: 6px;
                    height:40px;
                    color: $white;
                    display: none;
                    font: 500 14px/21px $poppins;
                    margin-right:15px;
                    margin-top: 15px;
                    padding: 9px 11px 11px 11px;
                    transition: background-color 300ms ease;
                    text-decoration:none;
                    cursor: pointer;

                    @include min-screen(768px) {
                        display: block;
                    }


                    &:hover {
                        background-color: $accent;
                    }
                    .hero-icon{
                        width: 20px;
                        height:19px;
                        margin-right: 15px;
                    }

                }

                .itemSave {
                    align-items: center;
                    background-color: transparent;
                    border: 1px solid #1f1f1f;
                    border-radius: 6px;
                    color: $white;
                    display: flex;
                    font: 400 16px/25px $poppins;
                    margin-top: 15px;
                    margin-right: 15px;
                    height: 40px;
                    padding: 9px 11px 11px 11px;
                    text-decoration: none;
                    transition: background-color 300ms ease, border-color 300ms ease;

                    svg {
                        fill: $white !important;
                        margin-right: 15px;
                    }

                    &.itemSaved {
                        background-color: $accent;
                    }

                    &:hover {
                        background-color: $accent;
                        border-color: $accent;
                    }
                    .hero-icon{
                        width: 20px;
                        height:19px;

                    }


                }
            }

        }
    }

    &--no-image .projectHeroContent {
        top: 0;
    }



}
.export-integrations-wrapper{
    border-top: 1px solid #4e4e4e;
    // margin-top: 26px;
    .header{
        font: 700 22px/1.4 $poppins;
        padding-top: 19px;
    }
    .content{
        padding-bottom: 19px;
        .song-HeroLinks {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            @include min-screen(768px) {
                justify-content: left;
            }

            .songHeroItem {
                background-color: transparent;
                border: 1px solid $black-100;
                border-radius: 6px;
                color: $white;
                height:40px;
                display: none;
                font: 500 14px/21px $poppins;
                margin-right:15px;
                margin-top: 15px;
                padding: 9px 27px 11px 27px;
                transition: background-color 300ms ease;
                text-decoration:none;
                cursor: pointer;
                @include min-screen(768px) {
                    display: block;
                }

                &:hover {
                    background-color: $accent;
                }
                .hero-icon{
                    width: 20px;
                    height:19px;
                    margin-right: 20px
                }


            }
            .sound-exchange{
                background-color: $white;
                border: 1px solid #1f1f1f;
                border-radius: 6px;
                color: #0D0D0D;
                display: none;
                font: 500 13px/21px $poppins;
                margin-right:15px;
                margin-top: 15px;
                padding: 9px 27px 8px 27px;
                transition: background-color 300ms ease;
                text-decoration:none;
                @include min-screen(768px) {
                    display: block;
                }
                .hero-icon{
                    width: 20px;
                    height:19px;
                    margin-right: 20px
                }
            }

            .itemSave {
                align-items: center;
                background-color: transparent;
                border: 1px solid #1f1f1f;
                border-radius: 6px;
                color: $white;
                height: 40px;
                display: flex;
                font: 400 16px/25px $poppins;
                margin-top: 15px;
                margin-right: 15px;
                padding: 9px 9px;
                text-decoration: none;
                transition: background-color 300ms ease, border-color 300ms ease;

                svg {
                    fill: $white !important;
                    margin-right: 15px;
                }

                &.itemSaved {
                    background-color: $accent;
                }

                &:hover {
                    background-color: $accent;
                    border-color: $accent;
                }
                .hero-icon{
                    width: 20px;
                    height:19px;
                    margin-right: 20px
                }



            }
        }
    }
}
.export-text{
    font-family: $poppins;
    font-style: normal;
    display:none;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #B3B3B3;
    padding:5px;
    @include min-screen(768px) {
        display: flex;
    }
}
.export-date{
    font-family: $poppins;
    font-style: normal;
    display:none;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color:white;
    padding-left: 3px;
    @include min-screen(768px) {
        display: flex;
    }
}
.export-active{
    background-color: $accent !important ;
    border: none !important;
}
.ghosted-credits{
pointer-events: none;
opacity:0.5;
z-index: 9990;
position: relative;
.sectionHeaderLink{
    display:none !important;
}

}
.ghosted-wrapper{
    .title-wrapper{
        opacity:0.5;
    }
    .sectionHeaderWrapper{
        opacity:0.5;
    }
    .locked{
        opacity:0.5;
      }
      .locked-menu{
          pointer-events: none;
          opacity:0.5;
      }


    }
    .section-link{
        background: none;
    border: none;
    color: #d5743e;
    font: 500 14px/1.4 "Poppins", "Helvetica Neue", "Helvetica", Arial, sans-serif;
    padding: 0;
    text-align: right;
    text-decoration: none;
    position: absolute;
    right: 1px;
    margin-top: 55px;
    z-index: 9999;
    }
    .section-link-hidden{
        display: none !important;
    }
    .credits-alert{
        .bx--modal-close{
            display: none;
          }
    }
