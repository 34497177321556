.main.parties{
    width: calc(100% - 4.5%);
    .hero {
        .heroContent{
            margin: 0px;
            padding: 34px 0px;
            .heroNav {
                margin-left: 44px;
              button{
                  padding: 10px;
                  display: flex;
                .heroNavItem{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 16px;
                }
              }
            }
        }
        .bx--search-input{
            background: $grey-dark;
            &::placeholder{
                color: $black-100;
            }
        }
        img{
            opacity: inherit;
        }
    }
    .mainContainer{
        padding: 0px;
            .section{
                margin-top: 20px;
            }
    }
    .bx--search-magnifier{
        fill: $black-100;
    }


    .bx--data-table-v2.without-expanded-content-table {
        tbody{
            tr{
                td {
                    .row-value-name{
                        width: 378px;
                        max-width: 378px;
                    }
                }
            }
        }
      }
}
.main.parties-detail{
    width: calc(100% - 4.5%);
    margin: 0 auto 0 90px;
    .create-hero{
        margin-bottom: 21px;
    }
    .hero-contacts .content-wrapper .sections-container{
        margin: 0px auto 0px 41px !important;
    }
    .sections-container {
        h2{
            padding-bottom: 15px;
        }
        .breadcrumb{
            margin-top: 18px;
            .bx--breadcrumb-item:last-of-type {
                .bx--link{
                    color: $white;
                }
            }
        }
        .section{
            .bx--data-table-v2--zebra {
                tbody {
                    tr {
                        td{
                            padding-top: 10px;
                            padding-bottom: 10px;
                            padding-left: 15px;
                        }
                        td{
                            &:first-child {
                                // first cell color
                                p.row-value-name {
                                    color: #FFFFFF !important;
                                }
                            }
                        }
                    }
                }
            }
        }


        .info-grid-wrapper.party-person{
            margin-bottom: 34px;

            & .infoGrid{
                grid-gap: 35px 20px;
                grid-template-columns: minmax(min-content, 223px) minmax(min-content, 186px) minmax(min-content, 186px) minmax(min-content, 186px) minmax(min-content, 285px);
                padding-left: 0px;
                padding-right: 30px;

                .infoText{
                    &:last-child{
                        margin-right: 5px;
                        grid-column: 1/-1;
                        @media (max-width: 1024px){
                            margin-right: 30px;
                        }
                        .infoTextContent{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            padding-right: 80px;
                        }
                    }
                    .infoTextTitle{
                        margin: 0px;
                    }
                    .infoTextContent{
                        button{
                            height: inherit;
                            padding: 12px 10px;
                            background: transparent;
                            border: 1px $black-100 solid;
                        }
                    }
                }
            }
        }
        .info-grid-wrapper.party-organization{
            margin-bottom: 34px;

            & .infoGrid{
                grid-gap: 35px 20px;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                padding-left: 0px;
                .infoText{
                    &:last-child{
                        margin-right: 5px;
                        grid-column: 1/-1;
                        @media (max-width: 1024px){
                            margin-right: 30px;
                        }
                        .infoTextContent{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                        }
                    }
                    .infoTextTitle{
                        margin: 0px;
                    }
                    .infoTextContent{
                        button{
                            height: inherit;
                            padding: 12px 10px;
                            background: transparent;
                            border: 1px $black-100 solid;
                        }
                    }
                }
            }
        }
    }
    .party-affiliation-section{
        margin: 0 auto 0 45px;
        .section{
            margin-top: 0px;
            margin-bottom: 22px;
        }
        .bx--data-table-v2 th:first-of-type, .bx--data-table-v2 td:first-of-type{
            padding-left: 15px;
        }
        .bx--data-table-v2 thead tr td .row-value, .bx--data-table-v2 tbody tr td .row-value, .row-header, .row-value-name{
            padding: 0px;
        }
        .bx--data-table-v2.bx--data-table-v2--zebra tbody tr {
            td:nth-child(2) {
                max-width: 230px;
                width: 220px;
            }
        }
    }
    .party-contacts-section {
        margin: 0 auto 0 45px;
        .section{
            margin-top: 0px;
            margin-bottom: 22px;
        }
        .bx--data-table-v2 th:first-of-type, .bx--data-table-v2 td:first-of-type{
            padding-left: 15px;
        }
        .bx--data-table-v2 thead tr td .row-value, .bx--data-table-v2 tbody tr td .row-value, .row-header, .row-value-name{
            padding: 0px;
        }

    }
    .party-address-section{
        margin: 0 auto 0 45px;
        .section{
            margin-top: 0px;
        }
        .bx--data-table-v2 th:first-of-type, .bx--data-table-v2 td:first-of-type{
            padding-left: 15px;
        }
        .bx--data-table-v2 thead tr td .row-value, .bx--data-table-v2 tbody tr td .row-value, .row-header, .row-value-name{
            padding: 0px;
        }
    }
    .party-header{
        .create-hero{
            margin-bottom: 55px !important;
        }
        .content-wrapper .sections-container{
            margin: 0px auto 0px 41px !important;
        }
    }
    .info-grid{
        // margin: 0px auto 0px 57px !important;
        margin: 0px auto 0px 52px !important;
    }
    // for checkbox
  .custom-checkbox {
    .bx--checkbox-label::before {
      border-radius: 0px;
    }
    .bx--checkbox-label::after {
      width: 10px !important;
      height: 5px;
    }
    .bx--checkbox:checked + .bx--checkbox-label::before {
      border-radius: 4px !important;
    }

    .bx--data-table-v2 thead tr td .bx--checkbox-label::before, .bx--data-table-v2 tbody tr td .bx--checkbox-label::before {
      background-color: transparent !important;
    }

    .bx--checkbox-label::before {
      background-color: transparent !important;
    }

    .bx--checkbox:checked + .bx--checkbox-label::before {
      border: none !important;
      background-color: $accent !important;
    }

    .bx--checkbox-label::after {
      border-left: 2px solid $black;
      border-bottom: 2px solid $black;
    }

    .bx--checkbox:indeterminate + .bx--checkbox-label::before {
      border-radius: 4px !important;
    }

    .bx--checkbox:indeterminate + .bx--checkbox-label::after, .bx--checkbox-label[data-contained-checkbox-state='mixed']::after {
      transform: scale(1) rotate(0deg);
      border-left: 0 solid $white;
      border-bottom: 2px solid $black;
      opacity: 1;
      margin-top: -0.2rem;
      left: 0.3rem
    }

    .bx--checkbox:checked + .bx--checkbox-label::before, .bx--checkbox:indeterminate + .bx--checkbox-label::before, .bx--checkbox-label[data-contained-checkbox-state='true']::before, .bx--checkbox-label[data-contained-checkbox-state='mixed']::before {
      background-color: $accent !important;
    }
      .link.row-value  {
          padding:0px !important
      }
    }

     // table dropdown style
  .bx--overflow-menu-options{
    background: $componentBackground;
    border: 1px solid $grey;
    color: $white !important;
    padding: 0px;
    right: -20px;
    left: auto;
    &::before{
      display: none;
      right: 21px;
      left: auto !important;
    }
    .bx--overflow-menu-options__option{
      &:hover{
        background: $accent
      }
      background:none;
      .bx--overflow-menu-options__btn{
        padding: 10px;
        opacity: 1 !important;
        color: $white !important;
      }
      .bx--overflow-menu-options__btn:focus{
        background: none  !important;
      }
    }

  }
}

.create-party-modal{
    max-height: none;
    z-index: 1000000000000 !important;
    .bx--modal-container{
        min-width: 550px;
        padding: 27px 30px 0px 30px;
    }
    .bx--modal-content{
        margin: 0px 16px;
    }
    .party-mini-btn{
        display: flex;
        justify-content: flex-end;
        margin-top: 49px;
        button{
            padding: 10px;
            height: inherit;
        }
    }
    .bx--modal-close {
        display: inherit;
        &:after {
          content: '';
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 21px;
          color: $white;
          outline: none;
        }
    }
    .bx--modal-close__icon {
        display: inherit;
        fill: $white;
        width: 20px;
        height: 13px;
      }
      input{
        padding: 0 1rem !important;
      }
      .party-form-custom .bx--form {
        margin-top: 0px;

      .fields-container:first-child {
        margin-top: 31px;
      }

      .formRow {
        margin-top: 31px;
      }
      .party-form {
        margin-top: 0px;
      }
      }
}
