.files-table {
  td:nth-child(1),
  td:nth-child(2) {
    width: 4%;
    min-width: 57px;
    text-align: center;
  }
  td:nth-child(3) {
    width: 26%;
    max-width: 373px;
  }

  td:nth-child(4),
  td:nth-child(5),
  td:nth-child(6) {
    width: 16.5%;
  }
  td:nth-child(8),
  td:nth-child(9) {
    width: 3%;
  }

  @media (max-width: 1024px) {
    td:nth-child(1),
    td:nth-child(2) {
      width: 6%;
    }
  }
  @media (max-width: 1440px) {
    td:nth-child(3) {
      width: 26%;
      max-width: 180px;
    }
  }
}

.share-table {
  td:nth-child(1) {
    width: 4%;
    min-width: 57px;
    .row-header,
    .row-value {
      padding: 0;
    }
  }
  td:nth-child(2) {
    width: 30%;
  }
  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5) {
    width: 16.5%;
  }
}

.deleted-files-table {
  td:nth-child(1),
  td:nth-child(2) {
    width: 4%;
    min-width: 57px;
    text-align: center;
  }
  td:nth-child(3) {
    width: 26%;
    max-width: 373px;
  }
  td:nth-child(4),
  td:nth-child(5),
  td:nth-child(6) {
    width: 16.5%;
  }
  td:nth-child(8) {
    width: 2%;
  }
  @media (max-width: 1024px) {
    td:nth-child(1),
    td:nth-child(2) {
      width: 6%;
    }
    td:nth-child(3) {
      max-width: 218px;
    }
  }
  @media (max-width: 1440px) {
    td:nth-child(3) {
      width: 26%;
      max-width: 338px;
    }
  }
}
