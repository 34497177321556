@import "../../scss/Tools/_tools.media-queries.scss";

.Comments {
  background: #1F1F1F;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 66px);
  overflow: hidden;
  padding: 20px;
  position: fixed;
  right: 0;
  top: 66px;
  width: 394px;
  z-index: 10002;

  @include min-screen(1024px) {
    padding: 14px 20px 14px 15px;
    width: 394px;
    z-index: 10002;
  }

  &__close {
    -webkit-appearance: none;

    background: transparent;
    border: 0;
    height: 30px;
    width: 20px;
    padding: 0;
    margin-right: 10px;

    svg {
      width: 100%;

      * {
        fill: #ff9602;
      }
    }
  }

  &__title {
    color: white;
    font-size:18px;
    line-height: 22px;
    margin: 15px 0 30px;
    font-weight: 800;
    word-break: break-all;

    &__for {
      color: #666;
      font-weight: normal;
      margin-bottom: 10px;
      word-break: normal;
    }
  }

  &__list {
    height: 100%;
    overflow: hidden;
    padding-bottom: 30px;
    position: relative;
  }

  &__comment {
    border-bottom: 1px solid #222222;
    color: white;
    padding: 10px 11px 10px 0px;

    & p {
      padding-right: 35px !important;
    }
    
    &:last-child {
      border-bottom: 0 none;
    }
    
    &:hover {
      .trashbutton {
        width: 40px;
      }
    }
    &__meta {
      color: #B3B3B3;
      font-size: 14px;
      margin-bottom: 12px;
      height: 21px !important;
      display: grid;
      // grid-template-columns: 30px auto auto 40px;
      grid-template-columns: 30px auto 1fr auto;
      align-items: center;
      // outline: red solid 1px;

      & > * {
        // outline: green solid 1px;
      }


      .commenterName {
        color: #FFFFFF;
        margin-right: 8px;
        cursor: default;
      }

      .commentDate {
        display: flex;
        justify-content: flex-end;
        padding-right: 0px;
        cursor: default;
      }

      .trashbutton {
        width: 0px;
        // display: none;
        display: flex;
        justify-content: center;
        overflow: hidden;
        button {
          padding-left: 15px;
        }
      }

      & button {
        background: none !important;
        border: none !important;
      }
    }
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__for {
      display: flex;
      flex-direction: column;
    }
    &__heading {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
    }
  
    &__subheading {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #737373;
    }
  }

  p {
    //COMMENT TEXT 
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    fot-size: 14px;
    line-height: 18px;
  }
  
  .Comments__form {
    padding-top: 14px !important;
  }
  .bx--btn {
    width: 58px !important;
    padding: 0px;
    border-radius: 4px !important;
  }

}


.commentsPane {
  background-color: #1F1F1F !important;
  width: 48px !important;
  height: 80px !important;
  border-top: #D5743E solid 1px !important;
  border-left: #D5743E solid 1px !important;
  border-bottom: #D5743E solid 1px !important;
  border-radius: 5px 0px 0px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-bottom: 5px !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 30px !important;
  text-align: center;
  position: absolute !important;
  right: 0px !important;
  top: 100px !important;
}

//COMMENTS FIELD
.flyoutPane {
  padding-right: 11px !important;
  .bx--form-item, .comment-field {
    //COMMENT AND SUBMIT BUTTON CONTAINER
    height: 54px;
    display: flex;
    flex-direction: row-reverse;
    gap: 14px;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    padding-left: 0px !important;
}

  .Comments__list {
    padding-bottom: 0px !important;
  }

  .Comments__form {
    background: none !important;
  }

  .bx--text-area.comment-text {
    
  }

  .bx--text-area {
    width: 275px !important;
    height: 36px !important;
    padding-top: 9px !important;
    margin-left: 0px !important;
  }

  .bx--text-area::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: none !important;
    box-shadow:inset 0 0 6px rgba(0,0,0,0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  }


    .css-15k3avv {
      background: #2c2b2a !important;

      .css-wqgs6e {
        background-color: #2764c6 !important;
        color: #FFFFFF !important;
        font-size: 12px !important;
      }

      .css-v73v8k,
      .css-z5z6cw {
        color: #FFFFFF !important;
        font-size: 12px !important;
      }
    }
}