.create-hero {
  // overflow: hidden;
  // position: relative;

  width: 100vw;

  margin-bottom: 23px;

  background: $componentBackground;
  .separator {
    display: block;
    height: 0px;
    border: 0;
    border-top: 1px solid $grey;
    margin: 0px;
    padding: 0px;
  }
  .sectionHeaderText {
    color: #d5743e;
    font: 500 14px/1.4 "Poppins", "Helvetica Neue", "Helvetica", Arial, sans-serif;
    padding: 0;
    text-align: right;
}
.sectionHeaderLink {
  background: none;
  border: none;
  color: $accent;
  font: 500 14px/1.4 $poppins;
  padding: 0;
  text-align: right;
  text-decoration: none;
}
.billing-info-breadcrumb{
.breadcrumb{
  margin-top: 0px !important;
  margin-bottom:0px;
}
}
  .hero-button {
    background-color: $btnbackground;
    border-radius: 6px;
    border: 1px solid #4e4e4e;
    margin: 0 10px;
    color: $accent;
    font: 500 14px/25px $poppins;
    height: 35px;
    min-width: 150px;
    transition: background 300ms ease, border-color 300ms ease, color 300ms ease;

    &:hover {
      background: $white;
      border-color: $white;
      color: $btnbackground;
    }
  }
  .bx--search-input{
    background: $appSearchBackground;
    margin-right: 5px;
    &::placeholder{
      color: #B3B3B3;
    }
  }
  .parent-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;

  // border-top: 1px solid #4e4e4e;
  .vertical-line{
    margin-top: 1px;
    padding-left: 7px;
    font-size: 20px;
  }
  }
 h2{

  font-family: $poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 51px;
   padding-bottom: 20px;
    min-height: 51px;
    color: $white;
 }
 h4{
    font-family: $poppins;
    min-height: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: $white;
    margin-top: 13px;
    padding-bottom: 13.5px;
 }
    .title-wrapper{

    display: flex;
    align-items: center;
  }
  .content-wrapper{
    display:flex;
    align-items: center;
    justify-content: space-between;

   }
   .create-header-link{
    background: none;
    border: none;
    color: $accent;
    font: 500 14px/1.4 "Poppins", "Helvetica Neue", "Helvetica", Arial, sans-serif;
    padding: 0;
    text-align: right;
    text-decoration: none;
    margin-right: 5px;
    margin-top: 3px;
    margin-left: 10px;
  }
  .hero-edit-button{
    background-color: transparent;
    border: none;
    outline: none;
    border-radius: 6px;
    margin-left: 15px;
    & img {
      display: block;
      height: 20px;
      min-width: 20px;
    }
  }

  // Refetch Icon

  .refetchIconContainer {
    cursor: pointer;
  }

  .bx--search-magnifier{
    fill: #B3B3B3
  }

.commentsPane {
  position: fixed !important;
  right: 0px !important;
  top: 200px !important;
  z-index: 1 !important;
}

}
.projects-title-wrapper{
  .parent-wrapper{
    border-top:transparent;
  }
}
.tags-wrapper{
  margin-left: 35px;
  .tag{
    // margin: 0 10px;
    margin-right: 10px;
    min-height: 15px;
    font-family: $poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #C6C6C6;
    border: 1px solid #B3B3B3;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 20px;
  }
}
.info-icon {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 10px;
  margin-left: 2px;
  fill: $accent;
  cursor: pointer;
  &:hover{
    fill: $accent !important;
  }
}

.separator {
  display: block;
  height: 0px;
  border: 0;
  border-top: 1px solid #676767;
  margin: 0px;
  padding: 0px;
}
