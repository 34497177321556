.appSidebar {
  background:$sidebarBackground;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  height: 100vh;
  min-height: 100vh;
  padding-top: 66px;
  border-right:1px solid $accent;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 10;
  @include min-screen(1024px) {
    height: 100%;
    min-height: 100%;
    position: fixed;
  }

  display: none;
  @include min-screen(1024px) {
    display: block;
  }

  &.appSidebarOpen {
    transform: translateX(0);
    width: 200px;
    height: 100%;
    display: block;
    @include min-screen(1024px) {
      width: 230px;
    }
  }
}

.toggleMenu {
  display: none;
  @include min-screen(1024px) {
    display: block;
  margin-right:-22px;
  margin-top: 38px;
  position:absolute;
   right:0;
   width:35px;
    height:30px;
  
  }
}
.appMenuToggleIcon{
  height:12px;
  width:12px;
}
.sidebarNewAction {
  background: #000000;
  text-align: center;
  transition: width 0.2s ease-in-out;
  width: 100%;

  @include min-screen(1024px) {
    top: 127px;
  }

  a {
    opacity: 0;
  }

  &.sidebarNewActionOpen {
    @include min-screen(1024px) {
      width: 230px;
    }

    .sidebarNewActionMenu {
      display: flex;
      margin-top: 28px;

      a {
        opacity: 1;
        animation: fadein 0.3s ease-in forwards;
      }
    }
  }

  .sidebarNewActionMenu {
    position: relative;
    display: none;
    flex-direction: column;
    margin-left: 20px;
    padding-bottom: 8px;
    text-align: left;

    a {
      color: rgba(255, 255, 255, 0.6);
      font: 500 14px/25px $poppins;
      margin-bottom: 6px;
      opacity: 0;
      text-decoration: none;
      white-space: nowrap;

      &:hover {
        color: $white;
      }
    }
  }

  button {
    -webkit-appearance: none;
    border: 0 none;

    background: linear-gradient(98.64deg, #fcb716 -17.67%, #ec2a29 170.2%);
    color: white;
    height: 54px;
    font: 500 15px/25px $poppins;
    line-height: 54px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    &:focus {
      outline: 0;
    }

    &.sidebarNewProjectOpen {
      color: $white;
    }
  }
}
.burger {
  height: 2px;
  background: #f99b19;
  width: 18px;
  margin-top: 2px;
}
.disactive {
  height: 2px;
  background: white;
  width: 18px;
  margin-top: 2px;
}
.active-text {
  color: white;
}
.appNavigation {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  @include min-screen(1024px) {
    margin-top: 25;
  }
  a {
    color: rgba($white, 0.6);
    display: flex;
    align-items: center;
    text-decoration: none;
    white-space: nowrap;
    padding: 0 22px;
    &:before,
    &:after {
      content: "";
      display: table;
    }
  }
}

.slide-bar-items {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  margin-left: 12px;
  height: 54px;
  img {
    margin-right: 10px;
  }
}
.slide-bar-items-party {
  display: flex;
  align-items: center;
  margin-left: 8px;
  height: 54px;
  img {
    margin-right: 10px;
  }
}
.appNavigationItem {
  background: #000000;
  border-left: 4px solid $accent;
  height: 54px;
}
.appNavigationItemPassive {
  height: 54px;
}

.slide-bar-items-out {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-left: 12px;
  color: #f36720;
  margin-top: 250px;
  img {
    margin-right: 10px;
  }
}
.appMenuToggle {
  -webkit-appearance: none;

  align-items: center;
  background: none;
  border: 0 none;
  box-shadow: none;
  display: flex;
  height: 27px;
  flex-direction: column;
  justify-content: center;
  outline: none;
  padding: 0;
  width: 27px;
  border:1px solid $accent;
  border-radius: 100%;
  @include min-screen(1024px) {
    height: 27px;
    background: $sidebarBackground;
    width: 27px;
  }
  & + .appNavigation {
    opacity: 0;
    padding: 10px 10px 10px 20px;
  }
  span {
    background: $white;
    border-radius: 50%;
    display: inline-block;
    height: 6px;
    position: relative;
    width: 6px;

    em {
      font-size: 0;
      overflow: hidden;
    }
    &:before {
      background: $white;
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 6px;
      position: absolute;
      transform: translateX(-15px);
      top: 0;
      width: 6px;
    }
    &:after {
      background: $white;
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 6px;
      position: absolute;
      transform: translateX(8px);
      top: 0;
      width: 6px;
    }
  }
  &.appMenuToggleActive {
    padding: 0;
    display: flex;
    align-items: flex-end;
    @include min-screen(1024px) {
     padding-right: 8px;
    }
    & + .appNavigation {
      display: flex;
      flex-direction: column;
      opacity: 1;
      animation: fadein 0.3s ease-in forwards;
      transform: translateX(0px);
      a {
        &:hover {
          color: $white;
        }
      }
    }
  }
  &.appMenuToggleSmall,
  &.appMenuTogglesmall {
  }
  &.appMenuToggleMedium,
  &.appMenuTogglemedium {
    height: 20px;
    transform: scale(0.8);
    transform-origin: center center;
    width: 40px;
  }
  &.appMenuToggleLarge,
  &.appMenuTogglelarge {
  }
}
.new-actions {
  border: none;
  outline: none;
  background-color:$accent;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  position: fixed;
  right: 21px;
  bottom: 85px;
  z-index: 9000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include min-screen(1024px) {
    right: 62px;
  }

  & button {
    border: none;
    outline: none;
    background: none;
    color: white;
    font-size: 28px;
  }
}
.new-actions-items-wrapper {
  position: fixed;
  right: 30px;
  bottom: 160px;
  z-index: 9999;
  @include min-screen(1024px) {
    right: 71px;
  }
}
.new-actions-items {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.new-items-name {
  padding: 6px 12px;
  background: $componentBackground;
  border-radius: 14px;
  margin-right: 13px;
  width: 157px;
  display: flex;
  z-index: 9999;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
}
.new-items-image {
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
}
@keyframes fadeInNavigation {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.appNavigationItemPassive:hover, .appNavigationItem:hover, .appNavigation a:hover {
  background-color: #000000;
}
