.bx--pagination {
  background: transparent;
  border-color: transparent;
  border-width: 0 0 1px;
  justify-content: start !important;
  width: 600px
}

.bx--pagination--inline .bx--pagination__button {
  border: none;
}

.bx--pagination__text {
  color: white;
}

.bx--select--inline .bx--select-input {
  color: $accent;

  &:hover {
    background: none;
  }

  &:focus {
    outline: none;
  }
}
