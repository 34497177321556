.uploadCustomization {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
  margin-bottom: 0px !important; 
  padding-bottom: 35px;
  margin-top: 9px;
}

.uploadSection{
 display: flex;
 flex-direction: row;
 align-items: center;
}

.customizationUploadImage {
 width: 46px;
 height: 42px;
 margin-right: 22px;
}

.uploadText {
 color: #F99B19;
 font-size: 16px;
 line-height: 24px;
 font-weight: 500;
}

.uploadFormats {
 font-size: 16px;
 line-height: 24px;
 font-weight: 500;
}

.customization-upload {
 flex:0
 
}
.upload-wrapper{
 display:flex;
 justify-content: center;
 align-items: center;
 padding-bottom: 10px;
 .uploadFormats{
  margin-left: 20px;
  margin-top: 27px;
 }
}
.customizationButton {
 background-color: $btnbackground;
 border-radius: 4px;
 border: 1px solid #4e4e4e;
 color: $white;
 font: 500 16px/25px $poppins;
 height: 40px;
 min-width: 140px;
 transition: background 300ms ease, border-color 300ms ease, color 300ms ease;

 &:hover {
   background: $accent;
   border-color: $accent;
   color: $white;
 }
}
.social-follow-wrapper{
 .social-img{
  height:40px;
  width:40px;
  margin: 0 22px 22px;
  margin-bottom: auto;
 }
 .socila-img-wrapper{
  background-color: $white;
  height:40px;
  width:40px;
  margin: 0 22px 0 22px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
 }
 .input-wrapper{
  position:relative;
  display: flex;
  align-items: center;
  width: calc(100% - 300px);
  input{
   padding-left: 45px;
   &::placeholder{
    color: $granite-gray-color;
   }
  }
  .bx--form-item{
   width: 100%;
  }
.link-icon{
 height:10px;
 width:20px;
 position: absolute;
 left: 11px;
 top:15px;
}
  .bx--tooltip__trigger {
    position: absolute;
    left: 12px;
    top: 10px;
  }
 }
}
.social-follow-container{
 width: 100%;
 display:flex;
 margin-top: 38px;
 .toggle-wrapper{ 
 .bx--form-item{
  .bx--toggle__text--left{
   display: none;
  }
  .bx--toggle__text--right{
   display: none;
  }



  .bx--toggle__appearance::before{
 content:'';
opacity: 0.38;
border-radius: 7px;
   position: absolute;
    display: block;
    content: '';
    width: 34px;
    height: 14px;
    top: -2px;
    background-color: $white ;
    transition: 250ms cubic-bezier(0.5, 0, 0.1, 1);
    cursor: pointer;
  }
  .bx--toggle__appearance:after {
   box-sizing: border-box;
   position: absolute;
   display: block;
   border: transparent;
   cursor: pointer;
   top: -5px;
   width: 20px;
   height: 20px;
   background-color: #fff;
   border-radius: 50%;
   content: '';
   transition: 250ms cubic-bezier(0.5, 0, 0.1, 1);
}

.bx--toggle:focus + .bx--toggle__label .bx--toggle__appearance:after {
 box-shadow: none;
 outline: none;
}

.bx--toggle:focus + .bx--toggle__label .bx--toggle__appearance:after {
 box-shadow: none;
 outline: none;
}
.bx--toggle:checked + .bx--toggle__label .bx--toggle__appearance:before {
 background-color: $accent;
 border-color:transparent ;
}

.bx--toggle:checked + .bx--toggle__label .bx--toggle__appearance:after {
 background-color: $accent;
 border-color:transparent;
 left:-8px;
 
 
}
 }
 }

}
.buttons-wrapper{
  margin-top: 81px;
  padding-left: 0;
  button{
    padding: 10px;
    align-items: center;
    display: inline-flex;
    &:nth-child(1){
      background: transparent;
      border: 1px solid $black-100;
    }
  }
}
.customization-page {
  .img-label {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: $black-100;
    margin-bottom: 10px;
  }
  .img-dimension-error {
    margin-top: 10px;
    color: $error-color;
  }
  .custom-download-page-image {
    .cdpi-header-container {
      margin-bottom: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 2.5rem;
    }
    .custom-image-and-actions-container {
      display: flex;
      flex-direction: row;
      // margin-top: 36px;
      .custom-download-pg-image {
        min-height: 150px;
        max-width: 256px;
        max-height: 149px;
        object-fit: contain;
      }
      .dpi-actions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-left: 20px;
        h4{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: $white;
          margin-bottom: 17px;
        }
        .dpi-remove-upload-actions {
          display: flex;
          flex-direction: column;
          .dpi-action-container {
            height: 20px;
            position: relative;
            .action-btn-loading {
              position: absolute;
              margin: 0;
              padding: 0;
              left: -8px;
              top: -5px;
            }
            .dpi-action {
              height: 20px;
              cursor: pointer;
            }
            &:first-of-type{
              margin-top: 2px;
            }
            &:nth-child(2) {
              margin-top: 18px;
            }
          }
        }
      }
    }
  }
}

.account-customization{
  .mainContainer.customization-page{
    padding-top: 9px !important;
    .section{
      margin-bottom: 0px !important;
        .custom-download-page-image {
          .cdpi-header-container{
            margin-bottom: 18px;
            height: 0px !important;
            h3{
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: $black-100;
            }
          }
      }
      .uploadCustomization{
        border-bottom: none;
        margin-bottom: 0px !important; 
        padding-bottom: 24px;
        margin-top: 9px;
          .custom-image-and-actions-container{
            .dpi-actions{
              button{
                background: transparent;
                padding: 10px;
                border: 1px solid $black-100;
              }
            }
        }
      }
    }
  }
  .social-follow{
    .create-hero{
      margin-bottom: 44px;
    }
    .mainContainer{
      padding-top: 0px !important;
      margin-top: 8px;
      .social-follow-container{
        .bx--toggle__appearance{
          width: 0rem;
          margin-right: 30px;
        }
      }
    }
  }
  .create-hero{
    .sections-container{
      margin: 0px auto 0px 50px;
      .main-Switcher{
        padding-top: 63px;
        .main-Switcher-Content{
          // margin-right: 28px;
          .main-Switcher-Switch-Selected, .main-Switcher-Switch-None{
            // padding: 4px 0px 7px 0px;
          }
        }

      }
    }
  }
}

// Create modal custom settings

.bx--modal-close {
  top: 10px !important;
  right: 29px !important;
  outline: none !important;
  &:after {
    content: url(assets/images/close-icon.svg);
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    outline: none;
  }
}