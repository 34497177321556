.scrollIndicator {
  margin-top: 15px;

  @include min-screen(1024px) {
    display: none;
  }

  figure {
    height: 40px;
    margin: 0 auto;
    width: 60px;
  }

  .scrollIndicatorText {
    color: #3f3f3f;
    font: 500 12px/1.4 $poppins;
    text-align: center;
  }
}
