.appHeader {
  background: $appBackground no-repeat;
  border-bottom: 1px solid $componentBackground;
  display: flex;
  height: 65px;
  justify-content: space-between;
  left: 0;
  padding: 0 10px;
  position: fixed;
  top: 0;
  width: 100%;
  //changed to allow dropdown menu over flyout panes
  z-index: 20000;

  @include min-screen(768px) {
    padding: 0 20px 0 10px;
  }
  .dropdown-up-icon{

    margin-right:-6px;
  }
  
  .appSearch {
    align-self: center;
    display: none;
    max-width: 550px;
    margin: 0 10px;
    padding: 0 20px;
    width: 100%;
    .bx--search-input{
      background: #1F1F1F;
    }

    @include min-screen(1024px) {
     
    margin: 0 10px;
      display: block;
    }
    .bx--search-input{

      background-color: $appSearchBackground;
    }
  }

  .appUserActions {
    display: flex;
    align-self: center;

    a {
      text-decoration: none;
    }

    .appMenuToggle {
      margin-right: 8px;
      margin-top:20px;
      position: fixed;
      left: 15px;
      @include min-screen(1024px) {
        display: none;
      }


      &.appMenuToggleActive {
        padding:8px;
      }
    }
  }

  .appUserNav {
    align-items: center;
    display: flex;
    .appUserMenu {
      background: #141414;
      border: 1px solid #1f1f1f;
      padding: 5px 0px;
      position: absolute;
      right: 0;
      top: 65px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: baseline;

        @include min-screen(600px) {
          width: auto;
        }

      a {
        display: block;
        transition: color 300ms ease-in-out;
        color: $white;

        @include min-screen(600px) {
          display: inline-block;
          margin-bottom: 0;
          margin-right: 15px;
        }

        &:hover {
          color: $accent;
        }
      }

      button {
        -webkit-appearance: none;

        background: transparent;
        border: 0 none;
        color: $white;
        padding: 0;

        &:hover {
          color: $accent;
        }
      }

      a, button {
        padding: 10px 20px;
      }
    }
  }
}

.storageIndicator {
  display: none;
  margin-right: 15px;
  position: relative;

  @include min-screen(1024px) {
    display: inline-block;
  }

  svg {
    display: inline-block;
    height: 20px;
    width: 20px;
  }

  span {
    color: $accent;
    font: 500 8px/20px $poppins;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

.appHelp {
  display: none;
  margin-right: 15px;
  position: relative;

  @include min-screen(1024px) {
    display: inline-block;
  }

  svg {
    display: inline-block;
    height: 20px;
    width: 20px;
  }
}

.appNotifications {
  display: inline-block;
  margin-right: 15px;
  position: relative;

  svg {
    display: inline-block;
    height: 20px;
    width: 20px;
  }
}

.appUser {
  border: none;
  background: none;
  display: inline-block;
  padding: 0 20px 0 8px;
  position: relative;
  height: 64px;
  line-height: 64px;
  margin-top: 3px;

  @include min-screen(768px) {
    margin-top: 0px;
  }
  @media (max-width:375px){

  padding: 0 0px 0 8px;
  }

  .userIcon {
    display: inline-block;
    height: 30px;
    margin: 0 8px;
    width: 30px;
    border-radius: 50%;
  }

  span {
    color: $white;
    display: none;
    font: 500 12px/12px $poppins;
    vertical-align: middle;

    @include min-screen(1280px) {
      display: inline-block;
    }
  }

  .dropdownIcon {
    margin-left: 0;
    transform: translateY(-6px);
    width: 8px;
    margin-bottom: -2px;

    @include min-screen(768px) {
      vertical-align: sub;
      margin-top: 0px;
      margin-left: 6px;
    }
  }
}

.bx--search-close {
  fill: $accent;
}
.Breadcrumb-Project-Credits{
  .actions-wrapper {
    .appSearch {
      width: 292px;
      height: 40px;
      input {
        background-color: #1f1f1f !important;
      }
    }
  }
  .dropdown-icon {
    margin-left: 30px !important;
  }
}
