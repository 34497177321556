.recently-updated-table,
.songs-table {
  td:nth-child(1) {
    width: 0%;
  }
  td:nth-child(2) {
    width: 34%;
  }
  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5) {
    width: 22%;
  }
}

.favorites-table {
  td:nth-child(1) {
    width: 32%;
  }
  td:nth-child(2),
  td:nth-child(3),
  td:nth-child(4) {
    width: 22%;
  }
  td:nth-child(5) {
    width: 2%;
  }
}
