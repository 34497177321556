.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 20px;

  @include min-screen(560px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include min-screen(700px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @include min-screen(1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  + div {
    margin-top: 80px;
  }
}
