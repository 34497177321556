@import "../Components/components";


.heroHeaderContainer {

    background: $componentBackground;
    min-height: 120px;
    width: 100vw !important;

    display: flex;
    align-items: center;
    padding: 10px 145px 10px 0px;

    .heroHeader {


        // border: 4px solid blue;

        // flex-grow: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        overflow: visible;
        width: 100%;
        gap: 8px;

        .bx--search-input {
            background: $appSearchBackground;
        }


        .dropdown-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            .dropdown-menu {
                left: -20px
            }
            .arrow-1 {
                left: 9px !important
            }

            .arrow-2 {
                left: 11px !important
            }

            .dropdown-icon {
                margin: 0px 5px 0px 23px
            }
        }

        img {
            display: none; // Temporary fix to hide the background image while admin is ready
            object-fit: cover;
            object-position: center center;
            width: 100%;

            @include max-screen(1023px) {
                height: 100%;
            }
        }

        .sections-container {
            .breadcrumb {
                padding: 0px !important;
                margin: 0px !important;

                .bx--link {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                }


            }
        }

        .headerSectionsContainer {
            // background-color: green;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-left: 50px;

            .heroHeaderContent {
                // background-color: red;
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                flex-wrap: nowrap;
                gap: 10px;

                .heroTitle {
                    color: $white;
                    font: 700 34px/1.2 $poppins;
                    letter-spacing: letter-spacing(25, 40px);

                    @include min-screen(768px) {
                        letter-spacing: letter-spacing(25, 60px);
                    }
                }

                .heroSubtitle {
                    color: $white;
                    font: 700 34px/1.2 $poppins;
                    height: 51px;
                    font-family: $poppins;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 34px;
                    line-height: 51px;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    @include min-screen(768px) {
                        letter-spacing: letter-spacing(25, 60px);
                    }
                }

                .headerTitleWrapper {
                    max-width: 70%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 27px;
                    flex-shrink: 1;

                    .heroNav {

                        .heroNavItem {
                            background: none;
                            border: none;
                            color: $white;
                            display: inline-block;
                            font: 500 16px/1.2 $poppins;
                            margin-right: 25px;
                            padding: 0;
                            text-decoration: none;

                            @include min-screen(768px) {
                                font-size: 18px;
                            }

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }

                    .hero-button {
                        @extend .bx--btn;
                        @extend .bx--btn--primary;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 16px;
                        padding: 8px;
                        margin-left: 51px;
                    }


                    .heroAddButton {
                        background-color: transparent;
                        border: none;
                        outline: none;
                        padding: 0;
                        margin: 0;

                        & img {
                            display: block;
                            height: 20px;
                            min-width: 20px;
                            opacity: 1;

                            &.upload-btn {
                                height: 20px;
                                min-width: 16px;
                            }
                        }
                    }

                }


            }



        }


        .separator {
            display: block;
            height: 0px;
            border: 0;
            border-top: 1px solid #676767;
            margin: 0px;
            padding: 0px;
            // margin-top: auto;

        }


    }

    .actions-wrapper {

        flex-shrink: 0;
        width: 300px;

        display: flex;
        justify-content: flex-end;
        align-items: center;

        .appSearch {
            width: 292px;
            max-width: 292px;

            .bx--search-magnifier {
                fill: $black-100 !important;
                width: 18px;
                height: 18px;
                stroke-width: 2px;
            }

            .bx--search-input {
                color: $black-100 !important;

                background: $grey-dark;

                &::placeholder {
                    color: $black-100;
                }

            }

            .bx--search-input::placeholder {
                color: $black-100 !important;
                font-weight: 400 !important;
                font-size: 14px !important;
                line-height: 21px !important;
            }
        }

        .sectionHeaderLink {
            background: none;
            border: none;
            color: $accent;
            font: 500 14px/1.4 $poppins;
            padding: 0;
            text-align: right;
            text-decoration: none;
        }




    }


}