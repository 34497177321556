.appBrandingNewUi {

  height: 65;
  margin-right: 43px;
  position: relative;
  width: 250px;

  &ControlPanel {
    height: 43px;
  }
  .appLogo {
    display: block;
    margin-left:-18px;
    height: 65px;
    margin-right: 50px;
    @media(max-width:1023px) {
      margin-left: 30px;
      height: 65;
    }
    @media(max-width:768px) {
      margin-left: 30px;
      height: 65;
    }
    @media(max-width:425px) {
      margin-left: 30px;
      height: 65;
    }
    @media(max-width:375px) {
      margin-left: 30px;
      height: 65;
    }
    @media(max-width:320px) {
      margin-left: 30px;
      height: 65;
    }
  }

  a {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}




.appBranding {
  height: 65px;
  margin-right: 20px;
  position: relative;
  width: 125px;

  &ControlPanel {
    height: 43px;
    width: 80px;
  }

  .appLogo {
    display: block;
    width: 100%;
     margin-left: 8%;
  }

  a {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

