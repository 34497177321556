
.integrations {
.integration-tiles{
 width:100%;
display:flex;
flex-wrap: wrap;
.bx--form-item.bx--checkbox-wrapper:first-of-type {
  margin-top: 0.35rem;
  margin-right: 8px;
}
&-wrapper{
 position:relative;
 flex-basis: 32%;
 min-height:540px;
 min-width: 414px;
 background:$componentBackground;
 margin: 12.5px 28px 12.5px 0;
 padding: 33px 33px;


}
&-header{
 display: flex;
 justify-content: space-between;
 align-items: center;
 height:94px;

}
&-logo{
 height:94px;
 width:94px;
}
&-jaxsta{
 height: 35px !important;
}
&-heading{
 font-family: $poppins;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 30px;
padding: 20px 0 17px;
}
&-text{
 font-family: $poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
}
&-button{
 font-family: $poppins;
 font-style: normal;
 font-weight: 700;
 font-size: 14px;
 line-height: 21px;
 background: #000;
 border: 1px solid #B3B3B3;
 border-radius: 4px;
}
&-checkbox{
 display: flex;
 margin-top: 20px;
 &-label{
  margin: 4px;
  font-family: $poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
 }

}
.formCheckbox {
 outline-color: $accent;

 &:before {
  border: 1px solid #4E4E4E;
   background: #0E0A09;
   ;
 }

 span {
   color: $white;
 }
}

.bx--checkbox {
 &:checked + .formCheckbox {
   &:before {
     background: $accent;
   }
   &:after {
     border-color: $componentBackground;
   }
 }

 &:focus + .bx--checkbox-label::before {
   box-shadow: 0 0 0 3px rgba($accent, 0.5);
 }
}
}
.create-hero {
  margin-bottom: 17px;
}

}
.integrations-heading{

  font: 700 34px/1.4 $poppins !important;
 margin-left: 42px;
 padding-top: 60px;
 border-bottom: 1px solid #4e4e4e;


    margin-bottom: 20px;
    padding-bottom: 30px;
 }
 .read-more{
  position: absolute;
  margin-top: 30px;
  bottom:35px;
  background: #000000;
  border: 1px solid #B3B3B3;
  border-radius: 4px;

 }
 .read-more-button{
  font-family: $poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
cursor: pointer;
color:$accent;
 }
 .integrations-details-modal{
  .integration-tiles-logo {
    width: 85px;
    height: 85px;
   }
  .integration-tiles-heading {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
  }
  .integration-tiles-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
  .bx--modal-header {
    margin-bottom: 0px;
  }
  .bx--modal-close{
   display:none;
  }
  .bx--btn--secondary{
   display: none;
  }
  .integration-details{
   padding: 35px 35px;
  }
   .bx--modal-content{
    overflow-y: auto;
   }
   .bx--modal-content::-webkit-scrollbar-track
   {
     -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
     border-radius: 10px;
     background-color: #0e0d0d;
   }

   .bx--modal-content::-webkit-scrollbar
   {
     width: 5px;
     background-color: #0e0d0d;
   }

   .bx--modal-content::-webkit-scrollbar-thumb
   {
     border-radius: 10px;
     -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.3);
     box-shadow: inset 0 0 3px rgba(0,0,0,.3);
     background-color: #2b2929;
   }



 }
 .integration-tiles-modal-logo {
  width: 85px;
  height: 85px;
 }
