.parties-table {
  td:nth-child(1) {
    width: 4%;
  }
  td:nth-child(2) {
    width: 38%;
  }
  td:nth-child(3),
  td:nth-child(4) {
    width: 23%;
  }
  td:nth-child(5) {
    width: 2%;
  }
}

.parties-contact {
  td:nth-child(1) {
    width: 34%;
  }
}

.parties-address {
  td:nth-child(1) {
    width: 34%;
  }
  td:nth-child(2),
  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5) {
    width: 16.5%;
  }
}

.parties-affiliations {
  td:nth-child(1) {
    width: 34%;
  }
  td:nth-child(2),
  td:nth-child(3) {
    max-width: 16.5%;
  }
}
