.breadcrumb::-webkit-scrollbar {
  width: 0px;
  height: 4px;
}

.breadcrumb::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
}

.breadcrumb::-webkit-scrollbar-thumb {
  background-color: $accent;
}

.foler-container::-webkit-scrollbar {
  width: 6px;
}

.foler-container::-webkit-scrollbar-thumb {
  background-color: $accent;
}

.move-files-modal,
.move-files-from {
  .close-icon {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 20px;
    right: 29px;
  }

  //Over writing default design
  .bx--modal-container {
    padding: 30px;
    padding-top: 25px;
    width: 1070px;
    max-width: 1070px;
    min-height: 710px;
    position: relative;

    .bx--modal-header {
      margin-bottom: 45px;
    }

    .bx--modal-header__heading {
      font-weight: 500px;
      font-size: 24px;
    }

    .bx--modal-content {
      margin-bottom: 24px;
    }

    .bx--modal-footer {
      margin-left: unset;
      margin-right: unset;
      padding: unset;
    }
  }

  .from-modal-container {
    .selected {
      display: flex;
      justify-content: space-between;
      .selected-files-folder {
        flex: 52%;

        > p {
          font-weight: 500px;
          font-size: 20px;
        }
      }

      .selected-destination {
        flex: 46%;

        > p {
          font-weight: 500px;
          font-size: 20px;
        }
      }
    }
  }

  .destination-option {
    margin-top: 35px;
    margin-bottom: 12px;

    display: flex;
    align-items: center;
    gap: 11px;

    > label {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      margin-right: 30px;
    }

    input[type="radio"] {
      -webkit-appearance: none;
      cursor: pointer;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      outline: none;
      border: 1px solid $accent;
    }

    input[type="radio"]:before {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      margin: 2px auto;
      border-radius: 50%;
    }

    input[type="radio"]:checked:before {
      background: $accent;
    }

    input[type="radio"]:checked {
      border-color: $accent;
    }
  }

  .folder {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5px;

    .right {
      flex: 42%;

      > p {
        margin-bottom: 16px;
        .breadcrumb {
          padding-top: 27px;
        }
      }
    }

    .arrow {
      padding: 0px 30px;

      > img {
        position: absolute;
        right: 49%;
        top: 50%;
      }
    }

    .breadcrumb-container {
      display: flex;
      min-height: 61px;

      .breadcrumb {
        margin: 0;
        padding-top: 0px;
        flex-wrap: nowrap !important;
        width: 450px;
        overflow-x: auto;
        .bx--breadcrumb-item {
          > button {
            text-transform: capitalize;
            font-size: 14px;
            margin-top: 5px;
          }
        }
      }
    }

    .left {
      flex: 37%;
      > p {
        margin: 16px 0;
      }
    }

    .foler-container {
      width: 460px;
      height: 330px;
      overflow-y: auto;
      border-top: 1px solid $accent;
      padding-top: 6px;
      padding-right: 14px;
      
      .isSelected {
        p {
          color: $accent;
        }
        svg {
          path {
            fill: $accent;
          }
        }
      }

      .folder-info {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 6px 0;

        .folder-name {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;

          > p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 365px;
          }
        }

        .move-in-folder {
          margin-left: auto;
          height: 14px;
          width: 8px;
        }

        .bx--form-item {
          flex: unset;
          margin: 0px;
          margin-right: 12px;
        }
      }
    }
  }
}

// From modal design over write
.move-files-from {
  .bx--modal-container {
    .folder {
      .right {
        .foler-container {
          .folder-info {
            .folder-name {
              .bx--checkbox-wrapper {
                margin-right: 0px;
              }
            }
          }
        }
      }
      .left {
        .foler-container {
          border-right: 0px;
          .folder-info {
            gap: 12px;
          }
        }
      }
    }
  }
}
