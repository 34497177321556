.publicShare {
  display: flex;
  position: absolute;
  width: 100%;
  z-index: 20001;
  top: 0;
  background-color: #0f0f0f;
.UploadsActivity{
  left:0 !important;
}
  .publicShareBackground {
    background:#000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    max-width: 630px;


    padding: 120px 0;
    width: 100%;
    @media (max-width: 768px){

      max-width: 770px;
    }

    .publicShareWrapper {
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 25px;
      max-width: 430px;
      background: #141414;
      position: relative;
      width: calc(100% - 40px);
      @include max-screen(1000px) {
        align-items: center;
        display: flex;
        flex-direction: column;
      }
      p {
        color: white;
      }
    }

    .publicShareForm {
      max-width: 430px;
      padding-top: 60px;
      width: 100%;
      .bx--text-input{
        min-width: 100px;
      }
      @media (max-width: 768px){
        .bx--modal-container{
          margin:30px;
        }
      }
      @media (max-width:599px){
        .bx--modal-container{
          min-width:20%;
          height: 80%;
        }
        .bx--modal-footer{
          margin-left: -1.2rem;
          margin-right: -1.2rem;
        }
      }
      @media (max-width:455px){
        .bx--modal-container{
          min-width:20%;
          height: 80%;
        }
        .bx--modal-footer{
          margin-left: -0.7rem;
          margin-right: -0.7rem;
        }
      }
    }

    .selectedFiles {
      width: 100%;
      max-height: 237px;
      overflow-y: auto;
      margin-top: 13px;
    }

    .selectedFiles::-webkit-scrollbar {
      width: 10px;
  }

    .selectedFiles::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
  }

    .selectedFiles::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
      background-color: $white;
  }

    .selectedFile {
      font-size: 16px;
      padding-bottom: 5px;
    }

    .fileSize {
      color: #B3B3B3 !important;
      font-size: 14px;
      margin-left: 13px;
    }

    .fileOptions {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;
    }

    .selectedFileContainer {
      width: 100%;
      padding: 10px 30px;
      border-bottom: 1px solid #0D0D0D;
    }

    .new-file {
      border: none;
      outline: none;
      background: linear-gradient(98.64deg, $accent -17.67%, #ec2a29 150.2%);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 15px;
      @include min-screen(1024px) {
        right: 62px;
      }
    }
  }
  .uploadFiles {
    width: 100%;
    margin-top: 15px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    .folder-select:hover{
    color:$accent;
    cursor: pointer;
    }
  }

  .file-share {
    display: none;
  }

  .verificationCode {
    margin-bottom: 12px;
  }

  .publicError {
    color: #ec2a29 !important;
  }

  .publicShareContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .deleteFile {
    margin-right: 30px;
  }

  .deleteFile:hover {
    cursor: pointer;
  }

  .publicDownload {
    display: flex;
    width: 88px;
    height: 88px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 3px solid #B3B3B3;
    margin-top: 35px;
  }
  .downloadTextContainer {
    justify-content: center;
    align-items: center;
    h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 3px;
    margin-top: 26px;
    text-align: center;
    }
    .expirationLimitText {
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      color: $accent !important;
      text-align: center;
    }
  }
  .downloadLine {
    width: 100%;
    height: 0;
    border: 0.5px solid $accent;
    margin-top: 20px;
    padding: 0 30px;
  }
  .publicDownloadButton {
    margin: 20px 0;
    width:100%;
    padding: 0 15px;
    button {
      height: 40px;
    }
  }
  .downloadLogoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 28px;
  }
  .downloadButton {
    width:100%;
    background-color: $accent;
  }
  .downloadWrapper {
    max-width: 430px !important;
    padding: 0 !important;
    margin: 0 30px;
  }
  .downloadImageContainer{
    margin-right: 16px;
  }
  .downloadImageContainer:hover {
    cursor: pointer;
  }
  .downloadImage {
    width: 20px;
    height: 20px;
  }
  .navbar{
    position:fixed;
    z-index: 10000;
    width:100%;
    padding: 28px 20px;
    background:  linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 88.02%);
   &-items-wrapper{
     display: flex;
     align-items: center;
     justify-content: flex-end;
   }
    &-item{
           font: normal normal 15px/100% Poppins;
      padding:12px 28px ;

   }
    a{
      color:$white;
      text-decoration: none;
    }

  .public-share-logo-container{
    display:flex;
    align-items: center;
    justify-content: space-around;
    }
  .expiredText {
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    color: #FFF;
    text-align: center;
  }
  .expiredLine {
    width: 100%;
    height: 0;
    border: 0.5px solid #242424;
    margin-top: 40px;
    padding: 0 30px;
  }

    }

}
.expiredText {
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  color: #FFF;
  text-align: center;
}
.expiredLine {
  width: 100%;
  height: 0;
  border: 0.5px solid #242424;
  margin-top: 40px;
  padding: 0 30px;
}
.public-share-logo-container{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.public-share-logo-container{
  display: flex;
    justify-content: space-around;
    align-items: center;
    .appBranding{
      height:100px;
      width:185px;
      margin-bottom: 10px;
    }

      .app-logo {
        display: block;
        width: 100%;
        margin-left: 3%;;

      }
    }
    .form-wrapper{
      padding: 0 30px;
    }
    .button-wrapper{
      padding: 20px 30px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
    .transfer-button{
      width:100%;
      border:transparent;
      background: linear-gradient(98.64deg, $accent -7.67%, #EC2A29 150.2%);
      box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      height: 40px;
      font: normal 500 15px/22px Poppins;
      &:hover{
        background: linear-gradient(98.64deg, $accent -7.67%, #EC2A29 150.2%);
      }
    }

    .first-option{
      border-bottom: 1px solid #242424;
    }

    .options{
      background: #000000;
      padding: 30px 0;

      &-container{
        display:flex;
        padding: 10px 30px;
        align-items: center;
        justify-content: space-between;
      }

      &-plan-button{
        display:flex !important;
        border:transparent;
        width: 90px;
        height: 24px;
        padding:13px 0;
        background: linear-gradient(98.64deg, $accent -7.67%, $accent -17.65%, #EC2A29 150.2%);
        box-shadow: 0px 3px 34px rgba(0, 0, 0, 0.55);
        border-radius: 12px;
        font: normal 600 14px/21px Poppins
      }
      &-plan-button:hover{
        background: linear-gradient(98.64deg, $accent -7.67%, $accent -17.65%, #EC2A29 150.2%);

      }
      &-heading{
        font: normal normal 16px/24px Poppins;
        color: $accent !important;
      }
      &-sub-text{
        font: normal normal 14px/21px Poppins;
      }

    }


  .plan-tooltip {
    width: 208px;
    height: 321px;
    margin-left: 20px !important;
    background: $grey-dark;
    border: transparent !important;
    box-shadow: 0px 3px 34px rgba(0, 0, 0, 0.35);
    padding: 25px;
    z-index: 100000 !important;
    .bx--tooltip__caret{
      background: $grey-dark !important;
      border: transparent !important;
      width: 30px !important;
      margin-left: -10px;
      height: 30px !important;
      border-color: #2e2d2d !important;
      box-shadow: 1px 1px 0px #0000001f;
    }
    .plan{
      display:flex;
      flex-direction: column;
      align-items: center;

      text-align: center;
      margin-top: 0px;
      &-img-container{
        width:124px;
        height:124px;
        margin-top: 0px;
        margin-bottom: 40px;
      }
      &-img{
        width:124px;
        height: 124px;
      }
      &-text{
        color:$white;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        padding: 0px;
        margin-bottom: 34px;
        width: 100%;
      }
      &-button{
        width: 155px;
        height: 40px;
        background: linear-gradient(98.64deg, $accent -7.67%, #EC2A29 150.2%);
        box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.05);
        border-radius: 3px;
        border:transparent;
        font:normal 500 15px/22px Poppins;
    }

  }

    .bx--btn {
      width: 157px;
    }

  }
  .drag-effect{
    position: absolute;
    background: $accent;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display:flex;
    align-items: center;
    justify-content: center;
    color:white;
    text-align: center;
    font:normal 500 15px/22px Poppins;
  }
  .advertisement-container{
    display:flex;
    align-items: center;
    width: 100%;
    .advertisement-img{
      width:calc(100% - 95px)
    }

  }
  .terms-conditions-wrapper{
    padding-top: 10px;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    .terms-conditions-text{
      text-decoration: none;
      margin-top: 0.7rem;
      font:normal normal 14px/22px Poppins;
      @media (max-width: 374px){

      font:normal normal 13px/22px Poppins;
      }
      @media (max-width: 320px){

        font:normal normal 11px/22px Poppins;
        }
    }
  }


  .public-share-download-logo-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    .appBranding{
      height:110px;
      width:214px;
      margin-bottom: 10px;
    }

      .app-logo {
        display: block;
        width: 100%;
        margin-left: 3%;;

      }
    }
    .download-advertisement-container{
      height: 100vh;
      position: fixed;
      width: 100vw;
      .advertisement-img{
        display: flex;
        height: 100vh;
        margin-left: auto;
        object-fit: cover;
        object-position: top;
        width: calc(100vw - 630px);
        @media (max-width: 768px){

          width: calc(100vw - 770px);
        }
      }
      .backgroundCredit {
        display: none;
        @media (min-width: 830px) {
            display: block;
            position: absolute;
            bottom: 35px;
            right: 23px;
            color: white;
            font-size: 12px;
            font-family: Poppins, 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
        }
      }
  }
  .advertisement-container{
    height: 100vh;
    position: fixed;
    width: 100vw;
    .advertisement-img{
      display: flex;
      height: 100vh;
      margin-left: auto;
      object-fit: cover;
      object-position: top;
      width: calc(100vw - 630px);
       @media (max-width: 768px){

        width: calc(100vw - 770px);
      }
    }

    .backgroundCredit {
      display: none;
      @media (min-width: 830px) {
          display: block;
          position: absolute;
          bottom: 35px;
          right: 23px;
          color: white;
          font-size: 12px;
          font-family: Poppins, 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
      }
    }
  }
  .toggle-button{
    position:fixed;
    z-index: 10002;
    border: transparent;
    background-color: transparent;
    .nav-toggle-icon{
      height:20px;
      width:20px;
    }
    .nav-toggle-icon-cross{
      height:30px;
      width:30px;
      margin-top: -5px;
      margin-left: -5px;
    }
  }
  .navbar-items-mobile-wrapper{
    display: flex;
    flex-direction: column;
    align-items: left;
    .navbar-item{
      padding:12px 7px !important;
      text-align: left;
    }
   }
  .free-limit-text{
    text-align: center;
    padding: 30px 0 10px;
    font:normal normal 15px/22px Poppins;
  }


.download-public-share{
  .downloadWrapper.publicShareWrapper{
    margin-top: 66px;
    .publicDownload{
      margin: 17px auto 6px auto;
    }
  }
  .publicShareContainer{
    align-items: baseline;
  }
  .downloadLine{
    border: none;
    margin-top: 23px;
  }
  .downloadTextContainer{
    h1{
      margin-top: 0px;
    }
  }
  .imagePreviewContainer{
    margin-right: 29px;
  }
  .downloadImageContainer{
    margin-right: 25px;
  }
  .selectedFileContainer{
    .selectedFile{
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

//
// SEND FILE  - START
//

#sendFile {


  .navbar{

    .navbar-items-wrapper {
      gap: 59px;
      margin-right: 54px;
    }

    .navbar-item {
      padding: 12px 1px;
    }
  }

  .publicShareBackground {
    width: 555px;
  }

  overflow: hidden;

  .appBranding{
    margin: 0px;
    width: 217px;
    height: 112px;
    margin-bottom: 46px;
    .appLogo {
      margin-left: 0px;
      width: 217px;
    }
  }



  .publicShareWrapper {
    background: $grey-dark !important;
    width: 399px !important;
    margin-top: 0px;
    overflow: hidden;

    @media (max-width: 768px) {
      width: 320px !important;
    }

    margin-right: 10px;
    margin-left: 10px;

    padding: 37px 30px 74px 36px;

    .uploadFiles {
      padding: 0px;
      display: flex;
      flex-direction: row;
      gap: 22px;
      margin-bottom: 0px;
      margin-top: 0px;

      .file-text {
        .file-text-title {
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
        }

        .folder-select {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }
      }

      .uploadImgContainer {
        width: 57px;
        height: 57px;

        .upload-plus {
          width: 57px;
          height: 57px;
        }
      }

    }

    .lineBreakFiles {
      // border-top: 1px solid $componentBackground;
      width: 430px;
      height: 1px;
      background-color: $componentBackground;
      margin-bottom: 35px;
    }

  }

  .selectedFiles {
    overflow-y: auto;
    padding-right: 0px;
    padding-bottom: 0px;
    max-height: 210px;
    overflow-x: hidden;
    margin-right: 20px;
    width: 333px;

    margin: 0px auto;
    margin-bottom: 30px;
    margin-top: 40px;


    /* width */
    &::-webkit-scrollbar {
      width: 9px !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent !important;
      -webkit-box-shadow: none;
      border-radius: 0px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $greyFieldTitle !important;
      border-radius: 0px;
      -webkit-box-shadow: none;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $greyFieldTitle !important;
    }

    .publicShareContainer {
      width: 306px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0px;
      border-bottom: 1px solid $grey;
      padding-top: 14px;
      padding-bottom: 19px;

      .deleteFile {
        margin-right: 0px;
      }
    }

    :first-child {
      padding-top: 0px !important;
    }

  }

  .selectedFileContainer {

    border-bottom: 0px;
    padding: 0px;

    .selectedFile {
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      overflow: hidden;
    }

    .fileOptions {
      display: flex;
      flex-direction: row;
      margin-bottom: 0px;
      gap: 7px;
      align-items: center;

      .fileExtension {
        min-width: 20px;
        width: fit-content;
        height: 10px;
        padding: 1px;
        min-width: 20px;
        font-size: 7px;
        line-height: 7px;
        background-color: white;
        border-radius: 1px;
        color: black;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      .fileItemSize {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: $greyFieldTitle;
      }
    }
  }


  .publicShareForm {

    padding-top: 0px;

    .bx--form {
        margin-top: 0px;
    }

    .form-wrapper {
      padding: 0px;

      .formRow {
        margin-bottom: 35px;
      }

    }

    .options {
      background-color: transparent;
      margin-top: 35px;

      padding: 0 0 43px 0;

      .options-container {
        padding: 10px 0px 0px 0px;
        margin-bottom: 10px;
        border-bottom: 0px;
        border-bottom: 1px solid #242424;
      }

      .terms-conditions-wrapper {
        padding: 10px 0px;

        .bx--checkbox-wrapper {
          padding-right: 14px;
          flex: 0 0;
        }

        .terms-conditions-text {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: white;
        }

      }

      .options-heading {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: $accent
      }

      .options-sub-text {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
      }

    }

    .button-wrapper {
      padding: 0px;

      .transfer-button {
        background: $accent;
      }
    }


    .bx--checkbox {
      width: 20px;
      height: 20px;
    }

  }


  .send-advertisement-container {
    position: absolute;
    width: 100vw;
    min-height: 100vh;
    .advertisement-img{
      display: flex;
      min-height: 100vh;
      margin-left: auto;
      object-fit: cover;
      object-position: top;
      width: calc(100vw - 555px);
  }
}

}
