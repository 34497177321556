.bx--btn {
  border-radius: 4px;
  color: $white;
  transition: border 300ms ease, color 300ms ease, background 300ms ease;
  font-family: $poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  height: 36px;
  border-radius: 4px;
  outline: none;
}

.bx--btn--primary {
  background: $accent;
  &:hover,
  &:active,
  &:focus {
    border-color: #BC5B25;
    background: #BC5B25;
    // color: btnbackground;
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
    border: 1px solid #B3B3B3;
    background-color: transparent;
    &:hover {
      color: $white;
      background-color: transparent;
    }
  }
}

.bx--btn--secondary {
  border: 1px solid $white;
  background: transparent;
  border: 1px solid #B3B3B3;

  &:hover,
  &:focus {
    border: 1px solid #B3B3B3;
    background: rgba(255, 255, 255, 0.17);
    outline: none;
  }

  &:active
   {
    // background: $btnbackground;
    border: 1px solid #B3B3B3;
    color: $white;
    background: rgba(255, 255, 255, 0.5);
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
    border: 1px solid #B3B3B3;
    &:hover {
      color: $white;
    }
  }
}

.bx--btn--mt {
  margin-top: 10px;
}

.bx--btn + .bx--btn {
  margin-left: 20px;
}

.bx--breadcrumb {
  flex-wrap: wrap !important;
}
.project-files {
  .item-ghosted {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
    pointer-events: none !important;
    color: gray !important;
  }
}


// Generic Item Ghosted Styles - Start

.item-ghosted{
  opacity: 0.5;
  cursor:not-allowed;
  pointer-events: none;
  color: gray !important;
}

// Generic Item Ghosted Styles - End
.breadcrumb{
  .bx--link{
    color:$white !important;
  }
}


.progressBar {
  height: 8px;
  border-radius: 4px;
  border-color: white;
  background-color: white;
  .currentProgress { 
    height: 8px;
    border-radius: inherit;
    border-color: $accentDark;
    background-color: $accentDark;
  }

  .success {
    border-color: $success-color;
    background-color: $success-color;
  }
  .fail {
    border-color: $error-color;
    background-color: $error-color;
  }

}



// Icon Button - Start
// 

.iconButton {

  display: flex;
  flex-direction: row;
  gap:12px;
  padding: 0px 10px;
  border: 1px solid $greyFieldTitle;
  border-radius: 4px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &--active {
    background-color: $accent;
    border: 1px solid $accent;
  }

  &--disabled {
    cursor: auto;
    pointer-events: none;
  }

  &:hover {
    background-color: $accent;
    border: 1px solid $accent;
  }

  .icon {
    width: 20px !important;
    height: 20px !important;
  }

  .btnText {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

}



// 
// Icon Button - End

.notFound {
  height: 100px;
  display: flex;
  align-items: center;
}