.app-footer {
  display: flex;
  flex-direction: column;
  margin-top: 65px;
  row-gap: 7px;
  align-items: center;
  .legal-text {
    font-family: $poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #FAFAFA;
  }
  .legal-links-container {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
    .legal-links {
      font-family: $poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $accent;
    }
  }
}
