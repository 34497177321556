.appActivity {

  //TODO WORK
  background: #1F1F1F;
  display: flex;
  flex-direction: column;
  height: calc(100vh - (66px + 18px));
  overflow: hidden;
  padding: 20px;
  position: fixed;
  right: 0;
  top: calc(66px + 18px);
  width: 394px;
  z-index: 9002;
  box-sizing: border-box !important;
  transition: width 100ms ease-in-out;

  @include min-screen(1024px) {
    padding: 14px 20px 14px 15px;
    //ACTIVITY PANE COLLAPSED SIZE
    width: 0px;
    right: -50px;
    z-index: 9002;
  }

  &.appActivityActive {
    width: 388px;
    right: 0px;
    padding-bottom: 0px !important;
    z-index: 1;
  }
}

.appActivityTitle {
  color: white;
  font-size:18px;
  line-height: 22px;
  margin: 15px 0 30px;
  font-weight: 800;
  word-break: break-all;
  display: flex;
  align-items: center;
  justify-content: space-between;
&__heading {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  word-break: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}
button.Comments__close {
  display: flex;
  align-items: flex-start;
  padding-top: 2px;
}
&__subheading {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #4E4E4E;
}
}


.appActivityIcon {
  background: none;
  border: none;
  margin-bottom: 30px;
  outline: none;
  padding: 0;
  text-decoration: none;
  width: 100%;

  .appActivityIndicator {
    background: $componentBackground;
    border-radius: 50%;
    color: $accent;
    font: 500 16px/60px $poppins;
    height: 60px;
    margin: 0 auto 20px;
    text-align: center;
    transition: opacity 300ms ease-in-out;
    width: 60px;

    // .appActivityActive & {
    //   // opacity: 0;
    //   // display:none;
    // }
  }

  .appActivityIconText {
    color: #bbbbbb;
    font: 500 14px/1.4 $poppins;
    text-align: center;
    width: 100%;

    .appActivityActive & {
      // margin-top: 40px;
      text-align: right;
    }

    span {
      margin-left: 4px;
      opacity: 0;
      transform: translateX(100px);
      transition: opacity 300ms ease-in-out;
      vertical-align: middle;

      .appActivityActive & {
        opacity: 1;
        transform: translateX(0);
      }

      svg {
        height: 12px;
        width: 14px;
      }
    }
  }
}

.appActivityFeed {
  // height: calc(100vh - 220px);
  height: 100%;
  opacity: 0;
  overflow-x: hidden;
  position: relative;
  transition: opacity 300ms ease;
  overflow: overlay;



  .appActivityActive & {
    opacity: 1;
    padding-bottom: 0px !important;
  }

  &:after {
    background: linear-gradient(
      to bottom,
      #1f1f1f00 0%,
      #1F1F1F 80%
    );
    bottom: 0;
    content: "";
    height: 70px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.appActivityItem {
  border-bottom: 1px solid $componentBackground;
  margin: 0 0 15px;
  padding: 0 18px 10px 0;

  .activityTimeAgo {
    align-items: flex;
    display: flex;
    margin-bottom: 10px;
    justify-content: flex-end;
    padding-right: 5px !important;

    .activityTimeAgoText {
      color: #bbbbbb;
      font: 500 12px/1.4 $poppins;
      letter-spacing: letter-spacing(25, 12px);
      padding-right: 10px;
      display: flex;
      text-align: end;
      padding-right: 0px;
    }

    .itemSave {
      background: none;
      border: none;
      display: inline-block;
      height: 20px;
      padding: 0;
      width: 20px;

      svg {
        height: 20px;
        fill:$componentBackground;
        width: 20px;
      }

      &.itemSaved {
        svg * {
          fill: $accent;
        }
      }
    }
  }

  .activityBody {
    color: white;
    padding: 10px 0;
    & p {
      padding-right: 35px !important;
      font-size: 14px !important;
      // border: pink solid 1px !important;
    }
    &__meta {
      color: #B3B3B3;
      font-size: 14px;
      margin-bottom: 12px;
      height: 21px !important;
      display: grid;
      grid-template-columns: 30px auto auto;
      align-items: center;
      & > * {
        // border: yellow solid 1px;
      }
    }
    .activityuser {
      color: #ffffff !important;
    }

    // display: flex;

    .thumbnail {
      border-radius: 50%;
      height: 50px;
      margin-right: 20px;
      overflow: hidden;
      position: relative;
      width: 50px;

      img {
        height: 100%;
        object-fit: cover;
        object-position: center center;
        width: 100%;
      }

      .profileURL {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    
    .preview {
      a {
        color: $white !important;
        text-decoration: none !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
      }
      color: #bbbbbb;
      font: 500 12px/1.4 $poppins;
      // height: 16px;
      margin-top: 6px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      // width: 180px;
    }

    .description {
      color: $white;
      font: 700 12px/1.4 $poppins;
      letter-spacing: letter-spacing(25, 12px);
      // width: calc(100% - 70px);

      a {
        color: $white;
        text-decoration: none;
      }
    }
  }
}

.activityPaneButton {
  background-color: #1F1F1F !important;
  width: 50px !important;
  height: 80px !important;
  border-top: #D5743E solid 1px !important;
  border-left: #D5743E solid 1px !important;
  border-bottom: #D5743E solid 1px !important;
  border-radius: 5px 0px 0px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-bottom: 5px !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 30px !important;
  text-align: center;
  position: fixed !important;
  right: -2px !important;
  top: 108px !important;
  z-index: 1 !important;
  color: $accent;

}

// .appActivityActive {
//   opacity: 1;
//   transform: translateX(0);

// }
