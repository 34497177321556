.preview-image{
    width: 113px;
height: 113px;
}
.playlist-description-wrapper{
    display: flex;
    flex-direction: column;
    .playlist-description-heading{
        font-family:$poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: #B3B3B3;
        padding-bottom: 10px;
    }
    .playlist-description{
        font-family: $poppins;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }
}



///Empty Info Tile
///
.empty-info-container {
    height: 282px;
    width: 242px;
    background: #000000;
    border: 1px solid #272727;
    .empty-icon {
        height: 240px;
        width: 240px;
        border-bottom: 1px solid #272727;
        display: flex;
        justify-content: center;
        align-items: center;
        .empty-image {
            cursor: pointer;
        }
    }
    .empty-text {
        margin: 10px;
        h3 {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            padding: 0px;
        }
    }
}
.playlist-empty {
    padding-top: 40px !important;
}




////////////// PLAYLIST HERO SECTION START////////////////////
///
///


.playlist-hero {
    .sections-container {
        height: 120px;
    }
    .heroContent {
        margin-top: 0px;
        justify-content: space-between;
        align-items: center;
        height: inherit;
        // margin-left: 3px;
        height: 120px;
        padding: 35px 0px !important;
        h1 {
            line-height: 51px !important;
        }
    }
    .bx--search-input {
        // width: 292px;
        // height: 40px;
        background: #1F1F1F;
        border-radius: 2px;
    }
    .playlist, .playlistCover, .playlistArt {
        margin-bottom: 0px !important;
    }
    .playlistInfo {
        padding-left: 13.3px;
        width: calc(100% - 40px);
        h3 {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 0px;
        }
        aside {
            font-size: 12px;
            color: #737373;
            margin-top: 2px;
        }
    }
    .playlistInfoContainer {
        padding:9px 0px;
        border-width: 0px 1px 1px 1px;
        border-style: solid;
        border-color: #272727;
        .dropdown-menu {
            right: 0px;
            left: auto !important;
        }
    }
    .playlistInfoOptions {
        padding-right: 16px;
    }
    .grid {
        grid-gap: 20px 20px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        display: inline-grid;
    }
    .title-container {
        padding-left: 3.5px;
    }
    .dropdown-icon {
        margin: auto;
        margin-right: 20px;
    }
}
.playlist-hero {
  .hero {
    img {
      opacity: 1;
    }
  }
.playlist-page {
    padding-top: 41px !important;
    margin-left: 56px !important;
}
}


///
///
////////////// PLAYLIST HERO SECTION END ////////////////////

