.bx--data-table-v2-container {
  min-width: 0;
  max-width: calc(100% - 2px);
  overflow: visible;
}

.bx--data-table-v2 {
  border: 0 none;
  border-collapse: separate;

  border-spacing: 0 12px;
  color: $white;
  // width: 1098px;

  thead,
  tbody {
    border: 0 none;

    background: transparent;
  }

  th {
    // border-bottom: 1px solid #4e4e4e;
  }

  th,
  .bx--table-sort-v2 {
    border-top: 0 none;
    color: $white;
    font: 700 13px/1.2 $poppins;
  }

  td {
    // border-bottom: 1px solid #4e4e4e;
    border-top: none;
    border-left: none;
    font: 500 13px/1.2 $poppins;
  }

  tr.bx--expandable-row-v2 > td:first-of-type:before {
    display: none;
  }

  th,
  td {
    color: $white;
    padding-top: 8px;
    padding-bottom: 8px;

    &:first-of-type {
      border-left: 0 none;
      padding-left: 22px;
    }

    &:last-of-type {
      padding-right: 22px;
    }
  }

  &--zebra {
    thead > tr,
    tbody tr:nth-child(even) {
      background: $componentBackground;
    }

    .playlistRowSelector {
      width: 55px;
    }
    .playlistRowIndex {
      width: 40px;
      padding-left: 34px !important;
      text-align: center;
    }

    .playlistRowIndexSwapicons {
      width: 80px;
      padding-left: 23px !important;
      text-align: center;
      .table-index-container--number {
        margin-left: 19px !important;
      }
    }
  }

  tr {
    background: $componentBackground;
    &:hover {
      td {
        background: transparent;
        border-top: none;
        border-bottom: none;
        border-bottom-color: transparent;

        &:first-of-type,
        &:last-of-type {
          border-left: 0 none;
          border-right: 0 none;
        }
      }
    }

    &.bx--expandable-row-v2.bx--parent-row-v2 td {
      border-bottom: none;
    }
  }

  &--select-row {
    // width: 64px;
  }
}

.playlistTable {
  td {
    &:last-of-type {
      width: 100px;
    }
  }
}

.bx--data-table-v2--zebra tbody tr.bx--data-table-v2--selected,
tbody tr.bx--data-table-v2--selected {
  background: $componentBackground;
}

.bx--table-toolbar {
  margin-bottom: 15px;

  .bx--batch-actions--active {
    background-color: $accent;
  }

  .bx--batch-summary__para {
    @include max-screen(767px) {
      display: none;
    }
  }

  .bx--batch-actions .bx--btn--ghost:hover,
  .bx--batch-actions .bx--btn--ghost:focus {
    color: $componentBackground !important;

    .bx--btn__icon {
      fill: $componentBackground !important;
    }
  }
}

.bx--table-toolbar:empty {
  padding: 0;
  margin: 0;
}

.bx--checkbox:checked + .bx--checkbox-label::before,
.bx--checkbox:indeterminate + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-state="true"]::before,
.bx--checkbox-label[data-contained-checkbox-state="mixed"]::before {
  background-color: $accent;
  border-color: $accent;
}

.bx--checkbox-label::after {
  border-left: 1px solid $componentBackground;
  border-bottom: 1px solid $componentBackground;
}

.bx--table-expand-v2__svg {
  fill: $accent;
}

tr.bx--expandable-row-v2 {
  > td {
    &:first-child {
      padding-left: 22px;
    }

    &:last-child {
      padding-right: 22px;
    }

    > p {
      // font: 500 13px/1.2 $poppins;
    }
  }

  td[colspan] {
    &:first-child {
      padding-left: 45px;
    }
  }
}

tr.bx--expandable-row-v2:hover + tr[data-child-row] > td {
  background: unset;
  border: none;
  // font: 500 13px/1.2 $poppins;
  // padding: 10px 10px 20px;
}

.bx--table-sort-v2__icon,
.bx--table-sort-v2:focus svg {
  fill: $accent;
}

.bx--table-sort-v2:focus svg {
  outline: none;
}

.table-search {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;

  .bx--search {
    width: fit-content;
  }
  input {
    width: 243px;
  }
}

.bx--data-table-v2.bx--skeleton th {
  border-bottom-color: $accent;
}

.bx--data-table-v2.bx--skeleton th span,
.bx--data-table-v2.bx--skeleton td span,
.bx--data-table-v2.bx--skeleton th span:before,
.bx--data-table-v2.bx--skeleton td span:before {
  background: rgba($accent, 0.1);
}

.bx--data-table-v2.bx--skeleton tr:hover td {
  border-bottom-color: #4e4e4e !important;
}

.bx--data-table-v2,
.columnedTable {
  font-size: 14px;

  @include min-screen(768px) {
    font-size: 16px;
  }

  .itemSave {
    background: none;
    border: none;
    display: inline-block;
    height: 20px;
    padding: 0;
    width: 20px;

    svg {
      height: 20px;
      fill: none;
      stroke: #B3B3B3;
      width: 20px;
    }
    
    &.itemSaved {
      svg * {
        fill: $accent;
      }
    }
  }
}

.bx--data-table--no-results {
  padding-left: 1rem !important;
}

.columnedTable {
  &__row {
    display: flex;
    align-items: center;

    &:first-child {
      @include max-screen(767px) {
        height: 100%;
        left: 0;
        position: absolute;
        flex-direction: column;
        top: 15px;
      }

      div {
        @include max-screen(767px) {
          order: -1;
          margin-bottom: 5px;
        }
      }
    }
  }

  &__column {
    flex: 0 0 50%;
    word-break: break-word;
  }

  &__section {
    border-bottom: 1px solid #4e4e4e;
    color: white;
    position: relative;

    &:first-child {
      border-top: 1px solid #4e4e4e;

      @include max-screen(767px) {
        padding-top: 0 !important;
      }

      .columnedTable__row {
        &:first-child {
          top: -5px;
        }
      }
    }

    @include max-screen(767px) {
      border-top: 0 none !important;
      display: grid;
      grid-gap: 8px;
      grid-template-columns: 1fr;
      padding: 20px 0 20px 40px;
      width: 100%;
    }
  }

  &--with-batch-actions {
    .columnedTable__row {
      @include max-screen(767px) {
        &:first-child {
          flex-direction: row;
          height: auto;
          left: initial;
          position: relative;
          top: initial;
          width: auto;

          div {
            order: 1;
          }

          .columnedTable__column {
            flex-basis: auto;
            margin-right: 8px;
          }
        }

        &:nth-child(2) {
          height: 100%;
          flex-direction: column;
          left: 0;
          position: absolute;
          top: 15px;
          width: 45px;

          div {
            order: -1;
            margin-bottom: 5px;
          }
        }
      }
    }

    .columnedTable__section {
      &:first-child {
        top: 0;

        .columnedTable__row:first-child {
          top: 0;
        }

        .columnedTable__row:nth-child(2) {
          @include max-screen(767px) {
            top: -5px;
          }
        }
      }
    }
  }
}

.bx--data-table-v2 .bx--overflow-menu.bx--overflow-menu--open {
  opacity: 1;
}

.select-row {
  margin-bottom: 10px;
  background-color: #3e3e3e;
  padding: 10px 15px;

  &--selected {
    background-color: $accent;
  }

  input[type="checkbox"] {
    margin-left: 10px;
    vertical-align: middle;
  }
}
.move-radio {
  border: transparent !important;
}
.move-item-name {
  cursor: pointer;
  margin-top: 3px !important;
}

.passive-action {
  opacity: 0.4;
}

.copy-link:hover {
  cursor: pointer;
}
.copy-link-disabled {
  opacity: 0.5;
  &:hover {
    cursor: not-allowed;
  }
}
.row-header {
  font-family: $poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  padding: 0 10px;
  line-height: 18px;
  color: #b3b3b3;
}
.batch-actions-wrapper {
  display: flex;
  margin-left: auto;
  align-items: center;
  padding-right: 22px;
  column-gap: 24px;
  .batch-action-item {
    // margin: 10px;
    font-family: $poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
  }
  .batch-action-icons {
    height: 19px;
    width: 22px;
    cursor: pointer;
  }
  .selection {
    color: #d5743e;
  }
}
.actions-header {
  display: flex;
  align-items: center;
  // padding: 7px 17px;
  padding-left: 22px;
  padding-top: 8px;
  padding-bottom: 8px;
  // padding-right: 12px;
  background-color: $componentBackground;
  &--selected {
    // background: rgba(255,255,255,0.1);
  }
}
.actions-header > th {
  &:has(.bx--checkbox) {
    margin-right: 24px;
  }
}

.actions-header:empty {
  padding: 0;
}

.bx--checkbox-label::before {
  box-sizing: border-box;
  content: "";
  position: absolute;
  left: 0;
  top: calc(50% - 9px);
  height: 20px;
  width: 20px;
  border: 1px solid $white;
  border-radius: 3px;
  background-color: $appBackground;
}

.bx--data-table-v2 .bx--overflow-menu__icon {
  -webkit-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
  fill: $white;
  &:hover {
    fill: $white;
  }
  &:focus {
    fill: $white;
  }
}
.custom-pagination {
  display: flex;
  justify-items: center;
  column-gap: 24px;
  padding-left: 20px;
  .pages-count {
    font-family: $poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }
  .pages-dropdown {
    display: flex;
    align-items: center;
    // margin-left: 20px;
    .arrow-icon {
      margin-top: 15px;
      margin-left: 7px;
    }
    .bx--overflow-menu-options {
      width: fit-content;
      right: -20px;
      left: auto;
    }
    .bx--overflow-menu-options__btn {
      opacity: 1 !important;
      font-size: 0.875rem;
      font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
      font-weight: 400;
      width: 100%;
      height: 100%;
      border: none;
      display: inline-block;
      background-color: transparent;
      text-align: left;
      padding: 0.5rem 1rem;
      cursor: pointer;
      color: $white !important;
      max-width: 11.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: unset;
    }
    .bx--overflow-menu-options::before{
      right: 21px;
      left: auto !important;
    }
  }
  .sorting-dropdown {
    display: flex;
    align-items: center;
    // margin-left: 20px;
    .arrow-icon {
      margin-top: 15px;
      margin-left: 7px;
    }
    .sorting-title {
      font-family: $poppins;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #ffffff;
    }
  }
  .items-wrapper {
    display: flex;
    align-items: center;
    .active {
      border-color: $accent !important;
      color: $accent !important;
    }
    .items-perpage {
      font-family: $poppins;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      border: 1px solid $white;
      padding: 2px 10px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      border-radius: 50px;
      text-align: center;
      color: #ffffff;

      cursor: pointer;
      &:hover {
        border-color: $accent;
        color: $accent;
      }
    }
  }
}

.row-value {
  line-height: 24px;
  text-decoration: none !important;
  &-name {
    text-decoration: none;
    font-family: $poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    & * {
      text-decoration: none;
      color: $accent;
      font-weight: 500;
    }
  }
}

.bx--data-table-v2--zebra {
  tbody {
    tr {
      td {
        padding: 0;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }
}

.bx--data-table-v2 {
  thead,
  tbody {
    tr {
      td {
        .bx--checkbox-label {
          &::before {
            box-sizing: border-box;
            content: "";
            position: absolute;
            left: 0;
            top: calc(50% - 9px);
            height: 20px;
            width: 20px;
            border: 1px solid #b3b3b3;
            border-radius: 1px;
            background-color: $appBackground;
          }
        }

        .itemSave {
          margin-top: 5px;
          margin-right: 10px;
        }
        // style for the title/name

        .link,
        .row-value-name:has(a) {
          cursor: pointer;
          color: $accent;
          text-overflow: ellipsis;
        }
        .row-value {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-align: left;
          padding: 0 10px;
          font-weight: 500;
          @include max-screen(1024px) {
            max-width: 126px;
          }
          @include max-screen(768px) {
            min-width: 50px;
          }
        }
        .row-value-name,
        .link {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-align: left;
          color: $accent;
          .row-value,
          .link {
            padding: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
          }
          padding: 0 10px;
          max-width: 350px;
          @include max-screen(1024px) {
            max-width: 150px;
          }
        }

        &.bx--table-expand-v2 {
          padding-right: 14px;
        }
        .row-actions-dropdown {
          ul {
            background-color: $componentBackground;
            border-color: $componentBackground;
            &::before {
              background-color: $componentBackground;
              border-color: $componentBackground;
            }
            li {
              button {
                color: $white;
                background-color: $componentBackground;
              }
            }
          }
        }
      }
    }
  }

  &.without-expanded-content-table {
    // table-layout: fixed;
    thead,
    tbody {
      tr {
        td {
          .bx--checkbox-label {
            padding-left: 0;
            padding-right: 20px;
          }

          // style for the star icon
          &:has(.bx--checkbox) {
            .bx--checkbox-label {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
.expanded-row {
  border-left: 3px solid $accent !important;
}
.row-header {
  .tooltip--icon {
    position: absolute;
    margin-top: -20px;
  }
}
.expanded-content {
  td {
    vertical-align: baseline;
  }
}
.info-credit {
  margin-top: -9px;
  height: 15px;
  width: 15px;
}
.change-order-icon {
  color: white;
}

.change-order-container {
  display: flex;
  flex-direction: column;
  width: 5px;
  &--rotateIcon {
    transform: rotate(180deg);
  }

  img {
    cursor: pointer;
  }
}

.table-index-container {
  display: flex;
  align-items: center;
  width: 45px;
  margin-right: 0px;
  &--number{
    min-width: 20px;
  }
}
.empty-row{
  display:flex;
  align-items: center;
  justify-items: center;
  height: inherit;
  gap: 12px;
  span{
    color:#676767;
  }
  svg{
    path{
      fill:#676767;
    }
  }
  width: 100%;
}
