.create-Affiliations-Inputs {
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 1240px) {
    .css-xdylir-control {
      width: 250px;
    }
    .affiliationId {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@media (max-width: 650px) {
  .bx--select div {
    font-size: 10px !important;
  }
}

.formRow-my-party {
  margin-bottom: 60px;
}
.fileOptionsContents {
  margin-top: 10px;
  .bx--overflow-menu__icon {
    fill: white !important;
  }
}

.formRowComment {
  margin: 50px 0 35px 0;
}
.affiliations-info-form {
}
.affiliation-container {
  margin: 70px 0 -20px 0;
}
.create-Affiliations-Inputs-Select {
  width: 250px;
}
.title {
  color: $white;
  font: 700 22px/1.4 $poppins;
  margin-right: 23px;
  margin-bottom: 20px;
  @include min-screen(768px) {
    font-size: 34px;
  }
}
.create-Affiliations-Inputs-wrapper {
  display: flex;
  flex-direction: row;
  @media (max-width: 1240px) {
    flex-direction: column;
  }
}
.affiliations-buttons {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.cancel-Affiliations-Button {
  width: 160px;
  @media (max-width: 1240px) {
    margin-right: 13px;
    width: 140px;
  }
}

.affiliations-buttons-display {
  align-items: center;
  margin: 20px 0;
}
.affiliation-add {
  margin: 42px 0px 30px 0px !important;
}

.add-Affiliations-Button {
  border: transparent;
  background-color: transparent;
  .icon-img {
    margin-left: 2px;
  }
}

.add-Affiliations-Button-Disable {
  opacity: 0.5;
  border: transparent;
  background-color: transparent;
  .icon-img {
    margin-left: 2px;
  }
}

.affiliation-Id {
  //margin-left: 13px;
  display: flex;
  align-items: center;
  @media (max-width: 1240px) {
    //margin: 10px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.publisher-container {
  display: flex;
  flex-direction: row;
  width: 210%;
  @media (max-width: 1240px) {
    display: flex;
    align-items: flex-start;
  }
}

.SwitcherStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-Switcher-Content {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 20px;
  cursor: pointer;
  
  min-width: 104px;
  justify-content: flex-end;
  box-sizing: border-box;
  .account-menu-icon {
    width: 24px;
    height: 24px;
  }
  .customization-image{
    width: 29px;
    height: 26px;
    margin: 33px 0 0 0;
  }
}

// .switcher-Style {

//   // background: $appBackground no-repeat;
// }

.main-Switcher {
  display: flex;
  height: 120px;
  align-items: flex-end;
  gap:30px;

  .main-Switcher-Switch-Selected {
    background: none;
    outline: none;
    color: $accent !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    padding: 0px 0px 7px 0px;
    margin-top: 6px;
    border-radius: 0;
    text-decoration: none;
    box-shadow: 0px -2px 0px 0px $accent inset;
    -webkit-box-shadow: 0px -2px 0px 0px $accent inset;
    -moz-box-shadow: 0px -2px 0px 0px $accent inset;
    min-width: 100%;
    text-align: center;
  }
  .main-Switcher-Switch-None {
    background: none;
    outline: none;
    color: $greyFieldTitle !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-top: 6px;
    padding: 0px 0px 7px 0px;
    border-radius: 0;
    text-decoration: none;
    border-bottom: none !important;
    box-sizing: border-box;
    min-width: 100%;
    text-align:center;
  }

  .main-Switcher-Switch-None:hover {
    color: $accent !important;
    box-shadow: 0px -2px 0px 0px $accent inset;
    -webkit-box-shadow: 0px -2px 0px 0px $accent inset;
    -moz-box-shadow: 0px -2px 0px 0px $accent inset;
  }
}

.affiliations-Wrapper {
  .bx--accordion__arrow {
    display: none;
  }
  .bx--accordion__title {
    margin: 0;
    font-size: 16px !important;
    font-weight: 500;
  }
  .bx--accordion__item {
    border-top: none;

  }
  .bx--accordion__heading {
    padding: 18px 0 10px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    width: 100%;
    color: #ffffff;
  }
  .bx--accordion__content {
    padding: 2px 0 5px 0 !important;
    width: min-content;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .bx--accordion__item {
    width: 100%;
  }
  .bx--modal.is-visible {
    background: transparent !important;
  }
  .bx--modal .bx--modal-container {
    border-top: none;
    padding: 22px 26px 5px 33px;
    height: 100vh;
    max-height: 100%;
  }
  .bx--modal-header__heading {
    display: none;
  }
  .bx--modal-header .bx--modal-header__label {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    color: #ffffff;
  }
  .bx--modal {
    justify-content: flex-end;
    transition: width 2s linear 1s;
  }
}

.affiliations-Title-Wrapper {
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 19px;
    margin-top: 22px;
  }
}

.affiliations-Accordion-Item {
  display: flex;
  justify-content: space-between;
  background: #0d0d0d;
  padding: 0 15px;
  margin: 0 0 10px 0;
  height: 41px;
  li {
    display: flex;
    align-items: center;
    button {
      padding: 0px 0px !important;
  }
  }
}

.bx--accordion__item--active {
  .bx--accordion__heading {
    color: $accent;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
}

.affiliations-Accordion-Item-Date {
  padding-right: 27px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  color: #5d5d5d;
}

.affiliations-Accordion-Item-Check {
  margin-top: 5px;
  font-style: normal;
  font-weight: 500;
  color: #fcfcfc;
}

.affiliations-Accordion-Item-Id {
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  color: $accent;
}

.affiliations-Accordion-Item-Date-Wrapper {
  display: flex;
  align-items: baseline;
}

.bx--modal-close {
  top: 29px !important;
  right: 24px !important;
  outline: none !important;
  &:after {
    content: '' !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    outline: none;
  }
}

.bx--modal-close__icon {
  fill: $white;
  width: 20px;
  height: 13px;
}

.css-kj6f9i-menu {
  margin-top: 20px;
  background: $componentBackground !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  &::selection {
    cursor: pointer;
    background: blue !important;
  }
}

.affiliation-Id-Input {
  margin-right: 13px;
  margin-top: 4px;
  .bx--text-input {
    min-width: 237px;
  }
}

@media (max-width: 1240px) {
  .affiliation-Id-Input {
    margin-right: 13px;
    margin-top: 4px;
  }
}
.affiliations-Form {
  margin: 25px 0;
  max-width: 800px;
}
.comment-formRow {
  margin-top: 50px;
}
.comment-formRow-button {
  margin-top: 20px;
}

.jaxsta-wrapper {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #4e4e4e;
  padding: 0 0 15px 0;
  margin: 0 0 16px 0;
  @include min-screen(768px) {
    flex-direction: row;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    background: $accent;
    margin-right: 28px;
    height: 40px;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    border-radius: 6px;
    &:hover {
      background: $accent;
    }
  }
  p {
    margin-top: 10px;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    width: 90%;
    @include min-screen(768px) {
      margin-top: 0;
    }
  }
}

.affiliation-tooltip {
  display: inline-block;
  position: relative;
  top: -20px;
}

.publisher-tooltip {
  display: inline-block;
  position: relative;
  top: -10px;
}

@media (max-width: 1240px) {
  .affiliation-tooltip {
    top: -8px;
  }
}

.subTitle {
  color: $white;
  font: 700 16px/1.4 $poppins;
  margin-right: 23px;
  margin-bottom: 12px;
  @include min-screen(768px) {
    font-size: 24px;
  }
}

.affiliations-container {
  margin: 50px 0;
}

.bx--modal .contact-modal {
& .modal-close-button {
  position: absolute;
  top: 10px;
  right: 20px;
}

}

.party-form-custom .bx--form {
& .create-hero {
  margin-bottom: 35px;
}

.formRow {
  margin-bottom: 35px;
}

.formRowComment {
  margin: 0px 0 35px 0;
}

.party-form {
  margin-top: 46px;
}
}
