.breadcrumb {
  display: flex;
  margin-bottom: 10px;

  &--playlist {
    margin-bottom: 15px !important;
  }

  .bx--breadcrumb-item {
    margin-right: 5px;
    &::after {
      color: $white;
      margin-left: 5px;
    }
    .bx--link {
      background: none;
      border: 0;
      color: $white;
      font: 500 14px/1.4 $poppins;
      margin: 0;
      padding: 0;
      border-bottom: 1px solid transparent;
      &:hover {
        border-bottom: 1px solid $white;
      }
    }

    &:last-of-type {
      .bx--link {
        // color: $accent;
        color: $appTextColor;
      }
    }
  }
}
.margin-0 {
  margin: 0px !important;
}

// Breadcrumb scss for Project Credits
.Breadcrumb-Project-Credits{
  .project-files{
    padding-top: 2px !important;
  }
  .breadcrumb {
    margin-top: 0px !important;
    padding: 20px 0px 0px;
    .bx--breadcrumb-item {
      &:last-of-type {
        .bx--link {
          color: $white;
        }
      }
    }
  }
}
  .breadcrumb-recording {
    .breadcrumb {
  .dropdown-menu {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .actions-header {
    height: 57px !important;
    margin-top: 2px;
  }
  .bx--data-table-v2 {
    margin-top: 1px !important;
  }
  .bx--data-table-v2 tr {
    height: 3.9rem !important;
  }
  .expanded-content, .expanded-content ~ tr {
    transform: translateY(-12px);
}
  .expanded-content {
    border: none !important;
    .expanded-row {
      border-left: 0px !important;
    }
  }
 }
}
