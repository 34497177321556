.mobile-only {
    display: none;

    @include max-screen(767px) {
        display: block;
    }
}

.bx--loading {
  animation-duration: 1000ms;
  margin: 0 auto;
}

.bx--loading__svg {
  stroke: $accent;
}

.bx--overflow-menu__icon {
  fill: rgba($accent, 0.5);
}

.bx--overflow-menu__icon:hover,
.bx--overflow-menu__icon:focus {
  fill: $accent;
}

.bx--overflow-menu:focus {
  box-shadow: none;
}

.bx--overflow-menu:focus .bx--overflow-menu__icon {
  fill: $accent;
}

a {
  color: $accent;
}

button.link {
  background: none;
  border: 0;
  color: $accent;
  padding: 0;
  text-decoration: underline;
}

.move-item{
  display: flex;
  align-items: center;
  .bx--form-item {
    flex: 0 !important;
  }
  button{
    margin-right: 8px;
  }
  span{
    margin-top: 8px;
  }
}

.bx-loading--center {
  margin: 0 auto;
}

// .bx--modal {
//   height: 0;
//   overflow: hidden;
//   // transition: opacity 200ms, z-index 0s 200ms, visibility 0s 200ms;

//   &.is-visible {
//     height: auto;
//     transition: opacity 200ms, z-index 0s 200ms, visibility 0s 200ms;
//   }
// }

#spinner.container {
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #141414 74%) !important;

  .loading {
    background-color: $accent !important;
  }
}


 
 //////////////////////////////////
 ///////NEW CALENDAR STYLING///////
 //////////////////////////////////
 
 
 ////////////////////////////////////
 /// CARBON DATEPICKER DARK STYLING
 
 .flatpickr-calendar.open {
  z-index: 10000000000 !important;
 }
 
 .bx--date-picker__day {
  color: #FFFFFF !important;
  border-radius: 3px !important;
 }
 
 
 .bx--date-picker__day:hover, .flatpickr-day:hover {
  background: rgba($accent, 0.1) !important;
 }
 
 
 .bx--date-picker__day.selected, .flatpickr-day.selected {
  background-color: $accent !important;
  border-color: $accent;
 }
 
 
 .bx--date-picker__month .numInputWrapper .numInput, .flatpickr-month .numInputWrapper .numInput {
  background-color: rgba($accent, 0.1) !important;
 }
 
 
 .bx--modal-content .bx--search-input, .bx--modal-content .bx--text-input, .bx--modal-content .bx--date-picker__input, .bx--modal-content textarea.bx--text-area {
  padding: 0;
 }
 
 
 .flatpickr-weekday {
  color: #FFFFFF !important;
 }
 
 
 .flatpickr-current-month .cur-month {
  color: #FFFFFF !important;
 }
 
 
 .numInputWrapper .numInput.cur-year {
  color: #FFFFFF !important;
  background: #0D0D0D !important;
  }
 
 
 .flatpickr-prev-month, .flatpickr-next-month {
  & svg {
    color: #FFFFFF !important;
    fill: #FFFFFF !important;
  }
 }
 
 
    ////////////////////////////////
    // THIS HIDES THE CALENDAR ICON
    //
    // .bx--date-picker__icon {
    //   display: none !important;
    // }
    // .bx--date-picker__input.flatpickr-input {
    //   padding: 16px 16px 16px 16px !important;
    // }
    // .flatpickr-input.active {
    //   padding: 16px 16px 16px 16px !important;
    // }
    // .flatpickr-input.active:focus {
    //   padding: 16px 16px 16px 16px !important;
    // }
    ////////////////////////////////
 
 
 .css-15k3avv {
  background: #2c2b2a !important;
 
 
  .css-wqgs6e {
    background-color: #2764c6 !important;
    color: $white !important;
    font-size: 12px !important;
  }
 
 
  .css-v73v8k,
  .css-z5z6cw {
    color: $white !important;
    font-size: 12px !important;
  }
 }
 
 
 .bx--overflow-menu-options__option:hover {
  background: var(--accent-color);
  color: white !important;
 
 
  .bx--overflow-menu-options__btn {
    background: none;
    color: white !important;
    opacity: 1 !important;
  }
 }
 
 
 .bx--overflow-menu-options__btn:focus {
  // background-color: white;
  text-decoration: none;
 }
 
 
 .bx--data-table-v2 .bx--overflow-menu {
  opacity: 1;
 }
 
 
 .bx--checkbox-label {
  color: rgba(#fff, 0.8);
  font: 400 12px/1.4 $poppins;
  padding-left: 1.2rem;
 }
 
 
 .bx--modal-close:focus {
  outline-color: $accent;
 }
 
 
 .flatpickr-calendar {
  background: #0D0D0D !important;
  border: #ffffff solid 1px !important;
  border-radius: 6px !important;
 
 
  & .flatpickr-months {
    // color: #FFFFFF !important;
    & .flatpickr-month {
 
 
  }
  & .flatpickr-current-month {
    & .cur-month {
      // color: #FFFFFF !important;
    }
  }
  & .flatpickr-day {
  }
  &.bx--date-picker__day {
 
 
  }
  }
  & .flatpickr-weekdaycontainer {
  }
 }
 
 
 //END CARBON DATEPICKER DARK STYLING
 //////////////////////////////////////
 

 //////////////////////////////////////
 /// REACT-DATEPICKER STYLING -
 /// USED  ON SESSION FORM
 

////////////////////////////////////
/// CARBON DATEPICKER DARK STYLING


.bx--date-picker__day {
  color: #FFFFFF !important;
  border-radius: 3px !important;
 }
 
 
 .bx--date-picker__day:hover, .flatpickr-day:hover {
  background: rgba($accent, 0.1) !important;
 }
 
 
 .bx--date-picker__day.selected, .flatpickr-day.selected {
  background-color: $accent !important;
  border-color: $accent;
 }
 
 
 .bx--date-picker__month .numInputWrapper .numInput, .flatpickr-month .numInputWrapper .numInput {
  background-color: rgba($accent, 0.1) !important;
 }
 
 
 .bx--modal-content .bx--search-input, .bx--modal-content .bx--text-input, .bx--modal-content .bx--date-picker__input, .bx--modal-content textarea.bx--text-area {
  // padding: 0;
 }
 
 
 .flatpickr-weekday {
  color: #FFFFFF !important;
 }
 
 
 .flatpickr-current-month .cur-month {
  color: #FFFFFF !important;
 }
 
 
 .numInputWrapper .numInput.cur-year {
  color: #FFFFFF !important;
  background: #0D0D0D !important;
  }
 
 
 .flatpickr-prev-month, .flatpickr-next-month {
  & svg {
    color: #FFFFFF !important;
    fill: #FFFFFF !important;
  }
 }
 
 
    ////////////////////////////////
    // THIS HIDES THE CALENDAR ICON
    //
    // .bx--date-picker__icon {
    //   display: none !important;
    // }
    // .bx--date-picker__input.flatpickr-input {
    //   padding: 16px 16px 16px 16px !important;
    // }
    // .flatpickr-input.active {
    //   padding: 16px 16px 16px 16px !important;
    // }
    // .flatpickr-input.active:focus {
    //   padding: 16px 16px 16px 16px !important;
    // }
    ////////////////////////////////
 
 
 .css-15k3avv {
  background: #2c2b2a !important;
 
 
  .css-wqgs6e {
    background-color: #2764c6 !important;
    color: $white !important;
    font-size: 12px !important;
  }
 
 
  .css-v73v8k,
  .css-z5z6cw {
    color: $white !important;
    font-size: 12px !important;
  }
 }
 
 
 .bx--overflow-menu-options__option:hover {
  background: var(--accent-color);
  color: white !important;
 
 
  .bx--overflow-menu-options__btn {
    background: none;
    color: white !important;
    opacity: 1 !important;
  }
 }
 
 
 .bx--overflow-menu-options__btn:focus {
  // background-color: white;
  text-decoration: none;
 }
 
 
 .bx--data-table-v2 .bx--overflow-menu {
  opacity: 1;
 }
 
 
 .bx--checkbox-label {
  color: rgba(#fff, 0.8);
  font: 400 12px/1.4 $poppins;
  padding-left: 1.2rem;
 }
 
 
 .bx--modal-close:focus {
  outline-color: $accent;
 }
 
 
 .flatpickr-calendar {
  background: #0D0D0D !important;
  border: #ffffff solid 1px !important;
  border-radius: 6px !important;
 
 
  & .flatpickr-months {
    // color: #FFFFFF !important;
    & .flatpickr-month {
 
 
  }
  & .flatpickr-current-month {
    & .cur-month {
      // color: #FFFFFF !important;
    }
  }
  & .flatpickr-day {
  }
  &.bx--date-picker__day {
 
 
  }
  }
  & .flatpickr-weekdaycontainer {
  }
 }
 
 
 //END CARBON DATEPICKER DARK STYLING
 //////////////////////////////////////
 
 
 //////////////////////////////////////
 /// REACT-DATEPICKER STYLING -
 /// USED  ON SESSION FORM
 

.with-time {
  //WITH TIME BOX

  & .react-datepicker {
    //CALENDAR WIDTH
    width: 355px !important;  
  }    

  & .react-datepicker__navigation--next {
    // NEXT MONTH ARROW
    padding-right: 30px !important;

  }
  & .react-datepicker__month-container {
    //MONTH BOX
    width: 250px !important;
  }

}

.react-datepicker-black {
  
  //TOTAL CALENDAR WIDTH

  .react-datepicker {
    //DAY BACKGROUND COLOR
    background-color: $componentBackground !important;
    color: $appTextColor !important;
    
    // CALENDAR WIDTH
    &__current-month {
      color: $appTextColor !important;
    }
    &-time__header {
      color: $appTextColor !important;
    }
    ::-webkit-scrollbar {
      background: none !important;
    }
    ::-webkit-scrollbar-thumb {
      background-color: $accent !important;
    }
    //NORMAL-WIDTH
    width: 255px;
    
  }
  

  
  .react-datepicker__day {
    // CALENDAR TEXT COLOR
    color: $appTextColor !important;
    &-name {
      //DAY OF THE WEEK COLOR
      color: $appTextColor !important;
    }
    &:hover {
      //CALENDAR DATE HOVER
      background-color: rgba($white, 0.1) !important;

    }
  }

  .react-datepicker__navigation--next {
    //NORMAL-WIDTH
    padding-right: 0px;
  }


  .react-datepicker__week {
  }

  .react-datepicker__time-container {
    //TIME WIDTH
    width: 100px !important;

  }

  .react-datepicker__month-container {
  //NORMAL-WIDTH
    width: 250px;
  }

  .react-datepicker__time {
    //TIME PANE BACKGROUND
    background-color: $componentBackground !important;
  }

  .react-datepicker__time-box {
    //TIME BOX
    margin: 0px 0px !important;
    width: auto !important;
    
  }

  .react-datepicker__time-list {
    //TIME LIST 
    overflow-x: hidden !important;
    
  }

  .react-datepicker__time-list-item {
    //TIME LIST ITEM
    width: 100px !important;
    padding: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;

  }


  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  //SELECTED DAY
    background-color: $accent !important;
  }
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    //SELECTED TIME STYLE
    background-color: $accent !important;
  }

  .react-datepicker__time-list-item {
    &:hover {
      background-color: rgba($white, 0.1) !important;
    }
  }

  .react-datepicker__header {

    // background-color: $componentBackground !important;
    background-color: transparent !important;
    border-bottom: 1px solid transparent !important;
  }

  .bx--date-picker__day {
    border-radius: 3px !important;
  }

  .bx--date-picker__day:hover, .flatpickr-day:hover {
    background: rgba($accent, 0.1) !important;
  }

  .bx--date-picker__day.selected, .flatpickr-day.selected {
    border-color: $accent;
  }

  .bx--date-picker__month .numInputWrapper .numInput, .flatpickr-month .numInputWrapper .numInput {
    background-color: rgba($accent, 0.1) !important;
  }

  .bx--modal-content .bx--search-input, .bx--modal-content .bx--text-input, .bx--modal-content .bx--date-picker__input, .bx--modal-content textarea.bx--text-area {
    padding: 0;
  }

  .css-15k3avv {
    background: #2c2b2a !important;

    .css-wqgs6e {
      background-color: #2764c6 !important;
      color: $white !important;
      font-size: 12px !important;
    }

    .css-v73v8k,
    .css-z5z6cw {
      color: $white !important;
      font-size: 12px !important;
    }
  }

}

.datepicker-time {
  //NORMAL WIDTH
width: 355px !important;
}

///////////////

.bx--data-table-v2 .bx--overflow-menu {
  opacity: 1;
}

.bx--checkbox-label {
  color: rgba(#fff, 0.8);
  font: 400 12px/1.4 $poppins;
  padding-left: 1.2rem;
}

.bx--modal-close:focus {
  outline-color: $accent;
}



// Styles for Overflow Menu Item - Start
// These styles are used in Table overflow Menu items and Tiles Menu


.bx--overflow-menu-options{
  background: $componentBackground !important;
  border: 1px solid $grey !important;
  color: $white !important;
  padding: 0px !important;
  width: fit-content !important;
  right: -20px;
  left: auto;
  &::before{
    border-top: 1px solid $grey !important;
    border-left: 1px solid $grey !important;
    background: $componentBackground !important;
    right: 21px;
    left: auto !important;

  }
  .bx--overflow-menu-options__option{
    &:hover{
      background: $accent !important
    }
    background:none;
    .bx--overflow-menu-options__btn{
      padding: 10px;
      padding-right: 40px !important;
      color: $white;
      opacity: 1;
    }
    .bx--overflow-menu-options__btn:focus{
      background: none  !important;
      text-decoration: none;
      opacity: 1 !important;
    }
  }
 
}

// Styles for Overflow Menu Item - End
