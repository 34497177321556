.file {
  background: $componentBackground;
  color: $white;
  border-radius: 4px;
  padding: 15px;
  position: relative;

  .fileContent {
    position: relative;
    width: 100%;
  }

  .fileInfo {
    margin-bottom: 10px;

    h4 {
      font: 400 12px/1.4 $poppins;
      letter-spacing: letter-spacing(25, 14px);
      word-break: break-all;

      @include min-screen(1024px) {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: letter-spacing(25, 14px);
      }
    }

    h5 {
      color: #bbbbbb;
      font: 500 12px/1.4 $poppins;
      letter-spacing: letter-spacing(25, 14px);
    }
  }

  .fileType {
    margin: 0 0 15px;
  }

  .fileOptions {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;
  }

  .meta {
    display: flex;

    .metaItem {
      font: 500 11px/1.4 $poppins;
      letter-spacing: letter-spacing(25, 11px);
      width: 50%;

      .metaItemKey {
        color: #bbbbbb;
      }
    }
  }

}

.fileType {
  background: $white;
  border-radius: 4px;
  color: $componentBackground;
  display: inline-block;
  font: 700 18px/30px $poppins;
  height: 30px;
  letter-spacing: letter-spacing(25, 18px);
  padding: 0 8px;
  text-align: center;
  text-transform: uppercase;
  min-width: 80px;

  &--compact {
    height: 20px;
    font-size: 14px;
    letter-spacing: letter-spacing(25, 14px);
    line-height: 20px;
    min-width: 0;
    width: auto;
  }

  &--pdf {
    background: #fa1e48;
  }

  &--rin {
    background: $accent;
  }

  &--psd {
    background: #1287ff;
  }

  &--mp3 {
    background: $accent;
  }

  &--aif {
    background: #7be257;
  }

  &--txt {
    background: #faead1;
  }

  &--avp {
    background: #a9d4ff;
  }

  &--doc {
    background: #1233ff;
  }

  &--wav {
    background: #5fb6b3;
  }
}

.fileType-container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  & * {
    font-weight: 500;
  }
}

.fileType-icon {
  width: 20px;
  height: 16px;
}

.fileType-chip {
    background-color: white;
    color: black;
    font-size: 10px;
    border-radius: 3px;
    height: max-content;
    padding: 0px 6px;
}

  .sections-container{
    .section{
      .rename-file, .rename-folder{

      .bx--modal-container{
        padding: 26px 30px 0px 30px;
        .form{
          label.bx--label{
              margin-bottom: 5px !important;
          }
          .bx--text-input.bx--text__input{
            padding: 10px;
          }
          .formRow{
            margin-top: 0px !important;
          }
        }
      }
      .bx--modal-header{
        margin-bottom: 3.7rem;
        .bx--modal-close{
          top: 10px !important;
          right: 22px !important;
          height: 20px;
          width: 21px;
          display: inline !important;
          &::after{
            content: '' !important;
          }
        }
        .bx--modal-close__icon{
          display: inline !important;
          fill: $white;
          width: -webkit-fill-available !important;
          height: fit-content !important;
        }
        .bx--modal-header__heading{
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
        }
      }
      .bx--modal-content{
        padding: 0px 16px;
        margin-bottom: 29px;
      }
      .bx--modal-footer{
        margin-left: 0rem;
        margin-right: 0rem;
        padding: 2.1rem 0rem 1.9rem 0rem;
        button{
          padding: 10px;
          display: flex;
          align-items: center;
        }
      }
    }
   }

}