
@keyframes pulse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
.loading-dots span {
  animation: pulse 1s infinite;
  opacity: 0.3;
  color: white;

  &:nth-child(1) {
    animation-delay: .2s;
  }
  &:nth-child(2) {
    animation-delay: .4s;
  }
  &:nth-child(3) {
    animation-delay: .6s;
  }
}
