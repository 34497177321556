.files-share {
    & .hero .breadcrumb {
        margin-top: 0px !important;
        padding-top: 30px;
    }

    .hero {
        .separator {
            display: none !important;
        }
    }

    & .heroContent {
        margin-bottom: 0px;
        height: 120px;
    }

    & .sections-container .section {
        margin-bottom: 22px;
        & .bx--data-table-v2-container {
            & .actions-header {
                padding-left: 22px;
                height: 54px;
                .custom-pagination {
                    padding-left: 0px;
                }
            }
            & .bx--data-table-v2--zebra {
                @media (max-width: 1550px){
                //   width: 96% !important;
                }
              }
          }
    }
    & .my-files {

        // display: none
    }
    & .project-files {
        .copy-link {
            display: none;
        }
    }
    & .song-files {
        // display: none
    }
    & .deleted-files {
        .section .bx--data-table-v2-container tbody tr td {
            &:nth-child(3){
                .row-value {
                    color: $accent;
                    cursor: pointer;
                }
                a {
                  color: $accent;
                }
            }
        }
        .copy-link {
            display: none;
        }    
    }
    & .create-hero {
        margin-bottom: 19px;
    }
    & .create-hero.search-header-flex {
        & .content-wrapper .sections-container .parent-wrapper .actions-wrapper {
            display: flex !important;
            cursor: default;
            & .sectionHeaderText {
                display: flex;
                align-items: center;
                padding-right: 16px;
            }
        }
    }

    .table-cell-icon {
        padding-right: 12px;
    }
    p.row-value-name{
        text-overflow: ellipsis;
        max-width: none !important;
    }
    & .song-files {
        tr :nth-child(3){
            & p.row-value {
                color: $accent;
            }
            & a {
                text-decoration: none;
            }
        }
        .copy-link {
            display: none;
        }
    }
    .vertical-line {
        color: $accent;
        margin-left: 22px;
        margin-right: 22px;
    }
    .hero-edit-button {
        padding-left: 20px;
    }
}