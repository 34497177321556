.uppy-Dashboard-inner {
  // min-height: 200px;
}

.uppy-Dashboard-inner {
  background: black !important;
  border: none;
  color: white;
}

.uppy-Dashboard-dropFilesTitle {
  color: white !important;
}

.uppy-Dashboard-browse {
  color: var(--accent-color) !important;

  &:hover {
    border-bottom-color: var(--accent-color);
  }
}

.uppy-size--md .uppy-DashboardAddFiles {
  border: none;
  margin: 0;
}

