///////responsive classes for specific table//////
.infoGrid {
  padding-left: 18px;
}

// Project Recording Details
.project-recording-details-file {
  td:nth-child(1),
  td:nth-child(2) {
    width: 4%;
    text-align: center;
  }
  td:nth-child(3) {
    width: 26%;
  }
  td:nth-child(4),
  td:nth-child(5),
  td:nth-child(6) {
    width: 16.5%;
  }

  td:nth-child(8),
  td:nth-child(9) {
    width: 3%;
  }
  @media (max-width: 1024px) {
    td:nth-child(1),
    td:nth-child(2) {
      width: 6%;
    }
  }
}

.project-recording-session-table {
  td:nth-child(1) {
    width: 4%;
  }
  td:nth-child(2) {
    width: 30%;
  }
  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5) {
    width: 16.5%;
  }
  @media (max-width: 1024px) {
    td:nth-child(2) {
      width: 32%;
    }
  }
}

.project-recording-credit-table {
  td:nth-child(1) {
    width: 4%;
  }
  td:nth-child(2) {
    width: 30%;
  }
  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5) {
    width: 16.5%;
    max-width: 237px;
  }

  @media (max-width: 1440px) {
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5) {
      max-width: 224px;
    }
  }
  @media (max-width: 1024px) {
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5) {
      max-width: 146px !important;
    }
  }
}

// Project Session Details

.session-credit-table,
.session-recording-table {
  td:nth-child(1) {
    width: 4%;
  }
  td:nth-child(2) {
    width: 30%;
  }
  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5) {
    width: 16.5%;
    max-width: 283px;
  }
  td:nth-child(6) {
    .bx--overflow-menu {
      margin-left: auto;
    }
  }
}
