.pageHeader {
  border-bottom: 1px solid $white;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 20px;

  &-titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .breadcrumb {
    margin-bottom: 10px;
  }
}