
.bx--tooltip__trigger:hover,
.bx--tooltip__trigger:focus {
  color: white
}
.bx--tooltip--definition__bottom,
.bx--tooltip--definition__top {
  font-weight: normal;
  font-size: .85rem;
  width: 20rem;
  padding: 1em;
  line-height: 1.3;
  color: #ccc;
  font-style: italic;
  z-index: 2;
}

.bx--tooltip__trigger path {
  fill: $accent;
}
.bx--tooltip__trigger path:hover{
  fill: $accent;
} 

.bx--tooltip__caret-icon {
  left: 1.72rem !important;
}

.bx--tooltip--icon {
  margin-left: -3rem;
  max-width: 22rem;
  
}

.tooltip--icon {
  border-bottom: none !important;
}

.info-icon {
  width: 14px;
  height: 14px;
}
.credit-tooltip{
  .bx--tooltip__label .bx--tooltip__trigger{
    margin-left: 2px;
    margin-bottom: -5px;
  }
  .bx--tooltip .bx--tooltip--shown{
    width:300px;
  }
}

.tooltip-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:5px
}