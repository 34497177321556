.account-img-placeholder {
  height: 150px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 38px;
  }
}

.bx--form {
  max-width: 800px;
  margin-top: 55px;
}

.formRow {
  margin-bottom: 35px;

  margin-top: 35px;

  &First {
    margin-top: 55px;
  }

  &Last {
    margin-top: 26px;
  }

  &Submit {
    .bx--btn {
      min-width: 120px;
    }
  }

  &--fileUpload {
    display: flex;
    align-items: center;
  }

  &--picContainer {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  &--actionContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
  }

  &--col {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
}


.formCol {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.formColChild {
  flex: 1
}


// Field Labels
.bx--label {
  min-height: 18px;
  font-family: $poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #B3B3B3;
  display: flex;
  align-items: center;

  .bx--tooltip__trigger {
    border: none;
    min-height: 18px;
    font-family: $poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #B3B3B3;
    display: flex;
    align-items: center;

    &:hover {
      border: none;
    }
  }
}

label.bx--label {
  margin-bottom: 6px;
}

.bx--label-description {
  color: $white;
}

// Field Inputs
// input[type="text"],
// input[type="password"],
// input[type="email"],
.bx--search-input,
.bx--text-input,
.bx--date-picker__input,
textarea.bx--text-area {
  background: transparent;
  box-shadow: 0 1px 0 0 $componentBackground;
  color: $white;
  transition: box-shadow 200ms ease-in-out;
  // border:1px solid #343434;
  background-color: $componentBackground;
  // border: none;
  font-family: $poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  &:focus {
    box-shadow: 0 2px 0 0 $accent;
  }
}

.bx--modal-content {

  .bx--search-input,
  .bx--text-input,
  .bx--date-picker__input,
  textarea.bx--text-area {

    &:focus {
      box-shadow: 0 2px 0 0 $accent;
    }

    &[data-invalid] {
      box-shadow: 0 2px 0 0 #CD4136;
    }
  }

  .formRow .bx--form-item .bx--date-picker__input {
    padding-left: 3rem;
  }
}

// Number input
.formNumberInput {
  width: 100%;

  input[type="number"] {
    background: $componentBackground;
    box-shadow: 0 1px 0 0 $componentBackground;
    color: white;
    transition: box-shadow 200ms ease-in-out;

    &:focus {
      box-shadow: 0 2px 0 0 $accent;
    }
  }

  .bx--number__controls {
    top: 2.5rem;

    svg {
      fill: $accent;
    }
  }
}

// Textareas
textarea.bx--text-area {
  height: 105px;
  // line-height: 1.5 !important;
  resize: none;

  &::-webkit-scrollbar {
    width: 0;
  }

  &:focus,
  &:hover {
    &::-webkit-scrollbar {
      width: 5px;
      color: $accent;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: $accent;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
  }
}

// Checkboxes
.formCheckbox {
  outline-color: $accent;

  &:before {
    border: 0 none;
    background: black;
  }

  span {
    color: $white;
  }
}

.bx--checkbox {
  &:checked+.formCheckbox {
    &:before {
      background: $accent;
    }

    &:after {
      border-color: $componentBackground;
    }
  }

  &:focus+.bx--checkbox-label::before {
    box-shadow: 0 0 0 3px rgba($accent, 0.5);
  }
}

.bx--radio-button-group {
  flex-wrap: wrap;
}

.radioButtonWrapper {
  @include max-screen(767px) {
    margin-bottom: 15px;
    width: 100%;
  }
}

// Radio Buttons
.formRadioButton {

  label,
  label span {
    color: $white;
  }
}

.bx--radio-button__appearance {
  border-color: $accent;
  border: 2px solid $accent;
  width: 30px;
  height: 30px;
  outline: none;
}

.bx--radio-button__label {
  color: $white;
}

.bx--radio-button:checked {
  +.bx--radio-button__label .bx--radio-button__appearance {
    border-color: $accent;

    &:before {
      background-color: $accent;
      width: 18px;
      height: 18px;
    }
  }
}

.bx--radio-button:focus {
  +.bx--radio-button__label .bx--radio-button__appearance {
    box-shadow: 0 0 0 3px rgba($accent, 0.5);
    outline: none;
  }
}


// Selects
.bx--select {
  width: 100%;

  select {
    background: $componentBackground;
    box-shadow: 0 1px 0 0 $componentBackground;
    color: white;

    &:focus {
      box-shadow: 0 2px 0 0 $accent;
    }

    option {
      background: white;
    }
  }
}

.bx--multi-select {
  line-height: 1rem;
  background: $componentBackground;
  box-shadow: 0 1px 0 0 $componentBackground;

  .bx--list-box__field:focus,
  .bx--list-box__field[aria-expanded='true'] {
    box-shadow: 0 2px 0 0 $accent;
  }

  .bx--list-box__selection--multi {
    background-color: $accent;
    color: $appBackground;
  }

  .bx--list-box__selection--multi>svg {
    fill: $grey-dark;
  }

  .bx--list-box__menu-item:hover,
  .bx--list-box__menu-item--highlighted {
    background-color: rgba(255, 150, 2, 0.2) !important;
  }

  .bx--list-box__label {
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  .bx--list-box__menu-icon svg {
    fill: $accent;
  }

  &:focus {
    box-shadow: 0 2px 0 0 $accent;
  }
}

.bx--select__arrow {
  fill: $accent;
}

.bx--react-select {
  .css-15k3avv {
    z-index: 2;
  }

  .css-1wy0on6 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 0.6rem;
  }

  .bx--select__arrow {
    bottom: 0rem;
    top: 1rem;
  }

}

.bx--react-select--invalid {
  >div {
    box-shadow: 0 2px 0 0 #CD4136;
  }
}

.bx--select-input {
  line-height: 1rem;
}

.bx--text__input--invalid {
  box-shadow: 0 2px 0 0 #CD4136;
}

input[data-invalid]~.bx--form-requirement,
textarea[data-invalid]~.bx--form-requirement,
select[data-invalid]~.bx--form-requirement,
.bx--list-box[data-invalid]~.bx--form-requirement,
.bx--react-select--invalid~.bx--form-requirement,
.react-datepicker-wrapper~.bx--form-requirement {
  max-height: 12.5rem;
  display: block;

  min-height: 15px;
  font-family: $poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150.69%;
  color: #CD4136;
  margin-left: 10px;

  &::before {
    content: '';
  }
}

.bx--form-requirement.visible {
  &:before {
    content: '';
  }
}

.react-datepicker-wrapper~.bx--form-requirement,
.bx--react-select--invalid~.bx--form-requirement {
  order: 3;
  color: #CD4136;
  font-weight: 400;
  margin-top: .25rem;
}

.bx--date-picker.bx--date-picker--single {
  width: 100%;

  .bx--date-picker-container {
    width: 100%;
  }

  .bx--date-picker__input {
    max-width: none;
    // width: 100%;
    width: 200px;

    &::placeholder {
      font-family: $poppins;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #676767;
    }
  }

  .bx--date-picker__icon {
    fill: $accent;
    // top: 2.6rem;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.alternateOption {
  color: rgba(#fff, 0.8);
  font: 400 12px/1.4 $poppins;
  margin-top: 20px;
  text-align: right;

  a {
    color: $accent;
    font-weight: bold;
    transition: color 300ms ease-in-out;
    text-decoration: underline;

    &:hover {
      color: white;
    }
  }
}

.forgotPasswordLink {
  min-height: 21px;
  font-family: $poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: underline;
  color: $accent;
  display: inline-block;
  margin-bottom: 35px;
}

.forgot-password {
  .bx--modal-header {
    display: none !important;
  }
}

.StripeElement {
  background-color: $componentBackground;
  border-bottom: 2px solid transparent;
  padding: 12px;
  transition: border-bottom-color 200ms ease-in-out, background-color 200ms ease-in-out;

  &--focus {
    border-bottom-color: $accent;
  }

  &--complete {}

  >.InputElement {
    color: white !important;
  }
}

.input-wrapper {
  position: relative;

  .suggestions-list {
    position: absolute;
    background-color: $componentBackground;
    top: 75px;
    left: 0px;
    z-index: 999;
    cursor: pointer;
    width: 100%;
    max-height: 200px;
    overflow: auto;
    margin-top: 3px;
    border-radius: 4px;
  }

  .suggestions-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: $componentBackground;
  }

  .suggestions-list::-webkit-scrollbar {
    width: 5px;
    background-color: $componentBackground;
  }

  .suggestions-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #555;
  }

  .suggestions-list {
    .suggestion-items {
      padding: 8px 15px;
      font: 500 13px/1.2 "Poppins", "Helvetica Neue", "Helvetica", Arial, sans-serif;
      list-style-type: none;
      border-bottom: 1px solid #2d2e36;
      transition: all 0.1s ease;
    }
  }

  .suggestions-list {
    .suggestion-items:hover {
      background-color: #0275d8;
      color: $white
    }
  }

  .no-suggestions {
    background-color: $componentBackground;
    color: #6a6868;
    text-align: center;
    font: 500 13px/1.2 "Poppins", "Helvetica Neue", "Helvetica", Arial, sans-serif;
    padding: 10px;
    margin-top: 3px;
    border-radius: 4px;
  }
}

.bx--list-box__menu {
  background-color: $componentBackground;

  .bx--list-box__menu-item:hover,
  .bx--list-box__menu-item--highlighted,
  .bx--checkbox-label {
    color: white !important;
  }

}

.party-form {
  .bx--radio-button__appearance:focus {
    outline: none !important;
  }

  .bx--radio-button__appearance {
    background-color: $componentBackground !important;
    border-color: $accent;
    height: 30px;
    width: 30px;


  }

  .bx--radio-button:checked+.bx--radio-button__label .bx--radio-button__appearance {

    border-color: $accent;

  }

  .bx--radio-button:checked+.bx--radio-button__label .bx--radio-button__appearance:before {
    background-color: $accent;
    width: 18px;
    height: 18px;
  }

  .bx--radio-button:focus {
    +.bx--radio-button__label .bx--radio-button__appearance {
      box-shadow: none
    }
  }

  .bx--radio-button__label {

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
  }

  .radio-person {
    margin-right: 95px;
  }

}

.link-expiration-text {
  color: $accent !important;
  font: 400 13px/1.4 $poppins;
  margin-bottom: 10px;
}

.input-icon-container {
  display: flex;
  align-items: center;
  gap: 8px;

  &--text {
    margin: 0px !important;
  }
}

.icon-img {
  height: 16px;
  min-width: 16px;
  margin-left: 10px;
  cursor: pointer;
}

.info-icon-img {
  @extend .icon-img;
  height: 19px;
  margin-left: 8px;
  margin-bottom: 3px;
}

.party-form-custom {
  .bx--form {
    max-width: 100%;

  }

  .fields-container {
    max-width: 800px;
  }

  textarea.bx--text-area {
    height: 105px;
    resize: vertical;
    max-height: 400px;
  }

}


.split-input {
  border: 0px;
  background-color: $componentBackground;
}

.btns-container {
  margin-top: 80px;
}

.img-upload-section {
  display: flex;

  .song-img-placeholder {
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 38px;
      font-family: $poppins;
    }
  }

  .song-img {
    max-height: 150px;
    max-width: 150px;
  }

  .actions-container {
    margin-left: 16px;
    display: flex;
    flex-direction: column;

    .action-container {
      height: 20px;
      position: relative;

      .action-btn-loading {
        position: absolute;
        margin: 0;
        padding: 0;
        left: -8px;
        top: -5px;
      }

      .dpi-action {
        height: 20px;
        cursor: pointer;
      }

      &:nth-of-type(1) {
        margin-bottom: 18px;
      }
    }
  }

}

.actionBtnContainer {
  margin-top: 85px !important;
}

.createRecordingCredit {
  .create-hero {
    margin-bottom: 56px;
  }

  .formRow {
    margin-bottom: 35px;

    .bx--select {
      .bx--label img {
        margin-left: 9px;
        width: 14.25px;
        height: 14.25px;
        margin-bottom: 3px;
      }
    }
  }

  .btns-container {
    margin-top: 80px;
  }
}

////////////
.accountEdit {

  .formRow {
    margin-bottom: 35px;
  }

  .section {
    margin-bottom: 35px;
  }

  .actionBtnContainer {
    display: flex;
    margin-top: 80px !important;
  }


  .accountImg {

    .accountCover {
      display: flex;
      height: 100%;

      width: 100%;
      justify-content: center;
      align-items: center;

      span {
        color: $white;
        display: block;
        font: 700 30px/1.4 $poppins;
        left: 50%;
        max-width: 100%;
        padding: 0 10px;

        @include min-screen(1200px) {
          font-size: (30px/1200px) * 100vw;
        }

        @include min-screen(1400px) {
          font-size: 38px;
        }
      }

    }

    .img-upload-section {
      margin-top: 9px;
    }

  }

  .accountInfoForm {
    .bx--form {
      margin-top: 35px;
    }
  }

}


.recordingForm {
  max-width: 795px;

  .formRow {
    margin-bottom: 35px;
  }

  .datePickerContainer {
    display: flex;
    gap: 36px;

  }

  .actionBtnContainer {
    margin-top: 80px !important;
  }

}


.recordingForm {
  max-width: 795px;

  .formRow {
    margin-bottom: 35px;
  }

  .datePickerContainer {
    display: flex;
    gap: 36px;

  }

  .actionBtnContainer {
    margin-top: 80px !important;
  }

}

/////////////////////////////////////
/// SESSION FORM PAGE ADDS /////////
/// /////////////////////////////////

///#1
.new-session-form {

  .disabledField {
    pointer-events: none;
    .css-1wy0on6 {
      display: none;
    }
  }

  .bx--radio-button-group {
    justify-self: flex-end;
  }

  .bx--radio-button__appearance:focus {
    outline: none !important;
  }

  .bx--radio-button__appearance {
    background-color: $componentBackground !important;
    height: 24px;
    width: 24px;
    border: 1px solid $accent !important;
  }

  .bx--radio-button:checked+.bx--radio-button__label .bx--radio-button__appearance {

    border-color: $accent;

  }

  .bx--radio-button:checked+.bx--radio-button__label .bx--radio-button__appearance:before {
    background-color: $accent;
    width: 18px;
    height: 18px;
  }

  .bx--radio-button:focus {
    +.bx--radio-button__label .bx--radio-button__appearance {
      box-shadow: none
    }
  }

  .bx--radio-button__label {

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    width: 100% !important;
  }

  .radio-person {
    margin-right: 95px;
  }

  .radio-session {
    background: none !important;
  }

  .radio-button-inline {
    display: flex;
    padding-left: 70px;
    padding-bottom: 8px;
    align-items: flex-end;
  }

  //moves the calendar down when field required alert
  .react-datepicker-popper:has(+ .bx--form-requirement) {
    transform: translateY(89px) !important;
  }

  .calendar-placeholder-icon-started {
    position: absolute;
    transform: translateY(32px) translateX(13px);
    z-index: 1;
    // top: 20px;
    // left: 20px;
  }

  .calendar-placeholder-icon-ended {
    position: absolute;
    transform: translateY(32px) translateX(245px);
    z-index: 1;
    // top: 20px;
    // left: 20px;
  }


  .btns-container {
    margin-top: 80px !important;
  }

}

////////////
/////MULTI SELECT PILL///////
////////////
.bx--react-select {
  .css-15k3avv {
    z-index: 2;
  }

  .css-xwjg1b,
  .css-es53b3-multiValue {
    background: $accent !important;
    color: $appBackground !important;
    border-radius: 30px !important;
    opacity: 1 !important;
    padding: 0px 4px !important;
  }

  .css-12jo7m5 {
    color: $appBackground !important;
    padding-right: 0px !important;
  }

  .css-1alnv5e {
    color: #1F1F1F !important;
    display: flex !important;
    justify-content: flex-start !important;
    padding: 1.5px 0px 0px 0px !important;
    margin: 0px !important;
    opacity: .8 !important;
    transition: opacity .3s;

    &:hover {
      opacity: 1 !important;
      background: none !important;
    }

    & svg {
      padding: 0px !important;
      margin: 0px !important;
      // transform: scale(1.1) !important;
      // justify-self: flex-start !important;
    }
  }

  .css-15k3avv {
    z-index: 2;
  }

  .css-1wy0on6 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 0.6rem;
  }

  .bx--select__arrow {
    bottom: 0rem;
    top: 1rem;
  }

}


/// END ///
.formRow {
  margin-bottom: 26px;

  margin-top: 35px;

  &First {
    margin-top: 55px;
  }

  &Last {
    margin-top: 26px;
  }

  &Submit {
    .bx--btn {
      min-width: 120px;
    }
  }

  &--Four-Column {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 34px;

    justify-content: space-between;
    margin-bottom: 26px;

    &>* {
      padding-right: 0px !important;
    }
  }

  &--Two-Column {
    display: grid;
    grid-template-columns: 60% 40%;
    margin-top: 35px;
    margin-bottom: 26px;
  }

  &--fileUpload {
    display: flex;
    align-items: center;
  }

  &--picContainer {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  &--actionContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
  }

  &--col {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }

}


.formRow--Four-Column .react-datepicker__input-container {

  width: 200px !important;

  & .bx--text-input {
    width: 200px !important;
    min-width: 200px !important;

    &::placeholder {
      color: #676767;
    }
  }


}

.formRow--Four-Column .react-datepicker-wrapper {

  min-width: 100px !important;

}

.formRow--Four-Column .bx--form-item {
  //
}

.formRow--Four-Column>* {
  //
}


.self-flex-center {
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
}

.self-flex-center .bx--form-item {
  padding-top: 15px !important;

}

.self-flex-center .bx--radio-button-group {
  justify-items: flex-end;
}

.formCol {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.formColChild {
  flex: 1
}

.formRow--Four-Column .react-datepicker__input-container {
  width: 200px !important;

  & .bx--text-input {
    width: 200px !important;
    min-width: 200px !important;

    &::placeholder {
      color: #676767;
      padding-left: 25px;
    }
  }

}

.formRow--Four-Column .react-datepicker-wrapper {
  min-width: 100px !important;
}



.self-flex-center {
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
}

.self-flex-center .bx--form-item {
  padding-top: 15px !important;

}

.self-flex-center .bx--radio-button-group {
  justify-items: flex-end;
}

.react-datepicker__input-container {
  input {
    caret-color: transparent;
  }
}

/////////////////////////////////
/////////////////////////////////
/////////////////////////////////
/////////////////////////////////


.editRecording {
  .create-hero {
    margin-bottom: 55px;
  }
}

.formRow.button-on-row {
  display: flex;
  gap: 23px;

  & button {
    margin-top: 26px !important;
  }
}

.party-form-custom {
  & .affiliations-Form {
    margin-top: 21px;
  }

  & .affiliations-container {
    margin-top: 21px;
    margin-bottom: 40px;

    & .formRow {
      margin-top: 21px;
    }

    .affiliations-buttons-display {
      align-items: center;
      margin: 42px 0 46px 0;
    }
  }

  & .bx--form-item {
    & .radioButtonWrapper {
      margin-right: 64px;
    }

    & .bx--radio-button__label {
      margin-right: 0px;
    }
  }
}



.party-form-custom .sections-container .section {
  margin-bottom: 35px;

  & .bx--data-table-v2 tbody tr td .row-value-name {
    color: #FFFFFF;
  }
}

.party-form-custom .sections-container {
  .formRow.buttons-row {
    padding-top: 35px;
    padding-bottom: 10vh;
  }
}

.sections-container.last-section {
  & .affiliations-container {
    margin-bottom: 1px !important;
  }
}

.delete-button-svg {
  background: transparent;
  border: none;
  // margin-top: 6px;
}


.collaboratorForm {
  margin-top: 55px;
  max-width: 795px;

  .formRow {
    margin-bottom: 35px;
  }

  .bx--text-input {
    color: $white
  }

  .slider-title {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    color: $white
  }

  .slider-description {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 30px;
    max-width: 100%;
    width: 720px;
  }


  .sliderContainer {
    .formRow {
      margin-bottom: 55px;
    }
  }


  .action-container {
    margin-top: 80px;
  }

  .recordings {
    display: flex;
    gap: 11px;
    align-items: flex-start;
  }

  .recordings-icons-container {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  .recording-icon {
    width: 15px;
    height: 15px;
  }

  .inviteAccepted {
    max-width: 193px;

    .bx--text-input {
      width: 193px;
      min-width: 100px;
    }
  }

  .sliderContainer {
    max-width: 663px;
  }

  .bx--react-select {
    .css-15k3avv {
      z-index: 2;
    }

    .css-xwjg1b {
      background: $accent !important;
      color: $appBackground !important;
      border-radius: 30px !important;
      opacity: 1 !important;
      padding: 0px 4px !important;
    }

    //TODO
    .css-12jo7m5 {
      // border: green solid 1px !important;
      color: $appBackground !important;
      padding-right: 0px !important;
    }

    .css-1alnv5e {
      color: #1F1F1F !important;
      // width: 50px !important;
      display: flex !important;
      justify-content: flex-start !important;
      padding: 1.5px 0px 0px 0px !important;
      margin: 0px !important;
      opacity: .8 !important;
      transition: opacity .3s;

      &:hover {
        // background-color: $accent !important;
        opacity: 1 !important;
        background: none !important;
      }

      & svg {
        padding: 0px !important;
        margin: 0px !important;
        // transform: scale(1.1) !important;
        // justify-self: flex-start !important;
      }
    }
  }

  .nameField,
  .emailField {
    max-width: 409px;
  }


}


.collaboratorForm {
  margin-top: 55px;
  max-width: 795px;

  .formRow {
    margin-bottom: 35px;
  }

  .bx--text-input {
    color: $white
  }

  .slider-title {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    color: $white
  }

  .slider-description {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 30px;
    max-width: 100%;
    width: 720px;
  }


  .sliderContainer {
    .formRow {
      margin-bottom: 55px;
    }
  }


  .action-container {
    margin-top: 80px;
  }

  .recordings {
    display: flex;
    gap: 11px;
    align-items: flex-start;
  }

  .recordings-icons-container {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  .recording-icon {
    width: 15px;
    height: 15px;
  }

  .inviteAccepted {
    max-width: 193px;

    .bx--text-input {
      width: 193px;
      min-width: 100px;
    }
  }

  .sliderContainer {
    max-width: 663px;
  }

  .bx--react-select {
    .css-15k3avv {
      z-index: 2;
    }

    .css-xwjg1b {
      background: $accent !important;
      color: $appBackground !important;
      border-radius: 30px !important;
      opacity: 1 !important;
      padding: 0px 4px !important;
    }

    //TODO
    .css-12jo7m5 {
      // border: green solid 1px !important;
      color: $appBackground !important;
      padding-right: 0px !important;
    }

    .css-1alnv5e {
      color: #1F1F1F !important;
      // width: 50px !important;
      display: flex !important;
      justify-content: flex-start !important;
      padding: 1.5px 0px 0px 0px !important;
      margin: 0px !important;
      opacity: .8 !important;
      transition: opacity .3s;

      &:hover {
        // background-color: $accent !important;
        opacity: 1 !important;
        background: none !important;
      }

      & svg {
        padding: 0px !important;
        margin: 0px !important;
        // transform: scale(1.1) !important;
        // justify-self: flex-start !important;
      }
    }
  }

  .nameField,
  .emailField {
    max-width: 409px;
  }


}


// // editRecordingCredit - START


.editRecordingEdit {

  .btns-container {
    margin-top: 80px;
  }

}


// editRecordingCredit - END

//global styling of btns container//
.btns-container {
  margin-top: 80px;
}


.formRow-last {
  margin-bottom: 31px !important;
}