.appDeviceNotice {
  background: rgba(26, 23, 25, 0.7);
  border: 1px solid #4e4e4e;
  border-radius: 6px;
  display: flex;
  left: 15px;
  margin: 0 auto;
  padding: 12px 10px;
  position: fixed;
  top: 75px;
  width: 345px;
  z-index: 1000;

  @include min-screen(1024px) {
    display: none;
  }

  &.appDeviceNoticeDismissed {
    display: none;
  }

  figure {
    margin: 0;
    width: 70px;
  }

  .appDeviceNoticeContent {
    color: $white;
    font: 500 12px/1.2 $poppins;
    padding-right: 25px;
    width: calc(100% - 70px);
  }

  .appDeviceNoticeDismiss {
    background: none;
    border: none;
    cursor: pointer;
    height: 100%;
    left: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    svg {
      height: 10px;
      position: absolute;
      right: 10px;
      top: 10px;
      width: 10px;
    }
  }
}
