.project-recording-table {
  td:nth-child(1) {
    width: 4%;
  }
  td:nth-child(2) {
    width: 30%;
    min-width: 432px;
  }
  td:nth-child(3),
  td:nth-child(4) {
    width: 16.5%;
    min-width: 238px;
    max-width: 238px;
  }
  td:nth-child(5) {
    width: 33%;
  }
  @media (max-width: 1440px) {
    td:nth-child(2) {
      width: 29%;
      min-width: 358px;
    }
    td:nth-child(3),
    td:nth-child(4) {
      min-width: 200px;
      max-width: 200px;
    }
  }
  @media (max-width: 1024px) {
    td:nth-child(2) {
      width: 24%;
      min-width: 214px;
    }
    td:nth-child(3),
    td:nth-child(4) {
      min-width: 130px;
      max-width: 130px;
    }
  }
}

.session-table {
  td:nth-child(1) {
    width: 4%;
  }
  td:nth-child(2) {
    width: 30%;
  }
  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5) {
    width: 16.5%;
  }
}

.project-file-table {
  td:nth-child(1),
  td:nth-child(2) {
    width: 4%;
    min-width: 57px;
    text-align: center;
  }
  td:nth-child(3) {
    width: 26%;
  }
  td:nth-child(4),
  td:nth-child(5),
  td:nth-child(6) {
    width: 16.5%;
    min-width: 238px;
    max-width: 238px;
  }

  td:nth-child(8),
  td:nth-child(9) {
    width: 3%;
  }

  @media (max-width: 1440px) {
    td:nth-child(1),
    td:nth-child(2) {
      width: 5%;
    }
    td:nth-child(3) {
      width: unset;
      max-width: 235px;
    }
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6) {
      min-width: 200px;
      max-width: 200px;
    }
  }
  @media (max-width: 1024px) {
    td:nth-child(1),
    td:nth-child(2) {
      min-width: 57px;
    }
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6) {
      min-width: 126px;
      max-width: 126px;
    }
  }
}

.credit-table {
  td:nth-child(1) {
    width: 4%;
  }
  td:nth-child(2) {
    width: 30%;
  }
  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5) {
    width: 16.5%;
    max-width: 238px;
  }
  @media (max-width: 1024px) {
    td:nth-child(2) {
      width: 26.5%;
    }
  }

  @media (max-width: 1440px) {
    td:nth-child(2) {
      width: 29.5%;
    }
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5) {
      max-width: 200px;
    }
  }
}

.collaborator-table {
  td:nth-child(1) {
    width: 35%;
  }
  td:nth-child(2),
  td:nth-child(4) {
    width: 18%;
  }
  td:nth-child(3) {
    width: 33%;
  }

  @media (max-width: 1024px) {
    td:nth-child(1) {
      width: 31.5%;
    }
    .bx--overflow-menu {
      margin-left: auto;
    }
  }
}

.files-project-table {
  td:nth-child(1),
  td:nth-child(2) {
    width: 4%;
    min-width: 57px;
    text-align: center;
  }
  td:nth-child(3) {
    width: 26%;
  }
  td:nth-child(4),
  td:nth-child(5),
  td:nth-child(6),
  td:nth-child(7) {
    width: 15%;
  }
  td:nth-child(8),
  td:nth-child(9) {
    width: 3%;
  }
}

// Playlist Details Page Table
.playlist-details-table {
  td:nth-child(1) {
    display: none;
  }
  td:nth-child(2) {
    width: 4%;
    text-align: center;
  }
  td:nth-child(3) {
    width: 30%;
  }
  td:nth-child(4),
  td:nth-child(5),
  td:nth-child(6) {
    width: 16.5%;
  }
}
