.publicPlaylistHero {
    margin-bottom: 25.5px;

    .playlistHeroContent {
        position: relative;

        @include min-screen(768px) {
            display: flex;
            justify-content: center;
            justify-content: flex-start;
            gap:20px
        }

        @include max-screen(425px) {
            position: relative;
            align-items: center;
            display: flex;
            flex-direction: column;
        }

        .playlistHeroThumbnail {
            height: 149px;
            margin: 0 auto;
            width: 149px;
        }

        img {
            height: 100%;
            width: 100%;
        }

        .playlistHeroBody {
            margin-left: 0px;
            margin-top: 50px;
            box-sizing: border-box;
            .bx--tooltip__label {
                color: #fff !important;
            }

            @include min-screen(768px) {
                margin-left: 0px;
                margin-top: 0px;
                width: calc(100% - 206px);
            }

            header {
                text-align: center;

                @include min-screen(768px) {
                    text-align: left;
                }

                h3 {
                    color: $white;
                    font-size: 16px;
                    letter-spacing: 0.45px;
                    line-height: 30px;

                    @include min-screen(768px) {
                        font-size: 14px;
                        letter-spacing: letter-spacing(25, 18px);
                        line-height: 21px;
                        font-weight: 400;
                    }

                    span {
                        color: $accent;
                        margin-left: 10px;
                    }
                }

                h1 {
                    color: $white;
                    letter-spacing: letter-spacing(25, 30px);
                    max-width: 990px !important;
                    overflow-wrap: break-word;
                    white-space:normal !important;
                    text-overflow: initial !important;
                    overflow: hidden;
                    margin-top: 9px;
                    font-weight: 600;
                    font-size: 38px;
                    line-height: 57px !important;

                    @include min-screen(768px) {
                        font-size: 25px;
                        max-width: 590px;
                        margin: 0 auto 5px;
                        white-space: normal;
                        letter-spacing: letter-spacing(25, 50px);
                        margin: 0 0 5px;
                    }

                    @include min-screen(1024px) {
                        font-size: 36px;
                        letter-spacing: letter-spacing(25, 60px);
                    }
                }
            }

            .playlistHeroLinks {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin-bottom: 1px;
                gap: 20px;
                @include min-screen(768px) {
                    justify-content: left;
                }

                .playlistHeroItem {
                    background-color: $btnbackground;
                    height: 40px;
                    color: $white;
                    display: none;
                    font: 500 14px $poppins;
                    margin-top: 20px;
                    transition: background-color 300ms ease;
                    text-decoration: none;
                    cursor: pointer;
                    padding: 8px 10px;
                    border: 1px solid #B3B3B3;
                    border-radius: 4px;
                    margin-right: 0px;

                    @include min-screen(768px) {
                        display: block;
                    }

                    .hero-icon {
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                    }

                    span {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                    }
                }
            }
        }
    }
}

.public-playlist-separator {
    margin-bottom: 13px;
}

.publicPlaylistHeroContainer {
    margin: 25.5px 38px;

    .ppInfo {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }

}


.publicPlaylistSongContainer {

    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #141414;
    width: 100%;
    padding: 24px 34px;
    border-bottom: 1px solid $grey-lighter;
    cursor: pointer;
    height: 76px;

    .ppSongName {
        display: flex;
        gap: 20px;
        align-items: center;
        cursor: pointer;

        .ppNumber {
            min-width: 50px;
            display: flex;
            justify-content: center;
        }

        .playPauseImg {
            width: 25px;
            height: 25px;
        }

        .ppSongPlaying {
            color: $accent
        }
    }

    .ppSongTime {
        color: $grey-lighter;
    }
    .playBtn {
        display: none;
    }

}

.publicPlaylistSongContainer:hover {
    .ppSongName {
        color: $accent
    }
    .ppNumber {
        display: none;
    }
    .playBtn {
        display: block;
        min-width: 50px;
        display: flex;
        justify-content: center;
    }
}

.ppContainer{
    margin: 0px 38px;
    .ppContainerHeader {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 24px 34px;
        border-bottom: 1px solid $accent;
        color: $white;
        background-color: #141414;
        height: 50px;
        align-items: center;

        .ppSongTitleHeader {
            display: flex;
            gap: 20px;

            .ppSongIndexHeader {
                min-width: 50px;
                display: flex;
                justify-content: center;
            }
        }
    }
}

.ppUnauthorized{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    font-size: 16px;
}
.bx--tooltip__trigger{
    .trigger-text {
        color: $white;
        font: 700 36px/51px $poppins;
        letter-spacing: letter-spacing(25, 30px);
        max-width: 900px;
        margin: 0 auto 5px;
        white-space: normal ;
        overflow: hidden ;
        text-overflow: ellipsis ;
        text-overflow: initial;

        @media (max-width:768px) {
          font-size: 36px;
          max-width: 490px;
          margin: 0 auto 5px;
          white-space: normal ;
          overflow: hidden ;
          text-overflow: initial !important ;
          letter-spacing: letter-spacing(25, 50px);
          margin: 0 0 5px;

        }
        @media (max-width:1024px) {
            font-size: 36px;
            max-width: 590px;
            margin: 0 auto 5px;
            white-space: nowrap ;
            overflow: hidden ;
            text-overflow: ellipsis ;
            letter-spacing: letter-spacing(25, 50px);
            margin: 0 0 5px;

          }
        @media (max-width:425px) {
            text-align: center;
            font-size: 20px;
            max-width: 395px;
            margin: 0 auto 5px;
            overflow-wrap: break-word;
            white-space:normal;
            line-height: normal;;
            overflow: hidden ;
            letter-spacing: letter-spacing(25, 50px);
            margin: 0 0 5px;

          }

        @include min-screen(1024px) {
            font-size: 36px;
            letter-spacing: letter-spacing(25, 60px);
        }
    }
}

.publicPlaylistModal {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    min-height: 150px;
    padding: 10px 20px 20px 20px;
    background-color: $grey-dark;
    border-top: 1px solid $primary;
    .waveform-wrapper {
        max-height: 184px;
    }
    .cross-icon-container {
        position: absolute;
        cursor: pointer;
    }
  }
  .public-songs-wrapper{
    margin-bottom:258px;
}
.public-songs-waveform-wrapper{
    margin-bottom:258px;
}

.publicPlaylistModal {
    .cross-icon-container{
        right: 42px;
    }
}


@media screen and (max-width: 450px) {
    .ppContainer {
        margin: 0px !important;
    }
    table tbody tr td:first-of-type {
        padding-left: 10px !important;
    }
    table tbody tr td:last-of-type {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }
    .ppContainer table tbody tr td:nth-child(1), .ppContainer table tbody tr td:nth-child(2) {
        width: 40px !important;
    }
    .ppContainer table tbody tr td:nth-child(3) {
        p {
            word-break: break-all !important;
        }
    }
}