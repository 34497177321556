.hero {
  height:auto;
  width: 100%;
  background: $componentBackground;
  .sectionHeaderLink {
    background: none;
    border: none;
    color: $accent;
    font: 500 14px/1.4 $poppins;
    padding: 0;
    text-align: right;
    text-decoration: none;
  }
  .hero-button {
    background-color: $btnbackground;
    border-radius: 6px;
    border: 1px solid #4e4e4e;
    color: $accent;
    font: 500 16px/25px $poppins;
    height: 45px;
    min-width: 150px;
    transition: background 300ms ease, border-color 300ms ease, color 300ms ease;

    &:hover {
      background: $white;
      border-color: $white;
      color: $btnbackground;
    }
  }
  .bx--search-input{
    background: $appSearchBackground;
  }
  .breadcrumb{
    margin-bottom: 0;
    padding-top: 30px;
    margin-top:0 !important;
  }
  img {
    display: none; // Temporary fix to hide the background image while admin is ready
    object-fit: cover;
    object-position: center center;
    width: 100%;

    @include max-screen(1023px) {
      height: 100%;
    }
  }

  .heroContent {
    max-width: 1440px;
    // padding: 21px 0;
    padding-top: 8px;
    margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid #4e4e4e;
    padding: 35px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      color: $white;
      font: 700 34px/1.2 $poppins;
      letter-spacing: letter-spacing(25, 40px);

      @include min-screen(768px) {
        letter-spacing: letter-spacing(25, 60px);
      }
    }
    p {
      color: $white;
      font: 700 34px/1.2 $poppins;
      height: 51px;
      font-family: $poppins;
      font-style: normal;
      font-weight: 700;
      font-size: 34px;
      line-height: 51px;

      @include min-screen(768px) {
        letter-spacing: letter-spacing(25, 60px);
      }
    }
    .actions-wrapper{
 display:flex
}
.title-wrapper{
  display: flex;
  align-items: center;
}
.hero-add-button{
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  margin-left: 26px;
  & img {
    display: block;
    height: 20px;
    min-width: 20px;
    opacity: 1;
    &.upload-btn {
      height: 20px;
      min-width: 16px;
    }
  }
}
    .heroNav {
      margin-right: 25px;
      .heroNavItem {
        background: none;
        border: none;
        color: $white;
        display: inline-block;
        font: 500 16px/1.2 $poppins;
        margin-right: 25px;
        padding: 0;
        text-decoration: none;

        @include min-screen(768px) {
          font-size: 18px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.separator {
  display: block;
  height: 0px;
  border: 0;
  border-top: 1px solid #676767;
  margin: 0px;
  padding: 0px;
}
