.fav-recordings-table {
  td:nth-child(1) {
    width: 4%;
  }
  td:nth-child(2) {
    width: 30%;
  }
  td:nth-child(5) {
    width: 33%;
  }
  td:nth-child(4),
  td:nth-child(3) {
    width: 16.5%;
  }
}

.fav-sessions-table {
  td:nth-child(1) {
    width: 4%;
  }
  td:nth-child(2) {
    width: 30%;
    @media (max-width: 1024px) {
      width: 49%;
    }
  }
  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5),
  td:nth-child(6) {
    width: 16.5%;
  }
}

.fav-file-table {
  td:nth-child(1) {
    width: 4%;
  }
  td:nth-child(2) {
    width: 30.5%;
  }
  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5) {
    width: 16.5%;
  }

  td:nth-child(7) {
    width: 2%;
  }
}
