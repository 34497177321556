.main.recording-detail {
  .commentsPane {
    top: 85px !important;
    // margin-bottom: 10px !important;
  }
  // Dropdpwn menu for files
  .uploadFileDropdown {
    cursor: pointer;
    border: 1px solid #272727;
    background: $componentBackground;
    width: 124px;
    height: 88px;
    display: inline-block;
    position: relative;
    margin-top: -20px;
    z-index: 5;
    padding: 10px 30px 10px 12px;
    left: 180px;
    color: #fff;
    .nav-link {
      font-size: 14px;
      line-height: 21px;
      display: flex;
      padding-bottom: 17px;
      .upload-icon {
        width: 16px;
        height: 20px;
        padding-left: 2px;
      }
      .files-icon {
        width: 20px;
        height: 16px;
      }
      .upload-text {
        margin-left: 13px;
      }
      .files-text {
        margin-left: 11px;
      }
    }
  }
  .uploadFileDropdownDiv {
    position: absolute;
    left: 116px;
    .arrow-1 {
      width: 0;
      height: 0;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 22px solid $componentBackground;
      position: absolute;
      left: 186px;
      top: -34px;
      z-index: 10;
    }

    .arrow-2 {
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 18px solid #272727;
      position: absolute;
      left: 188px;
      top: -36px;
    }
  }
  & .sections-container {
    .actions-header {
      height: 54px;
      margin-top: 12px;
    }
  }
  & .hero {
    height: 120px;
    & .sections-container {
      & .heroContent {
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 0px;
        display: flex;
        height: 80px;
      }
      & .breadcrumb.bx--breadcrumb.bx--breadcrumb--no-trailing-slash {
        margin-top: 0px;
        padding-top: 21px;
      }
    }
  }

  & .create-hero {
    margin-bottom: 11px;
    & .title-wrapper .vertical-line {
      color: $accent;
    }
  }
  & .mainContainer {
    padding-top: 0px !important;
  }
  & .section {
    margin-bottom: 22px;
    & .bx--data-table-v2--zebra {
      @media (max-width: 1550px) {
        width: 96% !important;
      }
    }
    & .bx--data-table-v2-container {
      & .actions-header {
        padding-left: 22px;

        .custom-pagination {
          padding-left: 0px;
        }
      }
    }
  }
  & .infoGrid {
    grid-gap: 35px 20px;
    grid-template-columns: minmax(min-content, 466px) minmax(min-content, 166px) minmax(
        min-content,
        166px
      ) minmax(min-content, 166px) minmax(min-content, 285px);
    padding-left: 0px;
    padding-right: 30px;
    padding-top: 25px;

    .infoText {
      &:nth-child(2) {
        grid-column: span 2;
      }
      &:last-child {
        margin-right: 5px;
        grid-column: 1/-1;
        @media (max-width: 1024px) {
          margin-right: 30px;
        }
        padding-bottom: 9px;

        .infoTextContent {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          padding-right: 80px;
        }
      }
      .infoTextTitle {
        margin: 0px;
      }
      .infoTextContent {
        button {
          height: inherit;
          padding: 12px 10px;
          background: transparent;
          border: 1px $black-100 solid;
        }
      }
    }
  }
}



//  Project Recording Credits Page - Start
// 

.projectRecordingCredits { 


  .mainContainer {
    padding-top: 0px !important;
  }


}

// 
//  Project Recording Credits Page - End




//  Project Recording  Files Page - Start
// 

.projectRecordingFiles { 


  .mainContainer {
    padding-top: 0px !important;
  }


}

// 
//  Project Recording Files Page - End


//  Project Recording Session Page - Start
// 

.projectRecordingSession { 


  .mainContainer {
    padding-top: 0px !important;
  }


}

// 
//  Project Recording Session Page - End
