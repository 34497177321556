.waveform-wrapper {
  .preloader-inner {
    height: 150px;
    text-align: center;
    font-size: 3em;
    font-family: monospace;
    font-weight: 300;
    color: #333;
  }
  .initials-avatar{
    height: 20px;
    width: 20px;
    text-align: center;
    font-size: 11px;
    padding-top: 4px;
    border-radius: 100%;
    background-color: $white;
  }
  //audio control icons
  .icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-size: 14px 10px;
    background-repeat: no-repeat;
    background-position-x: 40%;
    background-position-y: center;
    background-color: #0d0d0d;
    border-radius: 100%;
  }
  .rewind-icon {
    margin-top: 2px;
    background-image: url("assets/waveform/rewind.svg");
  }
  .forward-icon {
    background-image: url("assets/waveform/forward.svg");
    background-position-x: 53%;
  }
  .pause-icon {
    width: 38px;
    height: 38px;
    background-size: 18px 14px;
    background-image: url("assets/waveform/pause.svg");
    background-position-x: 51%;
  }
  .play-icon {
    background-image: url("assets/waveform/play.svg");
    background-position-x: 56%;
    background-size: 18px 14px;
    width: 38px;
    height: 38px;
  }
  .rewind-icon:hover {
    cursor: pointer;

    background-image: url("assets/waveform/rewindActive.svg");
  }
  .forward-icon:hover {
    cursor: pointer;

    background-image: url("assets/waveform/forwardActive.svg");
  }
  .pause-icon:hover {
    cursor: pointer;

    background-image: url("assets/waveform/pauseActive.svg");
  }
  .play-icon:hover {
    background-image: url("assets/waveform/playActive.svg");

    cursor: pointer;
  }
  .actions-container {
    display: flex;
    color: white;
    width: 100%;

    .media-details-wrapper {
      display: flex;
      color: white;
      justify-content: space-between;
      align-content: center;
      padding-top: 14px;
      align-items: center;
      width: 100%;
      .date{
        margin-right: 37px; 
        @media screen and (min-width: 1024px) and (max-width: 1443px){
          margin-right: 0px; 
        }
      }
    }
    .media-details {
      display: flex;
      align-items: center;
    }
    .media-name {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      padding: 5px 15px;
      color: $accent;
      white-space: nowrap;
      overflow: hidden;
      // width:300px;
      text-overflow: ellipsis; 
    }
    .media-extension {
      width: 27px;
      height: 15px;
      background: $white;
      color: black;
      font-size: 10px;
      font-weight: 400;
      padding: 2px;
      padding-top: 3px;
      padding-left: 3px;
      margin-right: 9px;
      border-radius: 3px;
    }

  }
  .media-size .date {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }
  .audio-controls-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    margin-left: 15px;
    width: 120px;
  }
  .user-avatar-wrapper {
    height: 65px;
    position: absolute;
    margin-top: -59px;
    z-index: 10;
    width: 100%;
    background-color: $componentBackground;
  }
  .user-initials {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    height: 30px;
    width: 30px;
    font-size: 14px;
    border-radius: 50%;
    position: absolute;
    left: -10px;
    bottom: -10px;
    cursor: pointer;
    line-height: 0px;
  }
  .wavesurfer-handle.wavesurfer-handle-start {
    background-color: $white !important;
    // display: none;
    // width: 1px !important;
  }
  .wavesurfer-handle.wavesurfer-handle-end {
    background-color: $white !important;
    // display: none;
    // width: 1px !important;
  }
  #waveform {
    .selected-region {
      background-color: rgba($color: $primary, $alpha: 0.2) !important;
      .wavesurfer-handle  {
        &.wavesurfer-handle-start {
          background-color: rgba($color: $primary, $alpha: 0.6) !important;
        }
        &.wavesurfer-handle-end {
          background-color: rgba($color: $primary, $alpha: 0.6) !important;
        }
        // display: none;
        // width: 1px !important;
      }
    }


  }
}

@media screen and (min-width: 760px) {
  .media-name {
    max-width: 400px;
  }
  .media-filename {
    max-width: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .media-name {
    max-width: 280px;
  }
  .media-filename {
    max-width: 300px;
  }
}
@media screen and (min-width: 1148px) {
  .media-name {
    max-width: 780px;
  }
  .media-filename {
    max-width: 400px;
  }
}
@media screen and (min-width: 1200px) {
  .media-name {
    max-width: 900px;
  }
  .media-filename {
    max-width: 750px;
  }
}
.bx--tooltip.bx--tooltip--shown.waveform-filename-tooltip {
  max-width: 100% !important;
}