.infoGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  max-width: 1250px !important;

  @include min-screen(700px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px 20px;
  }


  @include min-screen(1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  + div {
    margin-top: 80px;
  }

  &--min-2 {
    grid-template-columns: 1fr 1fr;
  }
}

.infoText {
  color: $white;

  .infoTextTitle {
    color: #bbbbbb;
    font-family: $poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    .bx--tooltip__trigger.tooltip--icon {
      position: unset;
      .info-icon {
        top: 0px;
      }
    }

    @include min-screen(768px) {
      margin: 0 0 5px;
    }
  }

  .infoTextContent {
    color: $white;
    font-family: $poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    font: 400 14px/1.4 $poppins;
  }

  &.infoTextSpanDouble {
    grid-column: 1 / -1;
  }
}
.info-grid-wrapper{
  margin-bottom: 120px;
}
