.subscriptionCurrentPlan {
    color: $accent;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    span {
        color: $white;
        font-weight: 700;
    }
}
.subscription-container{
    .sectionHeaderActions{
        width:auto !important;
        align-self: flex-start !important;
    }
    .sectionHeaderLink{
        align-self: center;
        padding-top: 10px !important;
        font: 500 17px/1.4 "Poppins"!important;
    }
    margin: 0 ;
    position:relative;
    left:50px;
    max-width: 1440px;
    padding-top: 20px;
    width: calc(100% - 30px);

    @include min-screen(768px) {
      padding-top: 20px;
    }
    @include min-screen(1024px) {
      width: calc(100% - 300px);
    }
}
.stripe-element-form{
    max-width:100% !important;
    .StripeElement--focus {
        background-color:$grey-dark;
        color: $white;
        border-bottom: 2px solid $primary
    }
    .StripeElement--invalid {
        background-color:$grey-dark;
        border-bottom: 2px solid $error-color
    }
    .StripeElement--complete{
        background-color: $grey-dark;
        color:$white !important;
    }
    .StripeElement--webkit-autofill {
        color:$white !important; 
    }
}
.subscription {
        p {
        color: $black-100;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin: 0 0 10px;
    }
        + .form {
        .bx--form .stripeElement {
            margin: 0 0 40px;
            padding: 10px;
        }
    }
}
div.subscriptionOptions:last-child{
    margin-right: 0px;
}
.subscriptionOptions {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 35px;
    
    &--registration {
        margin-bottom: 0;
    }
    .lastOption{
        margin-right: 0px !important;
    }
    .planOption {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        -webkit-appearance: none;
        background: $componentBackground;
        border: 1px solid $black-100;
        color: $white;
        margin-right: 16px;
        padding: 20px 50px;
        text-align: center;
        transition: transform 300ms ease-in-out;
        min-width: 100px;

        @include max-screen(1024px) {
            line-height: 60px;
            height: 80px;
                        min-width: 120px;
        }

        &--current {
            background: $accent;
            color: $white;
            border: none;
        }

        &--small {
            font-size: 14px;
            height: auto;
            line-height: 20px;
            min-width: 0;
            padding: 10px;
        }

        &:hover {
            transform: scale(1.07);
        }
    }
}

.subscriptionCard {
    max-width: 540px;
    .subscriptionCardEnding {
        color: $white;
        display: flex;
        justify-content: space-between;
        margin-bottom: 7px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        .updateLabel{
            color: $black-100
        }
        .cardEnding {
            font-size: 12px;
            text-align: right;

            span {
                color: $accent;
                font-weight: 700;
            }
        }
    }
    .formRowLast{
        margin-top: 50px;
        button{
            padding: 10px;
            min-width: 0px;
            display: inline-flex;
        }
    }
    .formRow{
        margin-bottom: 35px;
    }
}

// new style for account subscription
.account-subscription{
    .bx--form{
        margin-top: 0px;
    }
    .sections-container{
        .section{
            margin-bottom: 0px;
        }
    }
    .mainContainer.customization-page{
      padding-top: 9px !important;
      .section{
        margin-bottom: 0px !important;
          .custom-download-page-image {
            .cdpi-header-container{
              margin-bottom: 18px;
              height: 0px !important;
              h3{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: $black-100;
              }
            }
        }
        .uploadCustomization{
          border-bottom: none;
          margin-bottom: 0px !important; 
          padding-bottom: 24px;
          margin-top: 9px;
            .custom-image-and-actions-container{
              .dpi-actions{
                button{
                  background: transparent;
                  padding: 10px;
                  border: 1px solid $black-100;
                }
              }
          }
        }
      }
    }
    .social-follow{
      .create-hero{
        margin-bottom: 44px;
      }
      .mainContainer{
        padding-top: 0px !important;
        margin-top: 8px;
        .social-follow-container{
          .bx--toggle__appearance{
            width: 0rem;
            margin-right: 30px;
          }
        }
      }
    }
    .create-hero{
      .sections-container{
        margin: 0px auto 0px 50px;
        .main-Switcher{
          padding-top: 63px;
          .main-Switcher-Content{
            // margin-right: 28px;
            .main-Switcher-Switch-Selected, .main-Switcher-Switch-None{
            //   padding: 4px 0px 7px 0px;
            }
          }
  
        }
      }
    }

 .subscription-details{
    display: flex;
    align-items: baseline;
    margin-top: 49px;
    margin-bottom: 28px;
    a{
        text-decoration: none;
        button{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 16px;
            display: flex;
            border: 1px solid $black-100;
            background: $header-background;
            border-radius: 4px;
            color: $white;
            margin-left: 37px;
            padding: 10px;
            text-align: center;
            min-width: 100px;
        }
    }
 }
 .share-with-people{
    max-width: 847px;
    margin-top: 35px;
    p{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $white;
        span{
            color: $accent;
        }
        &:nth-child(2){
            margin-top: 30px;
        }
    }
    .share-link{
        max-width: 547px;
        margin-top: 59px;
        .copy-clipboard{
            display: flex;
            .bx--form-item{
                background: $componentBackground;
            }
            button{
                margin-left: 13px;
                padding: 10px;
                height: auto;
                img{
                    margin-right: 12px;
                }
            }
        }
    }
    .share{
        max-width: 345px;
            .share-as{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                background: $componentBackground;
                border: none;
                color: $white;
                margin-top: 30px;
                padding: 20px 22px 20px 23px;
                min-width: 100px;
                cursor: pointer;
                     &:last-child{
                        margin-bottom: 80px;
                     }
                        img{
                            margin-right: 33px;
                        }
                .twitterIcon {
                    width: 19px;
                    height: 19px;        
                } 
            }
    }
 }

}


// Redeem Modal
.redeem-modal{
    .bx--modal-container{
        max-width: 550px;
    }
    .bx--radio-button-group{
        display: block;
        .radioButtonWrapper{
            margin-bottom: 20px;
        }
    }
    .btns-container{
        display: flex;
        justify-content: flex-end;
    }
}