$accent: #d5743e;
// $appBackground:linear-gradient(#191919, #101010);
$appBackground: #000;
$appTextColor: #fff;
// $componentBackground:#1f1f1f;
$componentBackground:#0D0D0D;
$sidebarBackground:#0D0D0D;
$btnbackground: #0f0f0f;
$appSearchBackground:#1F1F1F;
$white: #fff;
$greyFieldTitle: #B3B3B3;
$accentDark: #D5743F;

:root {
  --accent-color: #{$accent};
  --app-background-color: #{$appBackground};
  --app-text-color: #{$appTextColor};
  --sidebar-background-color: #{$sidebarBackground};
  --component-background-color: #{$componentBackground};
  --btn-background-color: #{$btnbackground};
  --app-search-background-color: #{$appSearchBackground};
  --grey-field-title: #{$greyFieldTitle};
  --accent-dark-color: #{$accentDark};
}
