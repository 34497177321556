.reveal {
  .revealContentTrigger {
    background: none;
    border: none;
    color: #bbbbbb;
    display: inline-block;
    font: 500 14px/1.4 $poppins;
    margin-bottom: 50px;
    padding: 0;
    text-decoration: underline;
  }

  .revealContentContainer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease-in-out;
  }

  &.expanded {
    .revealContentTrigger {
      opacity: 0;
      visibility: hidden;
    }

    .revealContentContainer {
      max-height: 1000px;
    }
  }
}
