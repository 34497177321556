.login {
    display: flex;
    position: absolute;
    width: 100%;
    z-index: 9999;
    top: 0;

    .appBranding{
        display: flex;
        align-self: center;
        justify-content: center;
        margin-top: 100px;
    }

    .loginFormBackground{
        background-color: #0f0f0f;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
        max-width: 572px;
        padding: 20px 0;
        width: 100%;
        position:relative;
        z-index: 10000;

        .loginFormWrapper {
            align-self: center;
            max-width: 430px;
            position: relative;
            display: flex;
            flex-direction: column;

            @include max-screen(1000px) {
              align-items: center;
              display: flex;
              flex-direction: column;
            }
            p {
                color: white;
                margin-top: 2em;
            }

        }

        .loginForm {
            margin-top: 50px;
            max-width: 405px;
            width: 100%;
            background-color: #1F1F1F;
            padding: 35px;

            .loginSubmit {
                margin-top: 30px;
                width: 100%;
                height: 48px;
            }
            &:has(.success-message) {
                .loginSubmit {
                    margin-top: 25px;
                }
                .success-message {
                    font-family: $poppins;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    text-align: center;
                    color: $white;
                    margin-top: 25px;
                }
            }

            .links-container {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                row-gap: 11px;
                > a {
                    margin: 0;
                }
            }
            @include max-screen(768px) {
                padding: 20px;
            }

            .plans-container {
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                .plan-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #242424;
                    .plan-info {
                        max-width: 245px;
                        .plan-name {
                            font-family: $poppins;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            color: $accent;
                        }
                        .plan-detail {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 18px;
                            color: $white;
                        }
                    }
                    .plan-option {
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 8px 10px;
                        // gap: 8px;
                        height: 37px;
                        border: 1px solid #B3B3B3;
                        border-radius: 4px;
                        background-color: transparent;
                        outline: none;

                        // font styles
                        font-family: $poppins;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        color: $white;

                        &.selected-plan-option {
                            background: $accent;
                            border-color: $accent;
                        }
                    }
                }
            }
            .bx--checkbox-label {
                font-family: $poppins;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: $white;

                margin-bottom: calc(66px - 25px);

                a {
                    color: $accent;
                }
                span > span {
                    margin-left: 12px;
                }
                &::before {
                    box-sizing: border-box;
                    width: 20px;
                    height: 20px;
                    border: 1px solid #B3B3B3;
                    border-radius: 3px;
                }
            }
        }

    }

    .loginBackground {
        height: 100vh;
        position: fixed;
        width: 100vw;
        .test-wrapper {
            position: relative;
        }
        img {
            display: flex;
            height: 100vh;
            margin-left: auto;
            object-fit: cover;
            object-position: top;
            width: calc(100vw - 572px);
        }
        .text-overlay{
            height: 100vh;
            min-width: calc(100vw - 572px);
            top:0;
            right: 0;
            position: fixed;

            .content-wrapper{
                margin-left: 110px;
                margin-top: 70px;
                .header{
                    display: flex;
                    padding: 30px 0px ;
                    width: 100%;
                    .compare-plans {
                        background: #d5743e;
                        color: #fff;
                        text-decoration: none;
                        padding: 5px 12px !important;
                        border-radius: 4px;
                        text-align: center;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;
                        height: auto;
                    }
                    &-plan{
                        &-name{
                            p{

                            font-size: 25px;
                            font-weight: 500;
                            }
                            font-size: 50px;
                            font-weight: 700;
                            padding-right: 50px;
                            border-bottom: 2px solid $accent;
                        }
                        &-price{
                            font-size: 27px;
                            font-weight: 500;
                            padding-top: 20px;

                            padding-right: 50px;
                            padding-left: 2px;
                        }
                    }
                    .header-caption{
                        min-width: calc(100% - 430px);
                        min-height: 105px;
                        display: flex;
                        justify-content: space-between;
                        align-content: center;
                        align-items: flex-end;
                        padding-bottom: 10px !important;
                        margin-top: 30px;
                        padding: 35px 0px 16px 45px ;
                        border-left: 2px solid $accent;
                        border-bottom: 2px solid $accent;

                        p{
                            font-size: 20px;
                            font-weight: 500;
                        }
                        button
                        {
                            padding: 10px 20px;
                            height: 40px;
                        }
                    }
                    .unlimited{
                        margin-top: 57px;
                    }
                    .pro{
                        font-size: 50px;
                        height: 60px;
                        font-weight: 700;
                    }


                }
                .bullets{
                    margin-left: 170px;

                    width: 600px;
                    ul{

                        li{
                            padding: 10px;
                            list-style-type: disc;
                        }
                    }
                }
            }
        }


        .loginBackgroundCredit {
            display: none;
            @media (min-width: 830px) {
                display: block;
                position: absolute;
                bottom: 35px;
                right: 10px;
                color: white;
                font-size: 12px;
                font-family: Poppins, 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
            }
        }
    }
    @media(min-width:768px) {
        .loginFormBackground{
            max-width: 380px;
        }
    .loginBackground {
        height: 100vh;
        position: fixed;
        width: 100vw;
        .test-wrapper {
            position: relative;
        }
        img {
            display: flex;
            height: 100vh;
            margin-left: auto;
            object-fit: cover;
            object-position: top;
            width: calc(100vw - 380px);
        }
        .text-overlay{
            height: 100vh;
            width: calc(100vw - 380px);
            top:0;
            right: 0;
            position: fixed;

            .content-wrapper{
                margin-left: 23px;
                margin-top: 70px;
                .header{
                    display: flex;
                    padding: 10px 0px ;
                    width: 100%;
                    &-plan{
                        &-name{
                            p{

                            font-size: 10px;
                            font-weight: 500;
                            }
                            font-size: 20px;
                            font-weight: 700;
                            padding-right: 20px;
                            border-bottom: 2px solid $accent;
                        }
                        &-price{
                            font-size: 10px;
                            font-weight: 500;
                            padding-top: 10px;

                            padding-right: 20px;
                            padding-left: 2px;
                        }
                    }
                    .header-caption{
                        width: calc(100% - 92px);
                        min-height: 65px;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;
                        padding: 10px 0px 0px 20px;
                        border-left: 2px solid #d5743e;
                        border-bottom: 2px solid #d5743e;
                        align-content: center;
                        align-items: flex-end;
                        padding-bottom: 10px !important;

                        p{

                            font-size: 10px;
                            font-weight: 500;
                            height: auto !important;
                        }

                        button
                        {
                            padding: 10px 20px;
                            height: 40px;
                        }
                    }
                    .unlimited{
                        margin-top: 25px;
                    }
                    .pro{
                        font-size: 20px;
                        height: 30px;
                        font-weight: 700;
                    }


                }
                .bullets{
                    margin-left: 40px;

                    width: 300px;
                    ul{
                        .list-wrapper{
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            .box{
                                width: 50%;
                                padding-left: 0px;
                            }
                        }
                        li{
                            padding: 10px;
                            font-size: 8px;
                            list-style-type: disc;
                        }
                    }
                }
            }
        }


        .loginBackgroundCredit {
            display: none;
            @media (min-width: 830px) {
                display: block;
                position: absolute;
                bottom: 35px;
                right: 10px;
                color: white;
                font-size: 12px;
                font-family: Poppins, 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
            }
        }
    }
    }
    @media(min-width:1024px) {
        .loginFormBackground{
            max-width: 572px;
        }
    .loginBackground {
        height: 100vh;
        position: fixed;
        width: 100vw;
        .test-wrapper {
            position: relative;
        }
        img {
            display: flex;
            height: 100vh;
            margin-left: auto;
            object-fit: cover;
            object-position: top;
            width: calc(100vw - 572px);
        }
        .text-overlay{
            height: 100vh;
            width: calc(100vw - 572px);
            top:0;
            right: 0;
            position: fixed;

            .content-wrapper{
                margin-left: 23px;
                margin-top: 70px;
                .header{
                    display: flex;
                    padding: 10px 0px ;
                    width: 100%;
                    &-plan{
                        &-name{
                            p{

                            font-size: 12px;
                            font-weight: 500;
                            }
                            font-size: 22px;
                            font-weight: 700;
                            padding-right: 20px;
                            border-bottom: 2px solid $accent;
                        }
                        &-price{
                            font-size: 12px;
                            font-weight: 500;
                            padding-top: 10px;

                            padding-right: 20px;
                            padding-left: 2px;
                        }
                    }
                    .header-caption{
                        min-width: calc(100% - 430px);
                        min-height: 70px;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;
                        align-content: center;
                        align-items: flex-end;
                        padding-bottom: 10px !important;
                        padding: 10px 0px 1px 20px ;
                        padding-bottom: 0px;
                        border-left: 2px solid $accent;
                        border-bottom: 2px solid $accent;
                        p{

                            font-size: 12px;
                            font-weight: 500;
                            height: auto !important;
                        }
                        button
                        {
                            padding: 10px 20px;
                            height: 40px;
                        }
                    }
                    .unlimited{
                        margin-top: 25px;
                    }
                    .pro{
                        font-size: 22px;
                        height: 30px;
                        font-weight: 700;
                    }


                }
                .bullets{
                    margin-left: 50px;

                    width: 300px;
                    ul{
                        .list-wrapper{
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            .box{
                                width: 50%;
                                padding-left: 0px;
                            }
                        }
                        li{
                            padding: 10px;
                            font-size: 12px;
                            list-style-type: disc;
                        }
                    }
                }
            }
        }


        .loginBackgroundCredit {
            display: none;
            @media (min-width: 830px) {
                display: block;
                position: absolute;
                bottom: 35px;
                right: 10px;
                color: white;
                font-size: 12px;
                font-family: Poppins, 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
            }
        }
    }
    }
    @media(min-width:1280px) {
        .loginFormBackground{
            max-width: 572px;
        }
    .loginBackground {
        height: 100vh;
        position: fixed;
        width: 100vw;
        .test-wrapper {
            position: relative;
        }
        img {
            display: flex;
            height: 100vh;
            margin-left: auto;
            object-fit: cover;
            object-position: top;
            width: calc(100vw - 572px);
        }
        .text-overlay{
            height: 100vh;
            width: calc(100vw - 572px);
            top:0;
            right: 0;
            position: fixed;

            .content-wrapper{
                margin-left: 50px;
                margin-top: 70px;
                margin-right: 50px;
                .header{
                    display: flex;
                    padding: 10px 0px ;
                    width: 100%;
                    &-plan{
                        &-name{
                            p{

                            font-size: 14px;
                            font-weight: 500;
                            }
                            font-size: 24px;
                            font-weight: 700;
                            padding-right: 20px;
                            border-bottom: 2px solid $accent;
                        }
                        &-price{
                            font-size: 14px;
                            font-weight: 500;
                            padding-top: 10px;

                            padding-right: 20px;
                            padding-left: 2px;
                        }
                    }
                    .header-caption{
                        width: calc(100% - 230px);
                        min-height: 70px;
                        display: flex;
                        justify-content: space-between;
                        align-content: center;
                        align-items: flex-end;
                        padding-bottom: 10px !important;
                        margin-top: 10px;
                        padding: 10px 0px 1px 20px ;
                        padding-bottom: 0px;
                        border-left: 2px solid $accent;
                        border-bottom: 2px solid $accent;

                        p{

                            font-size: 14px;
                            font-weight: 500;
                            height: auto !important;
                        }
                        button
                        {
                            padding: 10px 20px;
                            height: 40px;
                        }
                    }
                    .unlimited{
                        margin-top: 25px;
                    }
                    .pro{
                        font-size: 24px;
                        height: 30px;
                        font-weight: 700;
                    }


                }
                .bullets{
                    margin-left: 110px;

                    width: 400px;
                    ul{
                        .list-wrapper{
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            .box{
                                width: 50%;
                                padding-left: 0px;
                            }
                        }
                        li{
                            padding: 10px;
                            font-size: 14px;
                            list-style-type: disc;
                        }
                    }
                }
            }
        }


        .loginBackgroundCredit {
            display: none;
            @media (min-width: 830px) {
                display: block;
                position: absolute;
                bottom: 35px;
                right: 10px;
                color: white;
                font-size: 12px;
                font-family: Poppins, 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
            }
        }
    }
    }
    @media(min-width:1440px) {
        .loginFormBackground{
            max-width: 572px;
        }
    .loginBackground {
        height: 100vh;
        position: fixed;
        width: 100vw;
        .test-wrapper {
            position: relative;
        }
        img {
            display: flex;
            height: 100vh;
            margin-left: auto;
            object-fit: cover;
            object-position: top;
            width: calc(100vw - 572px);
        }
        .text-overlay{
            height: 100vh;
            width: calc(100vw - 572px);
            top:0;
            right: 0;
            position: fixed;

            .content-wrapper{
                margin-left: 100px;
                margin-top: 70px;
                margin-right: 50px;
                .header{
                    display: flex;
                    padding: 10px 0px ;
                    width: 100%;
                    .compare-plans {
                        padding: 10px 12px !important;
                    }
                    &-plan{
                        &-name{
                            p{

                            font-size: 14px;
                            font-weight: 500;
                            }
                            font-size: 24px;
                            font-weight: 700;
                            padding-right: 20px;
                            border-bottom: 2px solid $accent;
                        }
                        &-price{
                            font-size: 14px;
                            font-weight: 500;
                            padding-top: 10px;

                            padding-right: 20px;
                            padding-left: 2px;
                        }
                    }
                    .header-caption{
                        width: calc(100% - 261px);
                        min-height: 70px;
                        display: flex;
                        justify-content: space-between;
                        align-content: center;
                        align-items: flex-end;
                        padding-bottom: 10px !important;

                        p{

                            font-size: 14px;
                            font-weight: 500;
                            height: auto !important;
                        }
                        button
                        {
                            padding: 10px 20px;
                            height: 40px;
                        }

                            margin-top: 10px;
                            padding: 10px 0px 1px 20px ;
                            padding-bottom: 0px;
                            border-left: 2px solid $accent;
                            border-bottom: 2px solid $accent;
                    }
                    .unlimited{
                        margin-top: 25px;
                    }
                    .pro{
                        font-size: 24px;
                        height: 30px;
                        font-weight: 700;
                    }


                }
                .bullets{
                    margin-left: 130px;

                    width: 500px;
                    ul{
                        .list-wrapper{
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            .box{
                                width: 50%;
                                padding-left: 0px;
                            }
                        }
                        li{
                            padding: 10px;
                            font-size: 14px;
                            list-style-type: disc;
                        }
                    }
                }
            }
        }


        .loginBackgroundCredit {
            display: none;
            @media (min-width: 830px) {
                display: block;
                position: absolute;
                bottom: 35px;
                right: 10px;
                color: white;
                font-size: 12px;
                font-family: Poppins, 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
            }
        }
    }
    }
.feedback-text{
    text-align: center;
    padding-top: 30px;
}
.linkShare{
    display:flex;
    flex-direction: column;
    align-items: center;
}
.progress-text{
    text-align: start;
}

}
.mb-0 {
    margin-bottom: 0px;
}
.social-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 26px;
    margin-top: 72px;
    column-gap: 28px;
    max-width: 210px;
    margin-left: auto;
    margin-right: auto;
    .socila-img-wrapper {
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .social-icon-bg {
        border-radius: 5px;
        background-color: $white;
    }
}





//
//
// DOWNLOAD FILE SHARE
//
//

.download-background {
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
}
.download-shared-files, .publicShareWrapper {
    .social-icons  {
        margin: 67px 0px !important;
        margin-top: 43px !important;
        column-gap: 50px !important;
    }
    .appBranding {
        width: 214px !important;
        height: 110px !important;
        margin-top: 161px !important;
    }
    /* Position the navbar container inside the image */
.nav-container {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .loginFormBackground {
    background: #000000 !important;
  }

    .loginForm {
        margin: auto;
        margin-top: 64px !important;
        margin-bottom: 62px !important;
        width: 338px !important;
        height: 323px !important;
        padding: 17px !important;
        text-align: center !important;
        background: #141414;
        .loginSubmit {
            margin-top: 59px !important;
            height: 40px !important;
        }
    }
    .shared-download-logo-div {
        margin-bottom: 22px !important;
        .shared-download-logo {
            width: 88px;
            height: 88px;
        }
    }
    .shared-download-header {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 6px;
    }
    .shared-download-content {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        max-width: 205px;
        margin: auto;
    }
}

.discount-code-input {
    position: relative;
    margin-top: 0px;
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
    }
    .show-validate-message {
        position: absolute;
        p {
            margin-top: 15px !important;
        }
    }
    .info-icon{
        top: 0px;
    }
}
.cs-discount-code-input{
    margin-bottom: 82px !important;
}
.disabled-plan{
    opacity: 0.3;
}
.register-btn{
    margin-top: 0px;
}
.terms-conditions{
    margin-top: 26px;
}


.success-message-forget-password {
    text-align: center !important;
}