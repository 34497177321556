
// Colors
//
$white: #ffffff;
$grey-dark: #1f1f1f;
$grey: #4e4e4e;
$grey-light: #999999;
$grey-lighter: #bbbbbb;
$black: #000000;
$orage: #ff9602;
// Primary is "Vintage Orange/Primary 500 in Figma"
$primary: #d5743e;
$header-background: #0D0D0D;
$black-100: #B3B3B3;
$error-color: #e0182d;

$body-color: $white;

$error-color: #CD4136;
$success-color: #37A900;

$granite-gray-color: #676767;
$dark-grey-color: #676767;

$gray-8-color: #141414;
