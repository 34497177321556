.session-to-recording {
  margin: 40px;
  margin-left: 52px;
  max-width: 795px;

  .actions {
    margin-top: 80px;
  }
  .bx--btn {
    padding: 10px !important;
  }
}
.create-recording-modal {
  .actionBtnContainer {
    text-align: right !important;
    .bx--btn {
      padding: 10px !important;
    }
  }
}
.assign-recording-session {
  .heroContent {
    padding: 9px 0px 18px !important;
    margin-bottom: 0px;
    p {
      letter-spacing: 1px !important;
    }
  }
  .breadcrumb {
    padding-top: 21px !important;
    .bx--link {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
  }
}
