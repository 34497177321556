
@keyframes slideUp {
  from {
    transform: translateY(1em);
    opacity: 0;
  }
}
.bx--toast-notification {
  position: fixed;
  z-index: 100009;
  bottom: 1em;
  right: 1em;
  background: $grey-dark;
  color: $body-color;
  animation: slideUp .3s ease-out;
}
.bx--toast-notification__subtitle,
.bx--toast-notification__caption {
  color: $body-color;
}
.bx--toast-notification__subtitle {
  margin-bottom: 1rem;
}
.bx--toast-notification__close-button {
  background-color: transparent;
}
.bx--toast-notification__close-icon,
.bx--toast-notification__icon {
  fill: $body-color
}
