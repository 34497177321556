.share-file-download{
    -webkit-align-self: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    margin-top: 25px;
    max-width: 338px;
    background: $gray-8-color;
    box-shadow: 0px 3px 34px rgba(0, 0, 0, 0.55);
    position: relative;
    width: 338px;
    margin: auto;
    margin-top: 66px;
    .public-download{
        display: -webkit-flex;
        display: flex;
        width: 88px;
        height: 88px;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        border-radius: 50%;
        border: 3px solid $black-100;
        margin-top: 35px;
        margin: 17px auto 6px auto;
    }
    .download-text-container{
        h1{
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 30px;
            color: $white;
            margin-bottom: 3px;
            margin-top: 26px;
            text-align: center;
            margin-top: 0px;
        }
        p{
            font-size: 12px;
            font-weight: 400;
            font-style: normal;
            color: $primary !important;
            text-align: center;
        }
    }
    .public-share-container{
        width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        -webkit-justify-content: space-between;
        justify-content: space-around;
        align-items: baseline;
        border-bottom: 1px solid $header-background;
        .selected-file-container{
            width: 57%;
            // padding: 10px 20px;
            .selectedFile{
                padding-bottom: 5px;
                color: $white;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                margin-top: 23px;
                text-align: left;
            }
            .fileOptions{
                display: -webkit-flex;
                display: flex;
                -webkit-flex-direction: row;
                flex-direction: row;
                margin-bottom: 8px;
                .fileSize{
                    color: $black-100 !important;
                    font-size: 14px;
                    margin-left: 13px;
                    margin-top: 0px;
                }
                .fileType--mp3{
                    background: $white;
                }
            }
        }
        .imagePreviewContainer{
            img{
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
        .downloadImageContainer{
            img{
                width: 15px;
                height: 15px;
                cursor: pointer;
            }
        }
    }
    .publicDownloadButton{
        margin: 20px 0;
        width: 100%;
        padding: 0 20px;
        button{
            font-weight: 500;
            font-size: 14px;  
            line-height: 21px;
            padding: 10px 127px;
            display: inline-flex;
        }
    }
}
.download-description{
    padding: 0px 30px;
    p{
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        margin-top: 19px !important;
        color: $white;
    }
}


// download share


.share-download{

  .audio-controls-wrapper {
    margin-top: 10px !important;
  }
    .loginFormBackground { 
        background-color: $black;
        max-width: 490px !important;
        .loginFormWrapper{
            p{
                margin-top: 20px;
                text-align: center;
            }
        }
    }
    .loginBackground {
        img {
            width: calc(100vw - 490px) !important;
        }
    }
    .appBranding{
        width: 214px;
        height: 110px;
        .appLogo{
            margin-left: 0px;
        }
    }
    .download-share-form{
        padding: 0px 35px !important;
        background-color: $gray-8-color !important;
        .loginSubmit{
            height: 40px !important;
            padding: 10px 0px;
        }
    }
    .social-icons{
        margin-top: 110px;
        column-gap: 50px !important;
    }
    .download-share-expired{
        text-align: center;
        padding: 0px 35px !important;
        background-color: $gray-8-color !important;
        .bx--form{
            margin-top: 0px;
            .shared-download-logo-div{
                margin-top: 17px;
                margin-bottom: 25px;
            }
            .shared-download-header{
                margin-bottom: 15px;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px
            }
            .shared-download-content{
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                margin: auto;
                width: 64%;
            }
        }
        .loginSubmit{
            padding: 10px 0px;
            height: 40px !important;
        }
    }
}


// Direct link

.cs-direct-link{
    .direct-link-wrapper{
        margin-top: 70px;
        .appBranding{
            margin-top: 0px !important;
        }
        
        .download-expired{
            width: 338px !important;
            .bx--form{
                margin-top: 0px;
                .formRow{
                    margin-top: 0px;
                }
                .shared-download-logo-div{
                    margin-top: 17px !important;
                }
            }
        }
        .link-share-icon{
            .social-icons{
                margin-top: 119px !important;
            }
        }
        
    }
    .loginForm.download-share, .loginForm.download-expired{
        background-color: $gray-8-color !important;
        max-width: 338px;
        width: 338px !important;
        .bx--form{
            margin-top: 17px;
            .shared-download-header{
                margin-top: 20px;
            }
        }
        .social-icons{
            margin-top: 119px !important;
        }
    }
    
    .loginForm{
        margin-bottom: 0px !important;
        padding: 0px 35px !important;
        max-width: 338px;
        width: 100% !important;
        height: auto !important;
        background-color: $gray-8-color !important;
        .formRow{
            margin-top: 0px !important;
            &:first-child{
                margin-bottom: 6px !important;
            }
        }
    }
    .loginSubmit{
        margin-top: 11px !important;
    }
  
}

.link-share-downloading {
    padding-top: 50px;
}