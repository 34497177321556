.favourites {
  .hero {
    height: 120px;

    .separator {
      display: none;
    }

    .title-wrapper {
      height: 51px;

      p {
        font-style: normal;
        font-weight: 700;
        font-size: 34px !important;
        line-height: 51px;
        letter-spacing: 0px !important;
      }
    }
  }



  .create-hero {
    margin-bottom: 34px;
    .separator {
      display: none;
    }
  }

  .heroContent {
    margin-top: 0px !important;
    height: 120px;
    // max-width: 1260px;
    margin-bottom: Opx !important;

    .appSearch {
      .bx--search-magnifier {
        fill: $black-100 !important;
        width: 18px;
        height: 18px;
        stroke-width: 2px;
      }

      .bx--search-input {
        color: $black-100 !important;
      }

      .bx--search-input::placeholder {
        color: $black-100 !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 21px !important;
      }
    }
  }

  // .appSearch {
  //   width: 292px;
  //   height: 40px;
  //   border-radius: 2px;
  // }

  .bx--search-input {
    background: $grey-dark;
  }

  @media only screen and (min-width: 1200px) {
    table td:nth-child(2) {
      // width: 330px !important;
    }
  }

  .favSection {
    .grid {
      // gap: 100px 23px;
      // grid-template-columns: max-content max-content max-content max-content max-content;
      // grid-template-columns: repeat(auto-fill, minmax(min-content, max-content)) !important;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 35px 23px;
    }

    .section {
      margin-bottom: 33px !important;
    }

    // .main .sections-container{
    //   max-width: 1260px;
    // }
  }

  .favRecording {
    @media only screen and (min-width: 1200px) {
      table td:nth-child(3) {
        width: 500px;

        .row-value {
          width: fit-content;
          max-width: 500px;
        }
      }
    }

    .create-hero {
      margin-bottom: 21px !important;
    }

    .section {
      margin-bottom: 22px !important;
      margin-top: 0px !important;
    }
  }

  .favProject {
    .section {
      margin-bottom: 34px !important;
    }

    .project {
      position: relative;
      width: 240px;
      margin-bottom: 0px;

      .projectCover {
        margin-bottom: 0px;
      }

      .projectArt {
        margin-bottom: 0px;
      }

      .tags-wrapper {
        margin-left: 0px;
      }

      .projectInfo {
        width: 100%;
        padding: 10px;
        border-width: 0px 1px 1px 1px;
      }

      .project-tile-title {
        padding: 0px;

        overflow: hidden;
        width: 210px;
        // white-space: nowrap;
        // text-overflow: ellipsis;

        aside {
          margin-top: 5px;
          margin-bottom: 11px;
        }
      }

      .favouriteIcon {
        height: 27px;
        width: 27.5px;
        cursor: pointer;
        position: absolute;
        top: 12.5px;
        right: 12.5px;
      }
    }
  }

  .favSong {
    .song {
      width: 240px;

      position: relative;
      width: 240px;
      margin-bottom: 0px;

      .songCover {
        margin-bottom: 0px;
      }

      .songArt {
        margin-bottom: 0px;
      }

      .tags-wrapper {
        margin-left: 0px;
      }

      .songInfo {
        width: 100%;
        padding: 10px;
        border-width: 0px 1px 1px 1px;
      }

      .song-tile-title {
        padding: 0px;
        margin-bottom: 11px;

        overflow: hidden;
        // width: 210px;
        // white-space: nowrap;
        // text-overflow: ellipsis;
      }

      .song-tags-wrapper {
        padding: 0px;
        margin-bottom: 5px;
      }

      .song-tag {
        margin: 0;
        padding: 0 7px;
      }

      .favouriteIcon {
        height: 27px;
        width: 27.5px;
        cursor: pointer;
        position: absolute;
        top: 12.5px;
        right: 12.5px;
      }
    }
  }

  .favPlaylist {
    .section {
      margin-bottom: 34px !important;
    }

    .waveform-preview-modal {
      margin-top: 0px;
    }

    .playlist {
      width: 240px;
      min-height: 305px;
    }

    .playlistCover {
      margin-bottom: 0px;
      height: 240px;
      width: 240px;
    }

    .playlistInfoContainer {
      min-height: 65px;
      background-color: $componentBackground;
      padding: 13.5px;

      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #272727;
    }

    .playlistInfo h3 {
      margin-bottom: 5px;
    }

    .playlistInfo aside {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #737373;
    }

    .playlistArt {
      margin-bottom: 0px;
    }
  }

  .favFiles {
    .create-hero {
      margin-bottom: 21px !important;
    }
    .section {
      margin-bottom: 22px !important;
      margin-top: 0px !important;
    }
  }

  .favSession {
    .create-hero {
      margin-bottom: 21px !important;
    }

    .section {
      margin-bottom: 22px !important;
      margin-top: 0px !important;
    }
  }
}
