.song-detail-credit {
  td:nth-child(1) {
    width: 4%;
  }
  td:nth-child(2) {
    width: 28%;
  }
  td:nth-child(3),
  td:nth-child(4) {
    width: 16.5%;
  }
  td:nth-child(5) {
    width: 33%;
  }
  td:nth-child(6) {
    width: 2%;
  }
}

.song-detail-file {
  td:nth-child(1),
  td:nth-child(2) {
    width: 4%;
    min-width: 57px;
    text-align: center;
  }
  td:nth-child(3) {
    width: 24%;
  }
  td:nth-child(4),
  td:nth-child(5),
  td:nth-child(6) {
    width: 16.5%;
  }
  td:nth-child(7) {
    width: 12.5%;
  }
  td:nth-child(8),
  td:nth-child(9) {
    width: 3%;
  }

  @include max-screen(1024px) {
    td:nth-child(2) {
      width: 6%;
    }
    td:nth-child(3) {
      width: 23.5%;
    }
  }
}
