.billing-information {
  .bx--breadcrumb-item {
    font-size: 25px;

    .bx--link {
      font: 500 25px/1.4 "Poppins", "Helvetica Neue", "Helvetica", Arial,
        sans-serif;
    }
  }
  .bx--breadcrumb-item::after {
    color: $white;
    font-weight: normal;
  }
  .invoices {
    #spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .bx--overflow-menu__icon {
      fill: $white;
    }
    .bx--overflow-menu__icon:hover {
      fill: $white;
    }
    .menu-item {
      color: $white;
    }
    .menu-item:hover {
      background: #0d0d0d;
    }
    .bx--overflow-menu-options,
    .bx--overflow-menu-options-open,
    .menu-item {
      background: #0d0d0d;
      border-color: #0d0d0d;
      width: fit-content;
    }
    .bx--overflow-menu-options::before {
      background: #0d0d0d;
      border-color: #0d0d0d;
      right: 21px;
      left: auto !important;
    }
    .sectionHeader {
      border-bottom: 1px solid $accent;
    }
    h2 {
      font-size: 20px !important;
      font-weight: normal !important;
    }
  }
  .invoice-wrapper {
    background: #0d0d0d;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .invoice-header {
    background: #0d0d0d;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-items: center;
    justify-content: space-between;
    padding: 10px;

    .header-last-item {
      display: flex;
      flex-direction: row;
      flex-basis: 18%;
      justify-content: space-between;
    }
  }
  .item-heading {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    color: #b3b3b3;
    font-size: 12px;
    line-height: 18px;
  }
  .item-description {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .invoice-description {
    padding: 10px;
    word-break: break-all;
    .item-description-text {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
    }
  }
}
.profile-img {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  object-fit: contain;
}
.profile-img-wrapper {
  display: flex;
}
.profile-actions-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 20px;
  .dpi-action-container {
    height: 20px;
    position: relative;
    .action-btn-loading {
      position: absolute;
      margin: 0;
      padding: 0;
      left: -8px;
      top: -5px;
    }
    .dpi-action {
      height: 20px;
      cursor: pointer;
    }

    &:nth-child(2) {
      margin-top: 18px;
    }
  }
}

// Billing Information

.invoices {

  width: calc(100vw - 220px);

  .billing-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 35.62px;

    padding-bottom: 17px;
    border-bottom: 1px solid #d5743e;
    margin-bottom: 21px;

    .title {
      margin: 0px;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }
  }

  .expanded-row {
    height: 116px;
    // padding-top:10px;
  }
  .expanded-content {
    .row-header {
      padding-top: 10px;
    }
  }
}
