#spinner {
  @media (max-width: 800px) {
    display: none;
  }
}
.black-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: black;
  padding: 100px;
  gap: 60px;
  > p {
    color: white;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
  }

  .store-link {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
    > img {
      cursor: pointer;
      width: 153px;
      height: 50px;
    }
  }

  @media (max-width: 425px) {
    padding: 49px;
  }

  @media (max-width: 380px) {
    padding: 24px;
  }
}
