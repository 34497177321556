.audio-preview-modal {
  .error-message {
    font-family: $poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: $white;
  }
  .bx--modal {
    transform: translateY(100%);
    transition: 0.3s ease;
    background: transparent;
  }

  .bx--modal-content {
    margin-bottom: 0px;
  }

  .bx--modal-container {
    position: absolute;
    bottom: 0;
    padding: 4px 34px;

    border-top: transparent;
    max-width: 100%;
    height: 140px;
    transition: height 200ms ease;
    width: 100%;
    border-top: 1px solid $accent;

    .bx--modal-header {
      display: none;
    }
  }

  .bx--modal.is-visible {
    transform: translateY(0);
    transition: 0.3s ease;
  }

  .bx--modal-close {
    display: none;
  }

  .wrapper-container {
    display: flex;
  }

  .waveform-container {
    width: 100%;
  }
}

.waveform-public-playlist {
  .bx--modal-container {
    border-top: 1px solid $accent;
    padding-top: 10px;
  }
  .bx--modal {
    transform: translateY(100%);
    transition: 0.3s ease;
    background: transparent;
  }

  .bx--modal-close,
  .bx--modal-header {
    display: none;
  }

  .show-hide {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    &-toggle {
      cursor: pointer;
      padding: 3px;
    }
  }
  .comments-toggle-icon {
    display: none;
  }
}

.audio-preview-modal-waveform {
  .bx--modal-container {
    // height: 240px;
    transition: height 200ms ease;
  }
}

.audio-player-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 18px;
  margin-top: 8px;
  .show-hide {
    display: flex;
    justify-content: center;
    &-toggle {
      cursor: pointer;
      padding: 3px;
    }
  }
}

.audio-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  @media (max-width:425px){
    flex-direction: column;
   }
  .preloader-inner {
    height: 150px;
    text-align: center;
    font-size: 3em;
    font-family: monospace;
    font-weight: 300;
    color: #333;
  }

  //audio control icons
  .icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-size: 14px 10px;
    background-repeat: no-repeat;
    background-position-x: 40%;
    background-position-y: center;
    background-color: #0d0d0d;
    border-radius: 100%;
  }

  .rewind-icon {
    margin-top: 2px;
    background-image: url("assets/waveform/rewind.svg");
  }

  .forward-icon {
    background-image: url("assets/waveform/forward.svg");
    background-position-x: 53%;
  }

  .pause-icon {
    width: 38px;
    height: 38px;
    background-size: 18px 14px;
    background-image: url("assets/waveform/pause.svg");
    background-position-x: 51%;
  }

  .play-icon {
    background-image: url("assets/waveform/play.svg");
    background-position-x: 56%;
    background-size: 18px 14px;
    width: 38px;
    height: 38px;
  }


  .rewind-icon:hover {
    cursor: pointer;

    background-image: url("assets/waveform/rewindActive.svg");
  }

  .forward-icon:hover {
    cursor: pointer;

    background-image: url("assets/waveform/forwardActive.svg");
  }

  .pause-icon:hover {
    cursor: pointer;

    background-image: url("assets/waveform/pauseActive.svg");
  }

  .play-icon:hover {
    background-image: url("assets/waveform/playActive.svg");

    cursor: pointer;
  }

  .actions-container {
    display: flex;
    color: white;
    width: 100%;

    .media-details-wrapper {
      display: flex;
      color: white;
      justify-content: space-between;
      align-content: center;
      padding-top: 14px;
      align-items: center;
      width: 100%;
    }

    .media-details {
      display: flex;
      align-items: center;
    }

    .media-name {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      padding: 5px 15px;
      color: $accent;
      white-space: nowrap;
      overflow: hidden;
      width: 300px;
      text-overflow: ellipsis;
    }

    .media-extension {
      width: 27px;
      height: 15px;
      background: $white;
      color: black;
      font-size: 10px;
      font-weight: 400;
      padding: 2px;
      padding-top: 3px;
      padding-left: 3px;
      margin-right: 5px;
      border-radius: 3px;
    }
  }

  .media-size .date {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }

  .audio-controls-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 120px;
    margin-right: 22px;
    @media (max-width:425px){
      margin-right: 0px;
    }
  }

  .user-avatar-wrapper {
    height: 65px;
    position: absolute;
    margin-top: -59px;
    z-index: 10;
    width: 100%;
    background-color: #1f1f1f;
  }

  .song-title {
    color: $accent !important;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    width: 500px;
    text-overflow: ellipsis;
    @media (max-width:425px){
      width: 300px;
    }
  }

  .audio-player-container {
    width: 100%;
    // background-color: green;

    .audio-player {
      width: 100%;
      height: 30px;
      display: none;
    }

    .song-time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 16px;
      p {
        font-weight: 500;
        font-size: 20px !important;
        line-height: 30px;
        letter-spacing: inherit I !important;
      }
      &-played {
        color: $accent;
      }
    }

    .song-date {
      display: flex;
      justify-content: flex-end;
      padding: 0px 16px;

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
      }
    }

    audio::-webkit-media-controls-play-button,
    audio::-webkit-media-controls-current-time-display,
    audio::-webkit-media-controls-time-remaining-display,
    audio::-webkit-media-controls-mute-button,
    audio::-webkit-media-controls-volume-control-container {
      display: none;
    }
    audio::-webkit-media-controls,
    audio::-webkit-media-controls-timeline {
      color: $accent;
    }

    audio::-webkit-media-controls-enclosure {
      background-color: transparent;
    }

    audio::-internal-media-controls-overflow {
      display: none;
    }

    audio::-webkit-media-controls-panel {
      padding: 0;
    }
  }
}
.public-play-icon{
  margin-top: 19px;
  height:38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width:768px){
    width: 75px;
    height: 75px;
  }
}
.timelineContainer {
  padding: 5px 20px 10px 18px;
}

.timeline {
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  background-color: $grey-lighter;
  border-radius: 2px;
  background-size: 0% 100%;
  background-image: linear-gradient($accent, $accent);
  background-repeat: no-repeat;
  cursor: pointer;
}

.timeline::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  // opacity: 0;
  // transition: all 0.1s;
  background-color: $accent;
}

.timeline::-webkit-slider-thumb:hover {
  background-color: $accent;
}

.timeline:hover::-webkit-slider-thumb {
  opacity: 1;
}

.timeline::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}


.audio-wrapper {
  .audio-player-container {
    .song-time {
      p{
        font-weight: 400;
        font-size: 12px !important;
        line-height: 18px;
      }
    }
  }
}