.main {
  margin: 0 auto 0 80px;
  width:100%;
  min-height: 100vh;
  @include min-screen(1024px) {
    width: calc(100%);
  }

  .mainContainer {
    margin: 0 auto 0 51px;
    max-width: calc(100vw - 200px);
    padding-top: 30px;
    width: calc(100% - 30px);
    @include min-screen(768px) {
      padding-top: 60px;
    }

    @include min-screen(1024px) {
      width: calc(100% - 80px);
    }
  }
  .sections-container {
    margin: 0 auto 0 51px;
    max-width: calc(100vw - 200px);
    width: calc(100% - 30px);
    .breadcrumb{
      padding-top: 25px;
    }
    @include min-screen(1024px) {
      width: calc(100% - 80px);
    }
  }
}

.appBody {
  padding-top: 65px;
}

// Dropdwon Component SCSS
.dropdown-container {
  position: relative;

  .dropDown-Btn {
    cursor: pointer;
    display: inline;

    .dropdown-icon {
      display: inline-block;
      width: 20px;
      height: 19px;
      margin: 0px 5px 0px 15px;
    }
  }

  .arrow-1 {
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 22px solid #272727;
    position: absolute;
    left: 15px;
    top: 22px;

  }

  .arrow-2 {
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 18px solid #0D0D0D;
    position: absolute;
    left: 17px;
    top: 25px;
    z-index: 10;
  }

  .dropdown-menu {
    z-index: 5;
    position: absolute;
    min-width: max-content;
    top: 40px;
    left: -9px;
    padding: 10px 30px 10px 10px;
    display: inline-block;
    background-color: #0D0D0D;
    border: 1px solid #272727;
    color: #fff;

    .dropdown-menu-item {
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      cursor: pointer;
      margin: 10px 0px 15px 10px;

      .dropdown-item-img {
        display: inline-block;
        width: 18px;
        height: auto;
        margin-right: 20px;
      }
    }
  }
}
