.song {
  position: relative;
  border: 1px solid #272727;
  margin-bottom: 42px;
  background-color: $componentBackground;
  height: fit-content;

  .songArt {
    display: block;
    height: 0;
    margin-bottom: 10px;
    padding-top: 100%;
    position: relative;
    width: 100%;

    img {
      height: 100%;
      left: 0;
      object-fit: cover;
      object-position: center center;
      position: absolute;
      top: 0;
      width: 100%;
    }

    a {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .songCover {
    display: block;
    height: 0;
    margin-bottom: 10px;
    padding-top: 100%;
    position: relative;
    width: 100%;

    span {
      color: $white;
      display: block;
      font: 700 30px/1.4 $poppins;
      left: 50%;
      max-width: 100%;
      padding: 0 10px;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);

      @include min-screen(1200px) {
        font-size: (30px/1200px) * 100vw;
      }

      @include min-screen(1400px) {
        font-size: 38px;
      }
    }

    a {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .songInfoContainer {
    display: flex;
    justify-content: space-between;
  }

  .songInfo {
    width: calc(100% - 20px);

    h3 {
      color: $white;
      font: 700 14px/1.2 $poppins;
      letter-spacing: letter-spacing(25, 14px);
      margin-bottom: 10px;
    }
    .song-tile-title{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      padding: 10px;
    }



    aside {
      color: #bbbbbb;
      font: 500 13px/1.4 $poppins;

      span {
        color: $white;

        a {
          color: $accent;
          text-decoration: none;
        }
      }
    }
  }

  .bx--overflow-menu--flip {

    left: -160px;

    &:before {
      left: 165px;
    }
  }
}

.songs-tiles{
  .bx--overflow-menu{
    margin-top: 30px;
    margin-right: 25px;
  }
  .bx--overflow-menu__icon {
    -webkit-transform: rotate(0deg) !important;
    transform: rotate(0deg) !important;
fill: $white;
&:hover{
  fill: $white;
}
&:focus{
  fill: $white;
}
  }
 @include max-screen (720px){
  .section{
    margin-right: 80px;
  }
 }
 .see-more{
  font-family: $poppins;
  color: $accent;
  font-size: 8px;
  cursor: pointer;
  margin-top: 10px;
    }
    .wrapper{
      display: flex;
    }
 .tags-wrapper{
  max-width: 300px;
  padding: 7px 0 21px 0;
  margin-left: 0px;

  .tag{
width: 64px;
height: 16px;
font-family: $poppins;
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 15px;
color: #B3B3B3;
border: 1px solid #B3B3B3;
padding:3px 7px;
margin: 0 10px;
border-radius: 20px;

  }
}
}

.songTileContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;

  .song {
    max-width: 240px !important;
  }

  .songCover, .songArt{
    width: 240px !important
  }

} 

.song-detail {
  .tags-wrapper{
    margin-left: 18px;
  }
  .bx--search-input {
    width: 292px;
    height: 36px;
  }
  .create-hero {
    h4 {
      margin-top:13.5px !important;
    }
  }
  .breadcrumb {
    padding-top: 0px !important;
    margin-top: 18px;
    .parent-wrapper {
      .title-wrapper {
        .sup-hero-icon {
          width: 11px;
          margin-left: 3px;
          cursor: pointer;
        }
      }
    }
    .bx--breadcrumb-item {
      &:last-of-type {
        .bx--link {
          color: $white !important;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px
        }
      }
    }
  }
  .song-detail-header {
    font-weight: 600 !important;
    font-size: 38px !important;
    line-height: 57px !important;
    height: 57px;
    overflow: hidden;
  }
  .vertical-line {
    color: #d5743e !important;
    padding-left: 18px !important;
  }
  .bx--search-magnifier{
    fill: $black-100;
  }
  .content-wrapper {
    background: #0D0D0D;
  }

  .song-files{
    .section {
      margin-top: 20px;
    }
    .actions-header {
      margin-top: 3px;
      height: 57px !important;
    }
    margin: 0px auto 0px 50px !important;
    .itemSave {
      svg {
        fill: #000000 !important;
      }
    }
  }
  .song-credits {
    .section {
      margin-top: 25px;
    }
  }
  .song-credits-section {
    .create-hero {
      margin-bottom: 23px;
    }
  }

  .create-header-link {
    margin-left: 22px !important;
  }
  .hero-edit-button {
    margin-left: 27px;
  }
}
.uppy-Dashboard-AddFiles-title {
  color: $white !important;
}

.song-section-infoGrid {
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
  margin-top: 35px;
  grid-gap: 35px 20px !important;
  padding-left: 0px !important;

  .infoText {

    .infoTextTitle {
      font: 500 12px/1.4 $poppins;
      line-height: 18px;
      color: #B3B3B3;
    }

    .infoTextContent {
      font: 400 16px/1.4 $poppins;
      line-height: 24px;
    }

    .infoTextContent-secondary {
      font: 400 14px/1.4 $poppins !important;
      line-height: 21px !important;
    }
  }
}

.song-section-container {
  max-width: 100% !important;
  margin: 0px !important;
}

// Dropdpwn menu for files on song Files header

.uploadFileDropdown {
  cursor: pointer;
  border: 1px solid #272727;
  background: $componentBackground;
  width: 124px;
  height: 88px;
  display: inline-block;
  position: relative;
  margin-top: -20px;
  z-index: 5;
  padding: 10px 30px 10px 12px;
  left: 180px;
  color: #fff;
  .nav-link {
    font-size: 14px;
    line-height: 21px;
    display: flex;
    padding-bottom: 17px;
    .upload-icon {
      width: 16px;
      height: 20px;
      padding-left: 2px;
    }
    .files-icon {
      width: 20px;
      height: 16px;
    }
    .upload-text {
      margin-left: 13px;
    }
    .files-text {
      margin-left: 11px;
    }
  }
}
.uploadFileDropdownDiv {
  position: absolute;
  .arrow-1 {
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 22px solid $componentBackground;
    position: absolute;
    left: 186px;
    top: -34px;
    z-index: 10;

  }

  .arrow-2 {
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 18px solid #272727;
    position: absolute;
    left: 188px;
    top: -36px;
  }
}



// Hide Secondary buttons of export modal

.export-details-modal{
  .bx--btn--secondary{
    display: none;
  }
}


// Css of song detail page
.main.song-detail{
  .create-hero{
   > .sections-container{
      margin-left: 8px;
      max-width: 100%;
    }
  }
  .infoText:last-child {
    margin-top:6px;
  }

  .sections-container{
    .section{
      .bx--data-table-v2-container{
        .bx--data-table-v2{
          tbody{
            tr{
              td{
                .link{
                  padding-left: 0px !important;
                }
              }
            }
          }
          .bx--expandable-row-v2{
            // .infoGrid{
            //   display: flex;
            //   justify-content: space-between;
            //   padding: 0px 60px 0px 43px;
            //   .infoText:nth-child(2){
            //     width: 15%;
            //     @media (max-width:1200px) {
            //       width: 13%;

            //     }
            //   }
            // }
            .bx--table-expand-v2{
              padding-left: 20px;
            }
          }
        }
      }
    }
    .info-grid-wrapper{
      margin-bottom: 41px;
    }
  }
  tr.bx--expandable-row-v2.expanded-content > td:last-child{
    padding-left: 16.7% !important;
  }
  .section{
    margin-bottom: 22px;
   .create-hero {
    margin-bottom: 20px;
   }
  }
  .bx--search-input{
    background-color: #1F1F1F !important;
  }
  .header-action{
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;
  }
  .bx--overflow-menu-options{
    background-color: $componentBackground;
    border: 1px solid #272727;
    color: #fff;
    right: -20px;
    left: auto;
    .bx--overflow-menu-options__option{
      background:none;
      .bx--overflow-menu-options__btn{
        opacity: 1 !important;
        color: #fff;
      }
      .bx--overflow-menu-options__btn:focus{
        background: none  !important;
      }
    }

  }
  .bx--overflow-menu-options::before{
    right: 21px;
    left: auto !important;
    content: none;
  }
  .song-detail-header{
    margin-bottom: 5px !important;
    max-width: 1073px !important;
  }
}

.SongHeroSection {
  .song-HeroContent{
    padding:30px 0px 29px 40px;

  }
  .songHeroContent {
    // max-width: 1440px;
    .header {
      background: #0D0D0D;
      padding-top: 13.5px;
      padding-bottom: 13.5px;
      padding-left: 40px
    }
    .content{
      padding: 15px 0px 28px 40px;
    }
    .songHeroItem {
      background: #0D0D0D !important;
      border: 1px solid #B3B3B3 !important;
      border-radius: 4px !important;
      margin-top: 20px !important;
      margin-right: 13px !important;
    }
    .songHeroItem:hover {
      background: #0D0D0D !important;
    }
    .songHeroExport, .songHeroExport:hover {
      background: $accent !important;
      border: none !important;
    }
    .songHeroExport {
      .hero-icon {
        margin-right: 11px !important;
      }
    }
    .sound-exchange {
      margin-top: 20px !important;
    }
    .itemSave {
      margin-top: 20px !important;
    }
  }
    .songHeroBody {
      margin-left: 30px !important;
      .songHeroDesription {
        max-width: 90%;
        height: 63px;
        display: block;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        .moreDescriptionBtn {
          cursor: pointer;
          color: #d5743e;
        }
      }
      .song-HeroLinks {
        margin-top: 28px;
        .itemSave {
          svg {
            fill: #000000 !important;
          }
        }
      }
      .songHeroLinks {
        button {
          margin-right: 13px !important;
        }
        .songHeroItem {
          img {
            margin-right: 11px !important;
          }
        }
      }
      .itemSave{
        border: 1px solid $black-100 !important;
      }
    }

    div.btn-group {
      display: flex;
      .songHeroItem {
        margin-right: 35px !important;
        padding: 9px !important;
        border: 1px solid #B3B3B3 !important;
        border-radius: 4px !important;
        .hero-icon{
          margin-right: 8px !important;
        }
      }
    }
    .export-integrations-wrapper{
      background: #000000;
      .header {
        font-size: 20px !important;
        font-weight: 500;
      }
      .export-text {
        padding-left: 0px;
      }
    }
  }

  .songTile-favorites {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .songs-file-page{
    .heroContent{
      margin-bottom: 0px;
      padding: 0px;
      margin-top: 8px;
      .actions-wrapper{
        margin-bottom: 30px;
      }
      .title-wrapper{
        margin-bottom: 19px;
      }
    }
  }


  // Create New Song Modal - Start

  .create-song-modal{
    max-height: none;
    z-index: 1000000000000 !important;
    .bx--modal-container{
      max-width: 550px;
    }
  }