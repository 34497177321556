.section {
  margin-bottom: 80px;

  @include min-screen(1024px) {
    margin-bottom: 120px;
  }
 

  .sectionHeader {
    border-bottom: 1px solid #4e4e4e;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding-bottom: 10px;

    a {
      margin-left: auto;
      width: auto;
    }
    .customization {
      width: 100%;
      justify-content: space-between;
    }

    .customization {
      width: 100%;
      justify-content: space-between;
    }

    .sectionHeaderWrapper {
      align-items: center;
      display: flex;
      margin-bottom: 20px;

      @include min-screen(768px) {
        font-size: 40px;
      }

      @include min-screen(1024px) {
        flex-wrap: nowrap;
        max-width: unset;
      }

      h2 {
        color: $white;
        font: 700 22px/1.4 $poppins;
        margin-right: 23px;

        @include min-screen(768px) {
          font-size: 34px;
        }

        @include min-screen(1024px) {
          margin-bottom: 0px;
        }
      }

      .sectionHeaderButton {
        background-color: $btnbackground;
        border-radius: 6px;
        border: 1px solid #4e4e4e;
        color: $accent;
        font: 500 16px/25px $poppins;
        height: 45px;
        min-width: 150px;
        transition: background 300ms ease, border-color 300ms ease, color 300ms ease;

        &:hover {
          background: $white;
          border-color: $white;
          color: $btnbackground;
        }
      }
      .customizationButton {
        background-color: $btnbackground;
        border-radius: 4px;
        border: 1px solid #4e4e4e;
        color: $white;
        font: 500 16px/25px $poppins;
        height: 40px;
        min-width: 140px;
        transition: background 300ms ease, border-color 300ms ease, color 300ms ease;
    
        &:hover {
          background: $accent;
          border-color: $accent;
          color: $white;
        }
    }
      .sectionHeaderButtonAdd {
        background-color: transparent;
        border: none;
        outline: none;
        border-radius: 6px;
        margin-bottom: 5px;
        img {
          height: 22px;
          min-width: 22px;
        }
      }

      .sectionHeaderButton + .sectionHeaderButton {
        margin-left: 23px;
      }
    }
 
    .sectionHeaderLink {
      background: none;
      border: none;
      color: $accent;
      font: 500 14px/1.4 $poppins;
      padding: 0;
      text-align: right;
      text-decoration: none;
    }

    .sectionHeaderActions {
      align-self: flex-end;
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }

    .bx--overflow-menu {
      margin-left: 10px;
    }

    .sectionHeaderText {
      color: $accent;
      font: 500 14px/1.4 $poppins;
      padding: 0;
      text-align: right;
    }
  }
}
.songs-table{
  .ghosted{
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}
.songs-tiles{
  @include max-screen(768px){
   .section{
     margin-right: 30px;
   }
  }
  @include max-screen(425px){
    .section{
      margin-right: 30px;
    }
   }
 }
