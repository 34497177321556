.dashboardPage {
  .hero {
    .heroContent {
      margin-top: 0px;
      margin-bottom: 0px;
      height: 120px;
      .title-wrapper h1 {
        font-weight: 700;
        font-size: 34px;
        line-height: 51px;
        letter-spacing: 0px;
      }
    }
    .separator {
      display: none;
    }
  }

  .storageContainer {
    padding-top: 41px !important;
  }

  .dashboardSection {
    .grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 35px 23px;
    }

    .section {
      margin-bottom: 33px !important;
    }

    .create-hero {
      margin-bottom: 34px;
    }
  }

  .dashboardProject {
    .section {
      margin-bottom: 34px !important;
    }

    .project {
      position: relative;
      width: 240px;
      margin-bottom: 0px;

      .projectCover {
        margin-bottom: 0px;
      }

      .projectArt {
        margin-bottom: 0px;
      }

      .tags-wrapper {
        margin-left: 0px;
      }

      .projectInfo {
        width: 100%;
        padding: 10px;
        border-width: 0px 1px 1px 1px;
      }

      .project-tile-title {
        padding: 0px;

        overflow: hidden;
        width: 210px;
        // white-space: nowrap;
        // text-overflow: ellipsis;

        aside {
          margin-top: 5px;
          margin-bottom: 11px;
        }
      }

      .favouriteIcon {
        height: 27px;
        width: 27.5px;
        cursor: pointer;
        position: absolute;
        top: 12.5px;
        right: 12.5px;
      }
    }
  }

  .dashboardSong {
    .song {
      width: 240px;

      position: relative;
      width: 240px;
      margin-bottom: 0px;

      .songCover {
        margin-bottom: 0px;
      }

      .songArt {
        margin-bottom: 0px;
      }

      .tags-wrapper {
        margin-left: 0px;
      }

      .songInfo {
        width: 100%;
        padding: 10px;
        border-width: 0px 1px 1px 1px;
      }

      .song-tile-title {
        padding: 0px;
        margin-bottom: 11px;

        overflow: hidden;
        // width: 210px;
        // white-space: nowrap;
        // text-overflow: ellipsis;
      }

      .song-tags-wrapper {
        padding: 0px;
        margin-bottom: 5px;
      }

      .song-tag {
        margin: 0;
        padding: 0 7px;
      }

      .favouriteIcon {
        height: 27px;
        width: 27.5px;
        cursor: pointer;
        position: absolute;
        top: 12.5px;
        right: 12.5px;
      }
    }
  }

  .dashboardPlaylist {
    .section {
      margin-bottom: 34px !important;
    }

    .waveform-preview-modal {
      margin-top: 0px;
    }

    .playlist {
      width: 240px;
      min-height: 305px;
      border: 0px;
    }

    .playlistCover {
      margin-bottom: 0px;
      height: 240px;
      width: 240px;
    }

    .playlistInfoContainer {
      min-height: 65px;
      background-color: $componentBackground;
      padding: 13.5px;

      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #272727;
    }

    .playlistInfo h3 {
      margin-bottom: 5px;
    }

    .playlistInfo aside {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #737373;
    }

    .playlistArt {
      margin-bottom: 0px;
    }
  }

  .dashboardFiles {
    .create-hero {
      margin-bottom: 21px !important;
    }

    .itemSave {
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}
