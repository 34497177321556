
.empty-table {
  background-color: $componentBackground;
  border-bottom: solid 1px $grey;
  padding: 3em 7%;
  text-align: center;

  .heading {
    margin-bottom: .5em;
  }
  .children {
    color: $grey-lighter;
  }
}
