.waveform-preview-modal {
  .bx--modal {
    transform: translateY(100%);
    transition: 0.3s ease;
    background: transparent;
  }
  .bx--modal-content{
      margin-bottom: 0px;
  }
  .bx--modal-container {
    position: absolute;
    bottom: 0;
    padding-left: 26px;
    padding-right: 26px;

    border-top: transparent;
    max-width: 100%;
    height: 262px;
    transition: height 200ms ease;
    width: 100%;
    background-color: $appSearchBackground !important;
  }
  .bx--modal.is-visible {
    transform: translateY(0);
    transition: 0.3s ease;
    }
  .bx--modal-close {
    top: 20px !important;
    left: 24px !important;
    width:50px;

    outline: none !important;
    &:after {
      display: none;
    }
    svg{
      display:block !important;
    }
  }
  .bx--modal-close__icon {
    display: block;
    fill: $white;
    width: 15px;
    height: 15px;
  }
  .wrapper-container {
    display: flex;
  }
  .waveform-container {
    width: 100%;
  }
  .comments-toggle-icon {
    position: absolute;
    top: 20px;
    right: 24px;
    cursor: pointer;
  }
  .comments-section {
    width: 0px;
  }
  .comments-drawer {
    width: 383px;
    height: 262px;
    position: absolute;
    border-left: 2px solid #0d0d0d;
    right: 0;
    bottom: 0;
    background: $componentBackground;
  }
  .comment-wrapper {
    padding-top: 10px;
    padding-bottom: 10px;
    // padding: 10px;
    height: 199px;
    overflow-x: hidden;
    overflow-y: auto;
    border-bottom: 2px solid #0d0d0d;
    padding-top: 0px;
    margin-top: 10px;
    .comment {
      padding-left: 10px;
      padding-right: 10px;
    }
    // .comment-to-edit {
    //   background-color: rgba($color: $orage, $alpha: 0.2);
    // }
    .selected-comment {
      background-color: rgba($accent, 0.7);
    }
  }
  .comment-header {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
  }
  .user {
    display: flex;
    &-name {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      padding-left: 10px;
    }
    &-avatar {
      height: 20px;
      width: 20px;
      text-align: center;
      font-size: 11px;
      border-radius: 100%;

    }

  }
  .actions {
    display: flex;
    align-items: center;
    .delete-comment-loading {
      margin-left: -7px;
      margin-right: 5px;
      height: 25px;
      width: 25px;
    }
    .trash-icon {
      width: 10px;
      height: 13px;
      margin-right: 15px;
      cursor: pointer;
    }
    .time-stamps {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #b3b3b3;
    }
  }
  .comment-description {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .comment-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow:  inset 0 0 6px rgba(0, 0, 0, 0.3);

    background-color: $componentBackground;
  }

  .comment-wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: #b3b3b3;
  }

  .comment-wrapper::-webkit-scrollbar-thumb {
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
  .comment-form {
    display: flex;
    flex-direction: row-reverse;
    padding: 10px;
    align-items: center;
    column-gap: 10px;
    margin-top: 0 !important;
    .bx--text-area {
      min-width: 245px !important;
      // width: 245px !important;
      height: 40px;
      background: #0d0d0d;
      border-radius: 2px;
      box-shadow: none;
      color: $white;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      padding: 10px;
      line-height: 21px;
    }
    input::placeholder {
      color: $white;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
    }
    .add-comment-btn{
        // width:92px;
        // height:40px;
        box-sizing:border-box;
      &:disabled:hover {
        background-color: $primary;
      }
    }
    .bx--text-area::-webkit-scrollbar {
      width: 5px;
      color:$primary;
     }
     .bx--text-area::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: $primary;
      box-shadow:inset 0 0 6px rgba(0,0,0,0.5);
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
     }
    .comment-input{
      padding: 8px 10px;
    }
  }
}
