.password-input {
  position: relative;

  input {
    padding-right: 3rem;
  }
}
.password-icon {
  position: absolute;
  right: .75em;
  top: 2em;
  cursor: pointer;
}
