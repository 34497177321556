.bx--modal {
  background: rgba(#0f0f0f, 0.9);
  z-index: 9999 !important;
  .bx--modal-container {
    background: $appBackground no-repeat ;
    border-top-color: $accent;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li,
    .bx--inline-notification,
    .bx--structured-list-th, .bx--structured-list-td {
      color: $white;
      font-family: $poppins;
    }
    .bx--inline-notification--warning .bx--inline-notification__icon {
      min-width: 40px;
    }
  }
  .bx--structured-list {
    min-width: initial;
  }
  .file-list-in-modal {
    margin-bottom: 3em;

    .bx--structured-list-row.bx--structured-list-row--header-row,
    .bx--structured-list-row {
      border-color: #666;
    }
    .bx--structured-list-th, .bx--structured-list-td {
      padding-top: .7em;
      padding-bottom: .7em;
      line-height: 1.2;
      font-size: .85em;
    }
  }

  .bx--modal-footer {
    background: $appBackground no-repeat;
  }

  .bx--file-filename {
    color: #152935 !important;
  }
}

// Hide double close icon on share playlist modal
.share-playlist-modal {
  .bx--modal-container {
    .bx--modal-header {
      .bx--modal-close {
        > svg {
          display: none;
        }
      }
    }
    .bx--modal-content {
      .bx--radio-button__appearance {
        background-color: #000000;
        border: 1px solid orange;
        width: 20px;
        height: 20px;
      }
      .bx--radio-button__appearance::before {
        width: 12px !important;
        height: 12px !important;  
      }
    }
  }

  .linkIcon {
    margin-top: 24px !important;
  }
}

.share-modal {
  .bx--form-message {
    margin-bottom: 0px !important;
  }
  & .close-button {
    position: absolute;
    top: 20px;
    right: 29px;
    & button {
      background: none !important;
      border: 0;
    }
}
  & .bx--modal-container {
    width: 550px;
    height: 696px;
    padding-top: 30px;
    & .bx--modal-content {
      overflow: hidden !important;
      @media (max-height: 850px){
        overflow-y: scroll !important;
      }
    }
  }

  & .bx--form {
    & .formRow {
      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #B3B3B3;
        padding-bottom: 20px;
      }
      & .drop-helper {

      }
      &__thin{
        margin-bottom: 15px !important;
      }
    }
    margin-top: -10px !important;
  }
  & .two-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 60px !important;
    margin: 0px 0px -3px 0px !important;

    & .formRow {
      align-items: center;
    }
    
    & .bx--form-item {
      align-items: flex-start;
      justify-content: flex-start;
      height: 100px !important;
    }
    & .radio-container {
      display: grid;
      grid-template-rows: 18px 82px;


    }
    & .radio-button-column {

      display: flex;
      gap: 35px;    
      margin-top: 0px;
      padding-top: 8px;

    }
    & .calender-short-width {
    }
  }
  & .bx--text-area {
    height: 105px;
    width: 100% !important;
  }
  #message {
  }
  .link-expiration-text{
    color: #FFFFFF !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
  }
  .copy-link {
    background-color: transparent !important;
  }

  #link:disabled {
    background: #0d0d0d !important;
    opacity: 1;
  }

  .bx--text__input input {
    width: 100% !important;
  }
  .bx--text-input {
    width: 100% !important;
  }
}

.bx--modal-content {
  overflow: hidden;
}

.modal-scrollable .bx--modal-content {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  &:focus, &:hover {
    &::-webkit-scrollbar {
      width: 5px;
      color: $accent;
     }
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      // background: $accent;
      box-shadow:inset 0 0 6px rgba(0,0,0,0.5);
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }
  }
}

.modal-overflow .bx--modal-content {
  overflow: visible;
}

.modal--no-secondary {
  .bx--btn--secondary {
    display: none;
  }
}

.timeZone-modal{
  .bx--modal-close{
    display: none;
  }
}
.move-modal{
  .bx--modal-container{
    min-width: 50%;
  }
  tbody tr:nth-child(even) {
    background: transparent !important;
  }
  tbody tr:nth-child(odd) {
    background: transparent;
  }
  .move-item-name {
    margin: auto 0px auto 30px !important;
  }
  td {
    padding-left: 0px !important;
  }
  .move-radio {
    padding-top: 0px;
    padding-bottom: 2px;
  }
}

.modal-background .bx--modal-container, .modal-background .bx--modal-footer {
  background-color: #000000;
}

.modal-background .bx--modal-content {
  .bx--search-input,
  .bx--text-input,
  .bx--date-picker__input,
  textarea.bx--text-area {
    box-shadow: none;
    width: 95%;
    max-width: 370px;
    padding: 1rem;
  }
}

.modal-background .bx--modal-content {
  .bx--date-picker__input {
    box-shadow: none;
    width: 95%;

    &:focus {
      padding-left: 3rem !important;
    }
  }
}

.share-files.share-modal {
  .bx--modal-header {
    color: #ffffff;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    height: 56px;
    margin-bottom: 0px;
  }
  
  .linkIcon {
      background: #0D0D0D;
      margin-right: 17px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      margin-right: 0px !important;
      transform: translateY(-6px);
  }
  
  .linkIcon:hover {
    cursor: pointer;
  }
  .bx--modal-container{
    padding-left: 46px;
    padding-right: 46px;
    overflow-x: hidden;

  }

  .text-clip {
    & #link:disabled{
      padding-right: 0px !important;
      color: #676767;
      text-overflow: ellipsis;
    }
    .bx--text-input {
      background: #0D0D0D;
    }
  }
  .bx--text-input.bx--text__input{
    &::placeholder {
      color: #FFFFFF !important;
    }
    padding-right: 0px !important;
  }
  .bx--modal-content {
    overflow: hidden;
    margin-bottom: 0px;
  }
  .bx--modal-footer {
    box-sizing: border-box;
    padding-top: 88px;
    margin-top: 0px;

    button {
      margin-bottom: 8px;
      // margin-right: -8px;
    }
  }
  & .close-button {
    position: absolute;
    top: 15px;
    right: 23px;
    & button {
      background: none !important;
      border: 0;
    }
  }

  .modal-background .bx--modal-content {
    .bx--date-picker__input {
      box-shadow: none;
      width: 95%;
    }
  }
  [data-invalid="true"] {
    border-bottom: #CD4136 solid 2px;
  }
  
  .hide-save-button {
    & .bx--btn--primary {
      display: none !important;
    }
    & .bx--btn--secondary {
      background-color: $accent !important;
      border: none !important;
    }
  }

  .link-modal{
    .bx--btn--secondary {
      display: none;
  }
    .bx--modal-container{
      min-width: 700px;
    }
    .link-wrapper{
      display: flex;
      width: 100%;
      .formRow{
        width: 100%;
        input{
          background-color: #141414;
        }
      }
    }
  }
  .link-alert{
    .bx--btn--primary{
      display: none;
    }
    .bx--btn--secondary {
      display: block;
    }
  }
  .bx--radio-button {
    &__label {       
    }
    &__appearance {
      background-color: #000000;
      height: 24px;
      width: 24px;
      box-shadow: none !important;
      &:active {
        box-shadow: none !important;
      }
    }
  }
  .artist-modal{
    .bx--modal-container{
      .bx--form{
        max-width: 546px;
      }
      width: 546px;
    }
  }
  .bx--modal-content {
    .bx--search-input,
    .bx--text-input,
    .bx--date-picker__input,
    textarea.bx--text-area {
      box-shadow: none;
      width: 100% !important;
      max-width: 100% !important;
      padding: 1rem;
    }
  }
  textarea#message {
      height: 105px;
  }
  & .bx--date-picker__input {
    padding-left: 3rem !important;
    &:focus {
      padding-left: 3rem !important;
    }
  }
  & .bx--modal-container {
    width: 550px;
    height: 696px;
    padding-top: 26px;
    & .bx--modal-content {
      overflow: hidden !important;
      @media (max-height: 850px){
        overflow-y: scroll !important;
      }
    }
  }
  & .bx--modal-close {
    display: none;
  }
  & .bx--modal-header__heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin-left: -18px !important;
  }
  & .bx--form {
    & .formRow {
      margin-top: 35px;
      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #B3B3B3;
        padding-bottom: 20px;
      }
      & .drop-helper {
        padding-bottom: 0px !important;
      }
      &__thin{
        margin-bottom: 15px !important;
      }
    }
    margin-top: 0px !important;
  }
  & .two-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 56px !important;
    margin: 30px 0px 31px 0px !important;
    justify-content: space-between;

    & .formRow {
      align-items: center;
    }
    
    & .bx--form-item {
      align-items: flex-start;
      justify-content: flex-start;
      height: 100px !important;
      & .bx--date-picker--single {
        display: flex;
        justify-content: flex-end;
        & .bx--date-picker-container {
          width: 200px;

        }
      }
    }
    & .radio-container {
      display: grid;
      grid-template-rows: 18px 82px;


    }
    & .radio-button-column {

      display: flex;
      gap: 34px;
      margin-top: 0px;
      padding-top: 8px;

    }

  }
  & .bx--text-area {
    height: 109px;
    width: 100% !important;
  }

  .link-expiration-text{
    color: #FFFFFF !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
  }
  .copy-link {
    background-color: transparent !important;
  }

  #link:disabled {
    background: #0d0d0d;
    opacity: 1;
  }

  .bx--text__input input {
    width: 100% !important;
  }
  .bx--text-input {
    width: 100% !important;
  }
}
////////////////////
///End Share Files
/// /////////////////////

.linkIcon {
    background: $componentBackground;
    margin-right: 17px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.linkIcon:hover {
  cursor: pointer;
}
.share-modal{
  .bx--modal-content::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #0e0d0d;
  }

  .bx--modal-content::-webkit-scrollbar
  {
    width: 5px;
    background-color: #0e0d0d;
  }

  .bx--modal-content::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.3);
    box-shadow: inset 0 0 3px rgba(0,0,0,.3);
    background-color: #2b2929;
  }

  .bx--modal-content {
    .bx--search-input,
    .bx--text-input,
    .bx--date-picker__input,
    textarea.bx--text-area {
      box-shadow: none;
      width: 99%;
      max-width: 405px;
      padding: 1rem;
    }
  }
  [data-invalid="true"] {
    border-bottom: #CD4136 solid 2px;
  }
  
  .hide-save-button {
    & .bx--btn--primary {
      display: none !important;
    }
    & .bx--btn--secondary {
      background-color: $accent !important;
      border: none !important;
    }
  
    }
    .linkIcon:hover {
      cursor: pointer;
    }
}

.link-share-mode {
  .bx--modal-container {
    height: 460px !important;
  }
}

.contact-modal {
  .main{
    margin:0;
    width: 100%;
    height: 100%;
  }
  .bx--modal-container {
    height: 533px;
    // min-height: 550px;
    width: 550px;
    padding-top: 30px;
  }


  .bx--modal-content{
    margin-bottom: 0px;
    overflow-y: scroll;
    .mainContainer{
      margin:0;
      padding-top: 0px;
      padding-bottom: 0px;
      // @include min-screen(768px) {
      //   margin:0;
      //   // padding-top: 60px;
      // }
      @media screen and (min-width: 768px){
        width: 100% !important;
      }
      // @media screen and (max-width: 1400px){
      //   padding-top: 20px;
      // }
      // overflow-y: scroll !important;
      & .section {
        // overflow-y: scroll !important;
        margin-bottom: 0px;
        ::-webkit-scrollbar-track
        {
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
          box-shadow:inset 0 0 6px rgba(0,0,0,0.3) ;
          border-radius: 10px;
          background-color: $componentBackground !important;
        }

      }
    }
  }
  .mainContainer::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow:inset 0 0 6px rgba(0,0,0,0.3) ;
    border-radius: 10px;
    background-color: $componentBackground !important;
  }
    .bx--modal-header {
      p {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
      }
    }
    .bx--modal-content::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow:inset 0 0 6px rgba(0,0,0,0.3) ;
    border-radius: 10px;
    // background-color: $componentBackground;
  }

  .bx--modal-content::-webkit-scrollbar
  {
    width: 5px;
    // background-color: $componentBackground;
  }

  .bx--modal-content::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
  }
  .bx--modal-footer {
    display: none;
  }

  .bx--form {
    margin-top: 0px;
  }
  .formRow:first-of-type {
    margin-top: 31px;
  }
  .formRow:nth-last-of-type(2) {
    margin-bottom: 0px;
  }
  .formRow:last-of-type {
    margin-top: 0px;
  }

  .bx--form-item {
    overflow: visible !important;
  }
  .formRow {
    margin-bottom: 30px;
  }
  .formRow.alt-footer {
    height: 74px;
    display: flex;
    // display: none;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    right: 0px;
    bottom: -20px;
    background-color: #000000;
    width: 100%;
    box-sizing: content-box;
    padding-bottom: 20px;
      button {
        margin-right: 30px;

    }
  }
}
.address-modal{
  .main{
    margin:0;
    width: 100%;
    height: 100%;
  }
  .bx--modal-container {
    height: 756px;
    // min-height: 660px;
    width: 550px;
    padding-top: 30px;
  }

  .bx--form {
    margin-top: 31px;
  }
  .formRow:first-of-type {
    margin-top: 31px;
  }

  .bx--modal-content{
    // margin-bottom: 40px;
    // padding-bottom: 150px;
    overflow-y: scroll;
    // margin-bottom: 0px;
    .mainContainer{
      margin:0;
      padding-top: 0px;
      padding-bottom: 65px;
      // @include min-screen(768px) {
      //   margin:0;
      //   // padding-top: 60px;
      // }
      @media screen and (min-width: 768px){
        width: 100% !important;
      }
      // @media screen and (max-width: 1400px){
      //   padding-top: 20px;
      // }
      // overflow-y: scroll !important;
      & .section {
        // overflow-y: scroll !important;
        margin-bottom: 0px;
      }
    }


  }
    .bx--modal-header {
      p {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
      }
    }
    .bx--modal-content::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow:inset 0 0 6px rgba(0,0,0,0.3) ;
    border-radius: 10px;
    // background-color: $componentBackground;
  }

  .bx--modal-content::-webkit-scrollbar
  {
    width: 5px;
    // background-color: $componentBackground;
  }

  .bx--modal-content::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
  }
  .bx--modal-footer {
    display: none;
  }

  .formRow {
    margin-bottom: 30px;
  }
  .formRow.alt-footer {
    height: 74px;
    display: flex;
    // display: none;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    right: 0px;
    bottom: -20px;
    background-color: #000000;
    width: 100%;
    box-sizing: content-box;
    padding-bottom: 20px;
      button {
        margin-right: 30px;

    }
  }
}

// alert List  - start


.alert-list{
  margin-top: 15px;
  ul {
    li{
      margin: 7px 0px;
    }
  }
}

.uploadModal {
  top: auto;
  bottom: 0;
  min-height: 177px;
  background-color: $grey-dark !important;

  .closeIcon {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: 29px;
    cursor: pointer;
  }

  .bx--modal-container {
    max-width: 100%;
    min-width: 200px;
    width: 100%;
    height: 177px;
    border: none;
    padding: 0;
    background-color: $grey-dark !important;
  }
  .bx--modal-content {
    padding: 0;
    margin: 0;
  }

  .uploadTitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }
  .titleContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
  }
  .uploadSubTitle {
    text-align: center;
  }

  .uploadModalContainer {
    position: relative;
  }

  .uploadModalContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap:16px;
    position: absolute;
    top: 11px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .uppy-Dashboard-inner {
    border: none;
    color: white;
    width: 100vw;
    background-color: $grey-dark !important;
  }

  .bx--modal-header {
    padding: 0;
    margin: 0;
  }

  .uppy-Dashboard-browse{
    @extend  .bx--btn ;
    @extend .bx--btn--primary;
    color: white !important;
    position: absolute;
    top: 71px;
    left: 0;
    right: 0;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

}


.uploadsProgress {
  bottom: 0;
  padding-left:40px;
  padding-right: 51px;
  position: fixed;
  text-align: center;
  transition: background-color 200ms ease-in-out;
  width: 100vw;
  max-width: 100%;
  z-index: 10001;
  background-color: $grey-dark;
  height: 95px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;


  transition: height 200ms;
  overflow: hidden;
  &--expanded {
    height:  93.5vh;
    width: 100%;
    align-items: flex-start;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow-y: auto;
  }


  .uploadPercentage {
    flex: 1;
    margin-right: 28px;

    display: flex;
    align-items: center;

    .progressBar {
      width: 100%;
      color: $accentDark !important;
    }

    progress {
      accent-color: $accentDark;
      border-color: transparent;
    }
  }

  .percentage-note {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin-right: 12px;
  }

  .uploadStatus {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-right: 32px;

    &--info {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }
  }

  .uploadActions {
    display: flex;
    flex-direction: row;
    gap: 18.82px;

    img {
      cursor: pointer;
    }
  }
}


.detailedProgress {
  width: 100%;
  &--header {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    margin-bottom: 24.5px;

    .infoContainer {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }

  .closeIcon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      cursor: pointer;
    }
  }

  .expandIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      cursor: pointer;
    }
  }

  .fileProgressContainer {
    display: flex;
    flex-direction: column;
    gap: 19.5px;
  }

  .sectionsContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}

.singleFileProgress {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid $grey;

  .fileTypeBlock {
    background-color: $white;
    border-radius: 3px;
    padding: 3px 11px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    min-width: none;
    color: $black;

    p {
      font-weight: 700;
      font-size: 12px;
      color: $black !important;
    }
  }

  .progressInfo {
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 3.5px;
    margin-right: 24px;

    &--brief {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .progressFile {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .fileName {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }

    .fileSize {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #8A8A8A;
    }
  }

  .progressPercentage {
    display: flex;
    align-items: center;
    gap: 20px;
    .percentage {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }

    .crossIcon {
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }
}

.artist-modal{
  .bx--modal-header{
    margin-bottom: 1px;
  }
  .bx--modal-content{
    margin-bottom: 22px;
  }
    max-height: none;
    z-index: 1000000000000 !important;
  .bx--modal-container{
    .bx--form{
      max-width: 546px;
    }

    width: 546px;
  }
}




//// Start - View Edit File Share Modal /////

.share-view-edit {

  .bx--modal {
    max-height: none;
  }

  .title {
    margin-left: -16px;
  }

  .closeIcon {
    position: absolute;
    top: 14px;
    right: 29px;
    cursor: pointer;
  }

  .bx--text-input::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $dark-grey-color;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 56px;
  }

  .filesViewEdit {
    .bx--modal-container {
      max-width: 550px;
    }
  }

  .bx--modal-container {
    min-width: 550px !important;
    min-width: 550px !important;
    width: 550px !important;
    padding: 26px 46px;
    overflow: auto;


    max-height: 100vh;
    min-height: fit-content;
    height: fit-content;


  }

  .sharedVia {
    .bx--label {
      margin-bottom: 8px;
    }
  }
  .sharedExpiration {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 35px;
  }

  .createdAt {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $accent !important;
  }

  .actionBtnContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0 !important;
    margin-top: 80px;
  }

  .bx--modal-content {
    margin-bottom: 0px;
    height: fit-content;
    max-height: none;
    overflow: visible;
  }

  .bx--form {
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $accent;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: $accent;
    }
  }

  .filesListContainer {
    background-color: $componentBackground;
    height: 159px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 21px;
    padding: 14px;
  }

  .fileName {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #676767 !important;
    word-wrap: break-word;
  }

  .bx--text__input {
    padding: 10px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  .playlistName {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $componentBackground;
    height: 41px;
    padding-left: 16px;
    gap: 12px;

    .name {
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      color: $dark-grey-color;
    }
  }

  .formRow {
    margin-bottom: 35px;
  }

  .bx--radio-button-group {
    display: flex;
    gap: 28px;
    .bx--radio-button__label {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .bx--radio-button__appearance:focus {
    outline: none !important;
  }
  .bx--radio-button__appearance {
    background-color: $componentBackground !important;
    // border-color: $accent;
    height: 20px;
    width: 20px;
    border: 1px solid $accent !important;
  }
  .bx--radio-button:checked
    + .bx--radio-button__label
    .bx--radio-button__appearance {
    border-color: $accent;
  }
  .bx--radio-button:checked
    + .bx--radio-button__label
    .bx--radio-button__appearance:before {
    background-color: $accent;
    width: 14px;
    height: 14px;
  }
  .bx--radio-button:focus {
    + .bx--radio-button__label .bx--radio-button__appearance {
      box-shadow: none;
    }
  }

  .linkRow {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .bx--text-input:disabled {
      opacity: 1;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $dark-grey-color;
    }
  }

  .linkPlaylistIcon {
    background-color: $componentBackground;
    height: 41px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -1px;
    cursor: pointer;

    .icon {
      width: 20px;
      height: 10px;
    }
  }

  .bx--modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0.25rem 0.25rem 0.125rem;
  }

  .bx--modal-close:focus {
    outline: 1px solid #3d70b2;
  }

  .bx--modal-close__icon {
    fill: #5a6872;
  }
}

.playlistViewEdit {
  .bx--modal-container {
    min-width: 550px !important;
    min-width: 550px !important;
    width: 550px !important;
    // max-height: 100vh;
  }
}

/// End  - View Edit File Share Modal ///////


.uploadModal {
  .bx--modal-close{
    display: none;
  }
}



// Public Share Modal - START

.publicShareUploads {
  left: 0;

  &--expanded {
    height: 100vh;
  }

}



// Public Share Modal - END



