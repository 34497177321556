.simple-list-item {
    color: red;
}

.simple-List-Wrapper {
    .bx--accordion__arrow {
      display: none;
    }
    .bx--accordion__title {
      margin: 0;
      font-size: 16px !important;
      font-weight: 500;
    }
    .bx--accordion__item {
      border-top: none;
  
    }
    .bx--accordion__heading {
      padding: 18px 0 10px 0;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 100%;
      width: 100%;
      color: #ffffff;
    }
    .bx--accordion__content {
      padding: 2px 0 5px 0 !important;
      width: min-content;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
    .bx--accordion__item {
      width: 100%;
    }
    .bx--modal.is-visible {
      background: transparent !important;
    }
    .bx--modal .bx--modal-container {
      border-top: none;
      padding: 22px 26px 5px 33px;
      height: 100vh;
      max-height: 100%;
    }
    .bx--modal-header__heading {
      display: none;
    }
    .bx--modal-header .bx--modal-header__label {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 45px;
      color: #ffffff;
    }
    .bx--modal {
      justify-content: flex-end;
      transition: width 2s linear 1s;
    }

    .simple-List-Accordion-Item {
        display: flex;
        justify-content: space-between;
        background: #0d0d0d !important;
        padding: 0 15px;
        margin: 0 0 10px 0;
        height: 41px;
        li {
            display: flex;
            align-items: center;
            button {
            padding: 0px 0px !important;
            }
            p {
            font-size: 16px;
            font-weight: 500;
            padding: 0px 0px !important;
            }
        }
        }
  }
  
.simple-List-Title-Wrapper {
h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 19px;
    margin-top: 22px;
}
}
