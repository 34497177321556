.playlist {
  position: relative;
  // border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #272727;
  .label-container{
    display: flex;
    .dpi-action-container{
      margin-left: 10px;
      margin-top: -3px;
      cursor: pointer;

    }
  }
  .img-label {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: $black-100;
    margin-bottom: 10px;
  }
  .img-dimension-error {
    margin-top: 10px;
    color: $error-color;
  }
  .playlist-image-and-actions-container {
    display: flex;
    flex-direction: row;
    margin-top: 36px;
    .playlist-download-pg-image {
      max-width: 140px;
      max-height: 140px;
      min-height: 70px;
      object-fit: contain;
    }
    .dpi-actions {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      margin-left: 20px;
      .dpi-remove-upload-actions {
        display: flex;
        flex-direction: column;
        .dpi-action-container {
          height: 20px;
          position: relative;
          .action-btn-loading {
            position: absolute;
            margin: 0;
            padding: 0;
            left: -8px;
            top: -5px;
          }
          .dpi-action {
            height: 20px;
            cursor: pointer;
          }

          &:nth-child(2) {
            margin-top: 18px;
          }
        }
      }
    }
  }

  .playlistArt {
    display: block;
    height: 240px;
    margin-bottom: 10px;
    padding-top: 100%;
    position: relative;
    width: 240px;

    img {
      height: 100%;
      left: 0;
      object-fit: cover;
      object-position: center center;
      position: absolute;
      top: 0;
      width: 100%;
    }

    a {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .playlistCover {
    display: block;
    height: 240px;
    margin-bottom: 10px;
    padding-top: 100%;
    position: relative;
    width: 240px;

    span {
      color: $white;
      display: block;
      font: 700 30px/1.4 $poppins;
      left: 50%;
      max-width: 100%;
      padding: 0 10px;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);

      @include min-screen(1200px) {
        font-size: (30px/1200px) * 100vw;
      }

      @include min-screen(1400px) {
        font-size: 38px;
      }
    }

    a {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .playlistInfoContainer {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    width: 240px;
    .dropdown-container {
      position: relative;

      .dropDown-Btn {
        cursor: pointer;
        display: inline;

        .dropdown-icon {
          display: inline-block;
          width: 20px;
          height: 19px;
          margin: 0px 5px 0px 15px;
        }
      }

      .arrow-1 {
        left: 15px;

      }
      .arrow-2 {
        left: 17px;
      }
      .dropdown-menu {
        left: 0px;
        padding: 0px;
        min-width: 127px;
        z-index: 10;

        .dropdown-menu-item {
          padding: 10px 20px;
          padding-right: 20px !important;
          margin: 0px !important;
          min-width: 70px;
        }
        .dropdown-menu-item:hover {
          background: #d5743e;
          background: var(--accent-color);
          color: white;
        }
      }
    }

  }

  .playlistTile-favorites {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .playlistTile-favorites {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .playlistInfo {
    width: calc(100% - 20px);
    .info-container{
      display: flex;
      width: 100%;
    }
    .play-icon{
      height:38px;
      width: 38px;
      display: flex;
      margin-top: 2px;
      cursor: pointer;
  }

    h3 {
      color: $white;
      font: 700 14px/1.2 $poppins;
      letter-spacing: letter-spacing(25, 14px);
      margin-bottom: 10px;
      width: 140px;
      padding-left: 10px;
    }

    aside {
      color: #bbbbbb;
      font: 500 13px/1.4 $poppins;
      padding-left: 10px;

      span {
        color: $white;

        a {
          color: $accent;
          text-decoration: none;
        }
      }
    }
  }

  .bx--overflow-menu--flip {
    left: -160px;

    &:before {
      left: 165px;
    }
  }
}
.playlist-details{
  
  .bx--data-table-v2-container {
    .bx--data-table-v2 {
      border-spacing: 0px 10px;
    }
  }

  .bx--table-toolbar{
    height:40px;
  }
}

.playlist-table-title {
    width: 500px;
      padding: 0 10px;


    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  
    @media only screen and (max-width: 1024px) {
      width: unset;
    }
}

.playlistCover {
  display: block;
  height: 0;
  margin-bottom: 10px;
  padding-top: 100%;
  position: relative;
  width: 100%;

  span {
    color: $white;
    display: block;
    font: 700 30px/1.4 $poppins;
    left: 50%;
    max-width: 100%;
    padding: 0 10px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);

    @include min-screen(1200px) {
      font-size: (30px/1200px) * 100vw;
    }

    @include min-screen(1400px) {
      font-size: 38px;
    }
  }

  a {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
.breadcrumb--playlist{
  .bx--link{
    color:white !important;
  }
}
.playlistCreateContainer {
  padding-top:20px !important;

  .playlistForm {
    max-width: 795px;
  }

  .actionBtnContainer{
    margin-top: 80px !important;
    margin-bottom: 45px;
  }
}
.bx--breadcrumb-item{
  .bx--link{
    color:white !important;
  }
}
.playlist-custom-form{
  max-width: 100% !important;

  .highlightText {
    color: $accent;
  }

}
.playlist-card {
  position: relative;
  .card-image {
    width: 100%;
    height: auto;
  }
  .overlay-button {
    // display: none;
    border: none;
    background-color: transparent;
  }
  .overlay-image {
    // display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 4;
  }
}



.playlistEditContainer {
  padding-top: 0px !important;


  .actionBtnContainer{
    margin-top: 80px;
    
  }
}

.playlistCreateContainer {

  padding-top: 0px !important;
  
  .playlistForm {
    max-width: 795px;
  }

  .actionBtnContainer{
    margin-top: 80px;
    
  }
}

.createPlaylist, .editPlaylist {
  .create-hero {
    margin-bottom: 55px;
  }
}


.playlistTileContainer {
  display: flex;
  flex-direction: row;
  gap:20px;
  flex-wrap: wrap;
}