@import "../Components/components";

.sessionDetail {
  // margin-top: -30px !important;

  .hero {
    height: 120px;
    .hero-button {
      @extend .bx--btn;
      @extend .bx--btn--primary;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      padding: 8px;
      margin-left: 51px;
    }
    .heroContent {
      margin-bottom: 0px;
      padding-top: 9px;
      padding-bottom: 8px;
      margin-top: 0px;
    }
    .separator {
      display: none;
    }

    .breadcrumb {
      margin-top: 0px;
      padding-top: 21px !important;
      max-width: 100%;
      overflow: hidden;
      // word-break: normal;
      flex-wrap: nowrap !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .title-wrapper {
      p {
        max-width: 1150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .commentsPane {
      top: 85px !important
    }
  }

  // Search Bar

  .appSearch {
    width: 292px;
    max-width: 292px;
    height: 40px;
    border-radius: 2px;
    .bx--search-input {
      background: $grey-dark;
      width: 292px;
      max-width: 292px;
    }
    .bx--search-magnifier {
      fill: $black-100 !important;
      width: 18px;
      height: 18px;
      stroke-width: 2px;
    }
    .bx--search-input::placeholder {
      color: $black-100 !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 21px !important;
    }
  }

  .sessionHeader {
    .create-hero {
      margin-bottom: 0px;

      .hero-edit-button {
        margin-left: 11px;

        img {
          width: 18px !important;
          height: 18px !important;
        }
      }
    }
  }

  //

  .infoContainer {
    padding-top: 0px !important;
    .section {
      margin-bottom: -4px;
    }

    .sessionTable {
      width: calc(100vw - 300px);
      max-width: 1177px;
      border-collapse: separate;
      border-spacing: 0px 35px;


      td:nth-child(1){
        width: 45%;
      }

      td:nth-child(3){
        width: 20%;
      }

      .infoCardGroup {
        display: flex;
        flex-direction: row;
        gap: 28px;
      }

      .infoCard {
        display: flex;
        flex-direction: column;
        width: fit-content;
        min-width: 160px;

        .infoTitle {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: $greyFieldTitle;
        }
        .infoContent {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  .sessionRecordings {

    .bx--data-table-v2, .bx--data-table-v2-container {
      max-width: calc(100vw - 200px) !important;
    }
  
    .content-wrapper {
      max-width: calc(100vw - 120px) !important;
  
    }

    .row-value-name{
      min-width: 10px;
      @include max-screen(1024px) {
        min-width:10px;
      
      }
     
    }
    .create-hero {
      margin-bottom: 23px;
      overflow: visible;
    }

    .section {
      margin-bottom: 22px;
    }

    .dropdown-container {
      margin-left: 46px;
      .dropdown-menu {
        left: -5px;
      }

      .arrow-1 {
        left: 14px;
      }
      .arrow-2 {
        left: 16px;
      }
    }


    .recording-table td:nth-child(6) {
      // max-width: 80px !important;
      width: 30px !important;
      min-width: none;
    }


  }
  .sessionCredits {
   

    .bx--data-table-v2, .bx--data-table-v2-container {
      max-width: calc(100vw - 200px) !important;
    }
  
    .content-wrapper {
      max-width: calc(100vw - 120px) !important;
  
    }

    .create-hero {
      margin-bottom: 23px;
    }
    .section {
      margin-bottom: 35px;
    }
    .hero-edit-button {
      margin-left: 40px;
    }
  }


  .tags-wrapper {
    margin-left: 17px;
  }

  .commentsPane {
    top: 85px !important;
  }

}

.modal--check-in {
  .bx--btn--secondary {
    display: none;
  }
}

.project-seesion {
  .mainContainer {
    padding-top: 0px !important;
  }
}
