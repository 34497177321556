@mixin includeFont(
  $fontFamilyName,
  $fileName,
  $weight: normal,
  $style: normal
) {
  @font-face {
    font-family: $fontFamilyName;
    font-weight: $weight;
    font-style: $style;
    //IE9 Compatibility Mode
    src: url("./fonts/#{$fileName}.eot");
    //IE6-IE8
    src: url("./fonts/#{$fileName}.eot?#iefix") format("embedded-opentype"),
      //Super Modern Browsers
        url("./fonts/#{$fileName}.woff2")format("woff2"),
      //Majority of Modern Browsers
        url("./fonts/#{$fileName}.woff")format("woff"),
      //Safari, Android, iOS
        url("./fonts/#{$fileName}.ttf")format("truetype"),
      //Legacy iOS
        url("./fonts/#{$fileName}.svg##{$fileName}")format("svg");
  }
}

// Set the fallback stack incase our primary font doesn't load
$stack: "Helvetica Neue", "Helvetica", Arial, sans-serif;

@include includeFont("Poppins", Poppins-Black, 900);
@include includeFont("Poppins", Poppins-Bold, 700);
@include includeFont("Poppins", Poppins-Medium, 500);

$poppins: "Poppins", $stack;
