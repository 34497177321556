.playlistHero {
    margin-bottom: 20px;

    .playlistHeroContent {
        position: relative;

        @include min-screen(768px) {
            display: flex;
            justify-content: center;
            justify-content: flex-start;
            gap:15px
        }

        @include max-screen(425px) {
            position: relative;
            align-items: center;
            display: flex;
            flex-direction: column;
        }

        .playlistHeroThumbnail {
            height: 246px;
            margin: 0 auto;
            margin-left: 0px;
            margin-right: 15px;
            width: 262px;
            margin-top: 29px;
        }

        img {
            height: 100%;
            width: auto;
        }

        .playlistHeroBody {
            margin-left: 0px;
            margin-top: 50px;
            box-sizing: border-box;

            @include min-screen(768px) {
                margin-left: 0px;
                margin-top: 0px;
                width: calc(100% - 206px);
            }

            header {
                text-align: center;

                @include min-screen(768px) {
                    text-align: left;
                }

                h3 {
                    color: $white;
                    font: 400 14px/1.8 $poppins;
                    letter-spacing: letter-spacing(25, 16px);
                    position: relative;
                    top: -8px;

                    @include min-screen(768px) {
                        font-size: 16px;
                        letter-spacing: letter-spacing(25, 18px);
                        line-height: 30px;
                    }

                    span {
                        color: $accent;
                        margin-left: 10px;
                    }
                }

                h1 {
                    color: $white;
                    font: 700 36px/51px $poppins;
                    letter-spacing: letter-spacing(25, 30px);
                    max-width: 990px !important;
                    margin: 0 auto 5px;
                    overflow-wrap: break-word;
                    white-space:normal;
                    line-height: normal !important;
                    text-overflow: initial;
                    overflow: hidden;

                    @include min-screen(768px) {
                        font-size: 25px;
                        max-width: 590px;
                        margin: 0 auto 5px;
                        white-space: normal;
                        letter-spacing: letter-spacing(25, 50px);
                        margin: 0 0 5px;
                    }

                    @include min-screen(1024px) {
                        font-size: 36px;
                        letter-spacing: letter-spacing(25, 60px);
                    }
                }
            }

            .playlistHeroLinks {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin-bottom: 1px;
                @include min-screen(768px) {
                    justify-content: left;
                }

                .playlistHeroItem {
                    background-color: $btnbackground;
                    border: 1px solid $white;
                    border-radius: 6px;
                    height: 40px;
                    color: $white;
                    display: none;
                    font: 500 14px $poppins;
                    margin-right: 7px;
                    margin-top: 20px;
                    padding: 9px 11px 11px 11px;
                    transition: background-color 300ms ease;
                    text-decoration: none;
                    cursor: pointer;

                    @include min-screen(768px) {
                        display: block;
                    }

                    &:hover {
                        background-color: $accent;
                    }

                    .hero-icon {
                        width: 20px;
                        height: 19px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

.publicPlaylistHeroContainer {
    margin: 25px 37px;

    .ppInfo {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .ellipsis-text{
        color: $accent;
        cursor: pointer;
    }
    @media (min-width: 375px) and (max-width: 576px) {
        margin: 25.5px 20px !important;
    }

}


.publicPlaylistSongContainer {

    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #141414;
    width: 100%;
    padding: 24px 34px;
    border-bottom: 1px solid $grey-lighter;
    cursor: pointer;
    height: 76px;

    .ppSongName {
        display: flex;
        gap: 20px;
        align-items: center;
        cursor: pointer;

        .ppNumber {
            min-width: 50px;
            display: flex;
            justify-content: center;
        }

        .playPauseImg {
            width: 25px;
            height: 25px;
        }

        .ppSongPlaying {
            color: $accent
        }
    }

    .ppSongTime {
        color: $grey-lighter;
    }
    .playBtn {
        display: none;
    }

}

.publicPlaylistSongContainer:hover {
    .ppSongName {
        color: $accent
    }
    .ppNumber {
        display: none;
    }
    .playBtn {
        display: block;
        min-width: 50px;
        display: flex;
        justify-content: center;
    }
}


.ppContainerHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 24px 34px;
    border-bottom: 1px solid $accent;
    color: $white;
    background-color: #141414;
    height: 50px;
    align-items: center;

    .ppSongTitleHeader {
        display: flex;
        gap: 20px;

        .ppSongIndexHeader {
            min-width: 50px;
            display: flex;
            justify-content: center;
        }
    }
}

.ppUnauthorized{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    font-size: 16px;
}
.bx--tooltip__trigger{
    .trigger-text {
        color: $white;
        font: 700 36px/51px $poppins;
        letter-spacing: letter-spacing(25, 30px);
        max-width: 900px;
        margin: 0 auto 5px;
        white-space: normal ;
        overflow: hidden ;
        text-overflow: ellipsis ;
        text-overflow: initial;

        @media (max-width:768px) {
          font-size: 36px;
          max-width: 490px;
          margin: 0 auto 5px;
          white-space: normal ;
          overflow: hidden ;
          text-overflow: initial !important ;
          letter-spacing: letter-spacing(25, 50px);
          margin: 0 0 5px;

        }
        @media (max-width:1024px) {
            font-size: 36px;
            max-width: 590px;
            margin: 0 auto 5px;
            white-space: nowrap ;
            overflow: hidden ;
            text-overflow: ellipsis ;
            letter-spacing: letter-spacing(25, 50px);
            margin: 0 0 5px;

          }
        @media (max-width:425px) {
            text-align: center;
            font-size: 20px;
            max-width: 395px;
            margin: 0 auto 5px;
            overflow-wrap: break-word;
            white-space:normal;
            line-height: normal;;
            overflow: hidden ;
            letter-spacing: letter-spacing(25, 50px);
            margin: 0 0 5px;

          }

        @include min-screen(1024px) {
            font-size: 36px;
            letter-spacing: letter-spacing(25, 60px);
        }
    }
}

.publicPlaylistModal {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    min-height: 150px;
    padding: 10px 20px 20px 20px;
    background-color: $grey-dark;
    border-top: 1px solid $primary;
    .waveform-wrapper {
        max-height: 184px;
    }
    .cross-icon-container {
        position: absolute;
        cursor: pointer;
    }
  }
  .public-songs-wrapper{
    margin-bottom:258px;
}
.public-songs-waveform-wrapper{
    margin-bottom:258px;
}


// Table style

.ppContainer{
    table{
        tbody{
            tr{
                td{
                    &:nth-child(1), &:nth-child(2){
                        width: 54px;
                    }
                    &:nth-child(3), &:nth-child(n + 2){
                        p{
                            &:first-child{
                                color: $black-100;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 18px;
                            }
                        }
                    }
                    &:nth-child(2){
                        cursor: pointer;
                        @media (min-width: 375px) and (max-width: 576px) {
                            margin: 0px 15px !important;
                        }
                    }
                    &:last-child{
                        padding-right: 28px;
                        text-align: end;
                    }
                }
            }
        }
    } 
    .bx--data-table-v2--zebra{
        tbody{
            tr{
                td{
                    padding-top: 17px;
                    padding-bottom: 17px;
                }
            }
        }
    }
    @media (min-width: 375px) and (max-width: 576px) {
        margin: 0px 15px !important;
    }

}

.cs-trigger-text{
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 57px;
    max-width: 1079px;
    @media (min-width: 930px) and (max-width: 1400px) {
        max-width: 700px;
    }
    @media (min-width: 425px) and (max-width: 930px) {
        max-width: 400px;
    }
}