.main.assign-session{
     .sections-container {
        .breadcrumb{
            margin-top: 21px;
            padding-top: 0px;
        }
     }
    .btns-container{
        margin-top: 80px !important;
    }
}

.creditForm {
  .btns-container{
    margin-top: 80px !important;
  }
}

.create-new-session-modal{
  .new-session-form {
    .formRow--Four-Column{
      position: relative;
    }
  }
    .react-datepicker__input-container input{
        padding-left: 20px;
    }
    .bx--modal-close {
        display: inherit;
        &:after {
          content: '';
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
          outline: none;
        }
      }
      .bx--modal-close__icon {
        display: inherit;
        fill: $white;
        width: 20px;
        height: 13px;
      }
      .bx--modal .bx--modal-container{
        padding: 30px;
      }
      .bx--modal-container{
        max-width: 550px;
        min-width: 550px;
        padding: 30px;
      }
      .modal-scrollable .bx--modal-content{
        margin: 0px 16px;
      }
      .new-session-form .formRow{
        margin-bottom: 35px;
        input{
            padding: 0 1rem;
            &::placeholder{
              color: $granite-gray-color;
            }
        }
      }
      .bx--modal-header{
        margin-bottom: 20px;
      }
      .bx--modal-content{
        margin-bottom: 21px
      }
      .bx--modal-footer{
        margin: 24px 0px 0px 0px;
        padding: 0px;
      }
}